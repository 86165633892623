import React from 'react'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

function DashBoardLoad() {
    return (
        <div className='py-3'>
            <SkeletonTheme baseColor="#bcdce5" highlightColor="#a4cfdb" >
                <div className='d-flex justify-content-between align-items-center skeleton-profile'>
                    <Skeleton width={"100%"} height={5} count={3} className="flex-1 profile" />
                    <Skeleton circle={true} height={50} width={50} />
                </div>
                <div className='mt-3'>
                    <Skeleton height={30} />
                </div>
                <div className='mt-3'>
                    <Skeleton height={200} />
                </div>
                <div className='mt-3'>
                    <Skeleton height={200} />
                </div>
                <div className='mt-3 d-flex skeleton-card flex-wrap justify-content-between align-items-center'>
                    <Skeleton height={200} width={"100%"} />
                    < Skeleton height={200} width={"100%"} />
                    <Skeleton height={200} width={"100%"} />
                    < Skeleton height={200} width={"100%"} />
                    <Skeleton height={200} width={"100%"} />
                    < Skeleton height={200} width={"100%"} />
                </div>
            </SkeletonTheme>
        </div>
    )
}

export default DashBoardLoad