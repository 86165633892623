import React, { useState } from "react"
import PropTypes from "prop-types"
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
} from "reactstrap"

//i18n
import { withTranslation } from "react-i18next"

// Redux
import { withRouter, } from "react-router-dom"

//images
import { FcManager } from "react-icons/fc"

const ProfileMenu = props => {
  const [menu, setMenu] = useState(false)

  const handleLogout = async () => {
    localStorage.clear()
    window.location.reload()
  }

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="d-inline-block"
      >
        <DropdownToggle
          className="btn header-item "
          id="page-header-user-dropdown"
          tag="button"
        >
          <div className="rounded-circle header-profile-user" >
            <FcManager size={"1.6rem"} />
          </div>
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          <span
            className="dropdown-item"
            style={{ cursor: "pointer" }}
            onClick={handleLogout}
          >
            <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger" />
            <span>{props.t("Logout")}</span>
          </span>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment >
  )
}

ProfileMenu.propTypes = {
  success: PropTypes.any,
  t: PropTypes.any,
}



export default withRouter(withTranslation()(ProfileMenu))
