import axios from "axios"
import { useNotification } from "components/Common/Notification"

// export const DOMAIN = "api-v2.careerpro.uk"
export const IMAGE_URL = "https://api-v2.careerpro.uk"
export const WEBSOCKET_URL = "wss://api-v2.careerpro.uk"
// export const WEBSOCKET_URL = "ws://192.168.1.72:8000"

//apply base url for axios
// export const BASE_URL = "https://api-v2.careerpro.uk/api/v1/"
// export const BASE_URL = `http://192.168.1.2:8000/api/v1/`
// export const BASE_URL =`https://${DOMAIN}/api/v1/`
export const BASE_URL = "https://api-v2.careerpro.uk/api/v1/"
export const LOCAL_URL = "http://192.168.1.106:9000/api/v1/"

export const axiosApi = axios.create({
  baseURL: BASE_URL,
})

axiosApi.interceptors.request.use(
  function (config) {
    const token = localStorage.getItem("token")
    if (token) {
      config.headers["Authorization"] = "token " + localStorage.getItem("token")
    }
    return config
  },
  function (error) {}
)

axiosApi.interceptors.response.use(
  response => {
    return response
  },
  err => {
    console.log(err.response.status)

    if (err.response.status == 401) {
      localStorage.clear("token")
      window.location.reload(false)

      useNotification({
        type: "error",
        message: err?.response?.data?.detail,
        title: err?.response?.statusText,
      })
    } else {
      useNotification({
        type: "error",
        message: err?.response?.data?.detail,
        title: err?.response?.statusText,
      })
    }
    return Promise.reject(err.response)
  }
)

export async function get(url, config = {}) {
  return await axiosApi.get(url, { ...config }).then(response => response.data)
}

export async function post(url, data, config = {}) {
  return axiosApi
    .post(url, { ...data }, { ...config })
    .then(response => response.data)
}

export async function ApiPut(url, data, config = {}) {
  return axiosApi
    .put(url, { ...data }, { ...config })
    .then(response => response.data)
}

export async function patch(url, data, config = {}) {
  return axiosApi
    .patch(url, { ...data }, { ...config })
    .then(response => response.data)
}

export async function del(url, config = {}) {
  return await axiosApi
    .delete(url, { ...config })
    .then(response => response.data)
}
