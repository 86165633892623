import React, { useEffect, useState } from "react"
import { Row, Col, Card, CardBody, Button, CardTitle, Label } from "reactstrap"
import { Link } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { map, range } from "lodash"
import PropTypes from "prop-types"
// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next"
import ToolkitProvider from "react-bootstrap-table2-toolkit"

import "../../assets/scss/datatables.scss"
import moment from "moment"

import { getJobApplications } from "store/actions"
import { AvField, AvForm } from "availity-reactstrap-validation"
import { categories, countries } from "pages/JobApplication/Crud/CategoryList"
import MyPagination from "components/Common/MyPagination"
import { Markup } from "interweave"

const Vacancies = ({ isInner, singleVacancies }) => {
  const dispatch = useDispatch()
  const [country, setCountry] = useState("all")
  const [category, setCategory] = useState("all")
  const [searchText, setSearchText] = useState("")
  const [page, setPage] = useState(1)

  const Role = localStorage.getItem("role")

  const { job_applications, studentProfile } = useSelector(state => ({
    job_applications: state?.StudentDetails?.applicationData,
    studentProfile: state?.StudentDashboard?.studentProfile,
  }))

  useEffect(() => {
    dispatch(getJobApplications(searchText, page))
  }, [dispatch, searchText, page])

  const totalPages = Math.ceil(job_applications?.count / 10)
  const pages = range(1, totalPages + 1)

  const columns = [
    {
      dataField: "title",
      text: "Title",
    },
    {
      dataField: "description",
      text: "Description",
    },
    {
      dataField: "image",
      text: "Image",
    },
    {
      dataField: "icon",
      text: "Action",
    },
  ]

  const data = map(job_applications?.results, (item, index) => ({
    ...item,
    image: (
      <img src={item?.image} alt="image" style={{ height: 50, width: 50 }} />
    ),
    title: item?.title ? (
      <Markup content={item?.title} />
    ) : (
      <span>{item?.job_title}</span>
    ),
    description: (
      <>
        <Row></Row>
        {item?.description?.length >= 40 ? (
          <>
            <p>Text is too long</p>
            <Link to={`/update-job-application/${item?.id}`}>View more...</Link>
          </>
        ) : (
          <Markup
            content={
              item?.description?.length > 40
                ? item?.description?.substr(0, 40 - 1) + "..."
                : item?.description
            }
          />
        )}
      </>
    ),
    // icon: (
    //   <>
    //     <div className="d-flex  align-items-center">
    //       <div>
    //         <Link
    //           to={`/update-job-application/${item?.id}`}
    //           className="btn-dark btn-sm bg-success p-1 px-2 font-size-12"
    //           style={{ borderRadius: "14px" }}
    //         >
    //           Update
    //         </Link>
    //       </div>
    //       <i
    //         className="bx bx-trash color font mx-2"
    //         onClick={() => handleSubmit(item.id)}
    //         type="button"
    //       />
    //     </div>
    //   </>
    // ),
  }))

  const defaultSorted = [
    {
      dataField: "id",
      order: "asc",
    },
  ]

  // Select All Button operation
  const selectRow = {
    mode: "checkbox",
  }

  const handleSearch = e => {
    setSearchText(e.target.value)
  }

  return (
    <React.Fragment>
      <Col className="col-12 ">
        <Card className="position-relative" style={{ minHeight: 200 }}>
          <CardBody>
            <CardTitle>Vacancies</CardTitle>
            <ToolkitProvider keyField="id" columns={columns} data={data} search>
              {toolkitProps => (
                <React.Fragment>
                  <Row className="mb-2">
                    <Col md="4">
                      <div className="search-box me-2 mb-2 d-inline-block">
                        <div className="position-relative">
                          <form
                            className="app-search d-lg-block pt-4"
                            onChange={e => handleSearch(e)}
                          >
                            <div className="position-relative">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Search..."
                                defaultValue={searchText}
                              />
                              <span className="bx bx-search-alt" />
                            </div>
                          </form>
                        </div>
                      </div>
                    </Col>
                    {/* <Col md="4">
                      <div className="d-flex align-items-center justify-content-between mb-3">
                        <AvForm>
                          <Label
                            className="text-muted font-size-12"
                            style={{ width: 160 }}
                          >
                            Country:
                          </Label>
                          <AvField
                            type="select"
                            label=""
                            name="country"
                            validate={{
                              required: {
                                value: false,
                              },
                            }}
                            onChange={e => setCountry(e.target.value)}
                          >
                            {" "}
                            <option value={""}>choose country.....</option>
                            {map(countries, (item, key) => (
                              <option key={key} value={item}>
                                {item}
                              </option>
                            ))}
                          </AvField>
                        </AvForm>
                      </div>
                    </Col>
                    <Col md="4">
                      <div className="d-flex align-items-center justify-content-between mb-3">
                        <AvForm>
                          <Label
                            className="text-muted font-size-12"
                            style={{ width: 160 }}
                          >
                            Category:
                          </Label>
                          <AvField
                            type="select"
                            label=""
                            name="category"
                            validate={{
                              required: {
                                value: false,
                              },
                            }}
                            onChange={e => setCategory(e.target.value)}
                          >
                            {" "}
                            <option value={""}>choose category.....</option>
                            {map(categories, (item, key) => (
                              <option key={key} value={item}>
                                {item}
                              </option>
                            ))}
                          </AvField>
                        </AvForm>
                      </div>
                    </Col> */}
                  </Row>

                  <>
                    <Row>
                      {data?.length >= 1 ? (
                        <Col xl="12">
                          <div className="table-responsive">
                            <BootstrapTable
                              keyField={"id"}
                              responsive
                              bordered={false}
                              striped={false}
                              defaultSorted={defaultSorted}
                              selectRow={selectRow}
                              classes={"table align-middle table-nowrap"}
                              headerWrapperClasses={"thead-light"}
                              {...toolkitProps.baseProps}
                            />
                          </div>
                        </Col>
                      ) : (
                        <p className="text-info">No Vacancies Available</p>
                      )}
                    </Row>
                    <MyPagination
                      totalPages={pages}
                      page={page}
                      setPage={setPage}
                    />
                  </>
                </React.Fragment>
              )}
            </ToolkitProvider>
          </CardBody>
          {studentProfile?.fees_paid === false && Role === "student" && (
            <div className="not-paid-modal">
              <Card className="bg-white">
                <CardBody>
                  <p className="text-danger">
                    Uh Oh! Seems Like You are not paid the fee
                  </p>

                  <div className="text-black">
                    <p className="mb-1">
                      <i className="mdi mdi-circle-medium align-middle text-primary me-1" />{" "}
                      Make the payment to asscess vacancies
                    </p>

                    <p className="mb-0">
                      <i className="mdi mdi-circle-medium align-middle text-primary me-1" />{" "}
                      For payments Contact us
                      <br />{" "}
                      <span className="mx-3 px-1">
                        {" "}
                        Call +44774163937, Email : info@careerpro.uk
                      </span>
                    </p>
                  </div>
                </CardBody>
              </Card>
            </div>
          )}
        </Card>
      </Col>
    </React.Fragment>
  )
}

export default Vacancies

Vacancies.propTypes = {
  isInner: PropTypes.bool,
  singleVacancies: PropTypes.any,
}
