import moment from "moment"

function useMoment(date, formate) {
  const day = date?.day
  const month = date?.month
  const year = date?.year
  var formatedDate = ""

  switch (formate) {
    case "YYYY-MM-DD":
      var formatedDate = `${date?.year}-${date?.month}-${date?.day}`
      return { formatedDate, day, month, year }

    case "YYYY/MM/DD":
      var formatedDate = `${date?.year}/${date?.month}/${date?.day}`
      return { formatedDate, day, month, year }

    case "dateObject":
      const dateObject = {
        day: parseInt(moment(date).format("DD")),
        month: parseInt(moment(date).format("MM")),
        year: parseInt(moment(date).format("YYYY")),
      }

      return dateObject

    default:
      return {}
  }
}

export default useMoment
