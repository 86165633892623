import React, { useState } from "react"
import PropTypes from "prop-types"
import { Col, Row } from "reactstrap"
import { map } from "lodash"
import Select from "react-select"
import { Card, Input } from "reactstrap"
import { useSelector } from "react-redux"

//Import Card

const Moduls = props => {
  const { moduls } = useSelector(state => ({
    moduls: state?.StudentDashboard?.studentProfile,
  }))

  const getShops = []

  const onGetShops = []

  const [editShow, setEditShow] = useState({
    status: false,
    data: {},
  })

  const [selectedCategory, setSelectedCategory] = useState("Pending")

  const handleEdit = item => {
    setEditShow({ ...editShow, status: true, data: item })
  }
  const handleClose = item => {
    setEditShow({ ...editShow, status: false, data: {} })
  }

  return (
    <React.Fragment>
      <Row className="w-100">
        <Col xl="4" sm="6">
          {map(moduls?.specializations, (data, index) => (
            <Card key={index} className="position-relatie bg-light">
              <div
                style={{ fontSize: "20px", right: 0 }}
                className="p-2 d-flex flex-row-reverse cursor-pointer text-secondary position-absolute w-fit"
              >
                {editShow?.status && editShow?.data.id === moduls.id ? (
                  <i
                    className="mdi mdi-close-thick text-warning cursor"
                    onClick={() => handleClose(moduls)}
                    style={{ cursor: "pointer" }}
                  />
                ) : (
                  <i
                    className="bx bx-pencil cursor"
                    onClick={() => handleEdit(moduls)}
                  />
                )}
              </div>
              <Row style={{ padding: "20px 0px" }}>
                <Col xl="5">
                  <div className="text-center py-2 border-end">
                    <div className="avatar-sm mx-auto mb-3 mt-1">
                      <span
                        className={
                          "avatar-title rounded-circle bg-soft bg-primary text-primary font-size-16"
                        }
                      ></span>
                    </div>
                    <h6 className="text-truncate text-muted"></h6>
                  </div>
                </Col>

                <Col xl="7" className="p-0">
                  <div className="p-2 py-0 text-center text-xl-start">
                    <Row>
                      <Col xs="12">
                        <div className="">
                          <h6 style={{ marginBottom: 5, fontWeight: 400 }}>
                            Enter Modul 1 Name
                          </h6>
                          {editShow?.status &&
                          editShow?.data.id === moduls.id ? (
                            <Input
                              type="text"
                              style={{ maxWidth: "170px", height: "27px" }}
                            />
                          ) : (
                            <p
                              style={{ marginBottom: 2 }}
                              className="text-muted text-truncate"
                            >
                              {data?.title}
                            </p>
                          )}
                        </div>
                      </Col>

                      <p className="pb-0 mb-1 pt-1">Select Status</p>
                      <div className="custom_select_new">
                        <Select
                          // onInputChange={handleEnters}
                          value={data?.status}
                          placeholder={selectedCategory}
                          // onChange={handlerCategoryFinalValue}
                          options={[]}
                          classNamePrefix="select2-selection"
                          style
                          isLoading={false}
                        />
                      </div>
                    </Row>
                  </div>
                </Col>
              </Row>
            </Card>
          ))}
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default Moduls
