import React from "react"
import { Card, CardBody, Col, Row } from "reactstrap"
import PropTypes from "prop-types"
import { useSelector } from "react-redux"
import CareerInput from "./CareerInput"
import useWindow from "Hooks/UseWindow"

function UpdateCareerIndrest({ CardData, isReadOnly }) {
  const { StudentSingleData } = useSelector(state => ({
    StudentSingleData: state.StudentDetails.StudentsSingleview,
  }))

  const { isMobile } = useWindow()

  const handleData = () => {
    if (isReadOnly) {
      return CardData
    } else {
      return StudentSingleData
    }
  }

  return (
    <div className="w-100">
      {isMobile ? (
        <p className="font-size-14 mb-3 mt-1 text-darkGreen">
          Interested Job Role
          <span className="mb-2 font-size-11">&nbsp;(Add 2 Minimum)</span>
        </p>
      ) : (
        <p className="mb-2">
          Interested Job Role
          {/* <p className="mb-2 font-size-11">&nbsp;(Add 2 Minimum)</p> */}
        </p>
      )}

      {isMobile ? (
        <div>
          <Card className="glass-card">
            <CardBody>
              <CareerInput
                name="first_job_role"
                label="1. Interested Job Role"
                readOnly={isReadOnly ? true : false}
                value={handleData()?.first_job_role}
                className="sm:mb-0"
              />
              <CareerInput
                name="second_job_role"
                label="2. Interested Job Role"
                readOnly={isReadOnly ? true : false}
                value={handleData()?.second_job_role}
                className="sm:mb-0"
              />
              <CareerInput
                name="third_job_role"
                label="3. Interested Job Role"
                readOnly={isReadOnly ? true : false}
                value={handleData()?.third_job_role}
                className="sm:mb-0"
              />
              <CareerInput
                name="forth_job_role"
                label="4. Interested Job Role"
                className="sm:mb-0"
                readOnly={isReadOnly ? true : false}
                value={handleData()?.forth_job_role}
              />
            </CardBody>
          </Card>
          {/* <Card className="glass-card">
            <CardBody>
              <div className="numbers-order">
                <p>1st</p>
              </div>
              <CareerInput
                name={"first_job_sector"}
                value={handleData()?.first_job_sector}
                label="Interested Job Sector"
                readOnly={isReadOnly ? true : false}
                className="sm:mb-0"
              />
              <CareerInput
                name="first_job_role"
                label="Interested Job Role"
                readOnly={isReadOnly ? true : false}
                value={handleData()?.first_job_role}
                className="sm:mb-0"
              />
              <CareerInput
                name="first_preferred_location"
                label="Preferred Location"
                readOnly={isReadOnly ? true : false}
                value={handleData()?.first_preferred_location}
              />
            </CardBody>
          </Card> */}
          {/* <Card className="glass-card">
            <CardBody>
              <div className="numbers-order">
                <p>2nd</p>
              </div>
              <CareerInput
                name="second_job_sector"
                label="Interested Job Sector"
                readOnly={isReadOnly ? true : false}
                value={handleData()?.second_job_sector}
                className="sm:mb-0"
              />
              <CareerInput
                name="second_job_role"
                label="Interested Job Role"
                readOnly={isReadOnly ? true : false}
                value={handleData()?.second_job_role}
                className="sm:mb-0"
              />
              <CareerInput
                name="second_preferred_location"
                label="Preferred Location"
                readOnly={isReadOnly ? true : false}
                value={handleData()?.second_preferred_location}
              />
            </CardBody>
          </Card> */}
          {/* <Card className="glass-card">
            <CardBody>
              <div className="numbers-order">
                <p>3rd</p>
              </div>
              <CareerInput
                name="third_job_sector"
                label="Interested Job Sector"
                readOnly={isReadOnly ? true : false}
                value={handleData()?.third_job_sector}
                className="sm:mb-0"
              />
              <CareerInput
                name="third_job_role"
                label="Interested Job Role"
                readOnly={isReadOnly ? true : false}
                value={handleData()?.third_job_role}
                className="sm:mb-0"
              />
              <CareerInput
                name="third_preferred_location"
                label="Preferred Location"
                readOnly={isReadOnly ? true : false}
                value={handleData()?.third_preferred_location}
              />
            </CardBody>
          </Card> */}
          {/* <Card className="glass-card">
            <CardBody>
              <div className="numbers-order">
                <p>4th</p>
              </div>
              <CareerInput
                name="forth_job_sector"
                label="Interested Job Sector"
                className="sm:mb-0"
                readOnly={isReadOnly ? true : false}
                value={handleData()?.forth_job_sector}
              />
              <CareerInput
                name="forth_job_role"
                label="Interested Job Role"
                className="sm:mb-0"
                readOnly={isReadOnly ? true : false}
                value={handleData()?.forth_job_role}
              />
              <CareerInput
                name="forth_preferred_location"
                label="Preferred Location"
                readOnly={isReadOnly ? true : false}
                value={handleData()?.forth_preferred_location}
              />
            </CardBody>
          </Card> */}
        </div>
      ) : (
        <div>
          <Row className="p-3 pb-0">
            {/* <Col className="career-input-field p-0 px-1 " md={4}>
              <div className="w-100">
                <h6
                  className="text-black font-size-12 ms-4 ps-2"
                  style={{ fontWeight: "400" }}
                >
                  Interested Job Sector
                </h6>
                <CareerInput
                  name={"first_job_sector"}
                  value={handleData()?.first_job_sector}
                  label=""
                  mylabel="1st"
                  readOnly={isReadOnly ? true : false}
                />
                <CareerInput
                  mylabel="2nd"
                  name="second_job_sector"
                  label=""
                  readOnly={isReadOnly ? true : false}
                  value={handleData()?.second_job_sector}
                />
                <CareerInput
                  mylabel="3rd"
                  name="third_job_sector"
                  label=""
                  readOnly={isReadOnly ? true : false}
                  value={handleData()?.third_job_sector}
                />
                <CareerInput
                  mylabel="4th"
                  name="forth_job_sector"
                  label=""
                  readOnly={isReadOnly ? true : false}
                  value={handleData()?.forth_job_sector}
                />
              </div>
            </Col> */}

            <Col className="career-input-field p-0 px-1 pb-0" md={8}>
              <div className="w-100">
                {/* <h6
                  className="text-black font-size-12"
                  style={{ fontWeight: "400" }}
                >
                  Interested Job Role
                </h6> */}

                <CareerInput
                  mylabel="1st"
                  name="first_job_role"
                  label=""
                  readOnly={isReadOnly ? true : false}
                  value={handleData()?.first_job_role}
                />
                <CareerInput
                  mylabel="2nd"
                  name="second_job_role"
                  label=""
                  readOnly={isReadOnly ? true : false}
                  value={handleData()?.second_job_role}
                />
                <CareerInput
                  mylabel="3rd"
                  name="third_job_role"
                  label=""
                  readOnly={isReadOnly ? true : false}
                  value={handleData()?.third_job_role}
                />
                <CareerInput
                  mylabel="4th"
                  name="forth_job_role"
                  label=""
                  readOnly={isReadOnly ? true : false}
                  value={handleData()?.forth_job_role}
                />
              </div>
            </Col>

            {/* <Col className="career-input-field p-0 px-1" md={4}>
              <div className="w-100">
                <h6
                  className="text-black font-size-12"
                  style={{ fontWeight: "400" }}
                >
                  Preferred Location
                </h6>

                <CareerInput
                  // mylabel="1st"
                  name="first_preferred_location"
                  label=""
                  readOnly={isReadOnly ? true : false}
                  value={handleData()?.first_preferred_location}
                />
                <CareerInput
                  // mylabel="2nd"
                  name="second_preferred_location"
                  label=""
                  readOnly={isReadOnly ? true : false}
                  value={handleData()?.second_preferred_location}
                />
                <CareerInput
                  // mylabel="3rd"
                  name="third_preferred_location"
                  label=""
                  readOnly={isReadOnly ? true : false}
                  value={handleData()?.third_preferred_location}
                />
                <CareerInput
                  // mylabel="4th"
                  name="forth_preferred_location"
                  label=""
                  readOnly={isReadOnly ? true : false}
                  value={handleData()?.forth_preferred_location}
                />
              </div>
            </Col> */}
          </Row>
        </div>
      )}
    </div>
  )
}

export default UpdateCareerIndrest
UpdateCareerIndrest.propTypes = {
  CardData: PropTypes.any,
  isReadOnly: PropTypes.bool,
}
