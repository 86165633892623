import React from "react"
import { Col, Container, Row } from "reactstrap"
import { Link } from "react-router-dom"
import { logo } from "assets/images/logo"
import MetaTag from "components/Common/Meta-tag"
import FooterCustom from "./LandingPage/FooterCustom"

function PrivacyPolicy() {
  return (
    <React.Fragment>
      <MetaTag title="Welcome!" />

      <Row className="gutter_zero   fullkit">
        <nav
          id="navbar-transparent"
          className="navbar navbar-expand-lg navbar-light bg-light"
        >
          <div className="navbar-collapse h-full" id="navbarButtonsExample">
            <Row className="gutter_zero marg-right h-full w-full">
              <Container className="h-full">
                <Col className="col-lg-12 col-md-12 col-sm-12 h-full d-flex justify-content-between">
                  <div className="d-flex align-items-center">
                    <Link to="/" className="btn text-light me-3">
                      <img src={logo} width={"200"} height={"31"} />
                    </Link>
                  </div>
                  <div className="d-flex align-items-center">
                    <Col className="nav-sizing buttonNewWrap">
                      <Link
                        to="/register"
                        type="button"
                        className="btn whitespace btn-outline-info text-adjust"
                      >
                        Create Account
                      </Link>
                      <Link
                        to="/login"
                        className="btn whitespace btn-light px-3 font-weight-bold-sign text-info text-adjust"
                        role="button"
                      >
                        Sign In
                      </Link>
                    </Col>
                  </div>
                </Col>
              </Container>
            </Row>
          </div>
        </nav>
      </Row>
      <Row className="container m-auto mt-5">
        <h4>PRIVACY POLICY</h4>
        <p>
          <br />
        </p>
        <p>Last updated March 21, 2023</p>
        <p>
          <br />
        </p>
        <p>
          <br />
        </p>
        <p>
          <br />
        </p>
        <p>
          This privacy notice for career pro (&apos;Company&apos;,
          &apos;we&apos;, &apos;us&apos;, or &apos;our&apos;), describes how and
          why we might collect, store, use, and/or share (&apos;process&apos;)
          your information when you use our services (&apos;Services&apos;),
          such as when you:
        </p>
        <ul>
          <li>
            <p>
              Visit our website at&nbsp;
              <a href="https://careerpro.uk/">https://careerpro.uk/</a>, or any
              website of ours that links to this privacy notice
            </p>
          </li>
          <li>
            <p>
              Download and use our mobile application (Career Pro), or any other
              application of ours that links to this privacy notice
            </p>
          </li>
          <li>
            <p>
              Engage with us in other related ways, including any sales,
              marketing, or events
            </p>
          </li>
        </ul>
        <p>
          Questions or concerns? Reading this privacy notice will help you
          understand your privacy rights and choices. If you do not agree with
          our policies and practices, please do not use our Services. If you
          still have any questions or concerns, please contact us at
          info@careerpro.uk.
        </p>
        <p>
          <br />
        </p>
        <p>
          <br />
        </p>
        <p>SUMMARY OF KEY POINTS</p>
        <p>
          <br />
        </p>
        <p>
          This summary provides key points from our privacy notice, but you can
          find out more details about any of these topics by clicking the link
          following each key point or by using our table of contents below to
          find the section you are looking for. You can also click&nbsp;
          <a href="https://app.termly.io/builder/websites/7f9269a0-852d-40e1-a81b-ddf548e9433b/documents/2808214/Final%20Details/Version%20Date#toc">
            here
          </a>{" "}
          to go directly to our table of contents.
        </p>
        <p>
          <br />
        </p>
        <p>
          What personal information do we process? When you visit, use, or
          navigate our Services, we may process personal information depending
          on how you interact with career pro and the Services, the choices you
          make, and the products and features you use. Click&nbsp;
          <a href="https://app.termly.io/builder/websites/7f9269a0-852d-40e1-a81b-ddf548e9433b/documents/2808214/Final%20Details/Version%20Date#personalinfo">
            here
          </a>{" "}
          to learn more.
        </p>
        <p>
          <br />
        </p>
        <p>
          Do we process any sensitive personal information? We may process
          sensitive personal information when necessary with your consent or as
          otherwise permitted by applicable law. Click&nbsp;
          <a href="https://app.termly.io/builder/websites/7f9269a0-852d-40e1-a81b-ddf548e9433b/documents/2808214/Final%20Details/Version%20Date#sensitiveinfo">
            here
          </a>{" "}
          to learn more.
        </p>
        <p>
          <br />
        </p>
        <p>
          Do we receive any information from third parties? We do not receive
          any information from third parties.
        </p>
        <p>
          <br />
        </p>
        <p>
          How do we process your information? We process your information to
          provide, improve, and administer our Services, communicate with you,
          for security and fraud prevention, and to comply with law. We may also
          process your information for other purposes with your consent. We
          process your information only when we have a valid legal reason to do
          so. Click&nbsp;
          <a href="https://app.termly.io/builder/websites/7f9269a0-852d-40e1-a81b-ddf548e9433b/documents/2808214/Final%20Details/Version%20Date#infouse">
            here
          </a>{" "}
          to learn more.
        </p>
        <p>
          <br />
        </p>
        <p>
          In what situations and with which parties do we share personal
          information? We may share information in specific situations and with
          specific third parties. Click&nbsp;
          <a href="https://app.termly.io/builder/websites/7f9269a0-852d-40e1-a81b-ddf548e9433b/documents/2808214/Final%20Details/Version%20Date#whoshare">
            here
          </a>{" "}
          to learn more.
        </p>
        <p>
          <br />
        </p>
        <p>
          How do we keep your information safe? We have organisational and
          technical processes and procedures in place to protect your personal
          information. However, no electronic transmission over the internet or
          information storage technology can be guaranteed to be 100% secure, so
          we cannot promise or guarantee that hackers, cybercriminals, or other
          unauthorised third parties will not be able to defeat our security and
          improperly collect, access, steal, or modify your information.
          Click&nbsp;
          <a href="https://app.termly.io/builder/websites/7f9269a0-852d-40e1-a81b-ddf548e9433b/documents/2808214/Final%20Details/Version%20Date#infosafe">
            here
          </a>{" "}
          to learn more.
        </p>
        <p>
          <br />
        </p>
        <p>
          What are your rights? Depending on where you are located
          geographically, the applicable privacy law may mean you have certain
          rights regarding your personal information. Click&nbsp;
          <a href="https://app.termly.io/builder/websites/7f9269a0-852d-40e1-a81b-ddf548e9433b/documents/2808214/Final%20Details/Version%20Date#privacyrights">
            here
          </a>{" "}
          to learn more.
        </p>
        <p>
          <br />
        </p>
        <p>
          How do you exercise your rights? The easiest way to exercise your
          rights is by filling out our data subject request form available here:
          info@careerpro.uk, or by contacting us. We will consider and act upon
          any request in accordance with applicable data protection laws.
        </p>
        <p>
          <br />
        </p>
        <p>
          Want to learn more about what career pro does with any information we
          collect? Click&nbsp;
          <a href="https://app.termly.io/builder/websites/7f9269a0-852d-40e1-a81b-ddf548e9433b/documents/2808214/Final%20Details/Version%20Date#toc">
            here
          </a>{" "}
          to review the notice in full.
        </p>
        <p>
          <br />
        </p>
        <p>
          <br />
        </p>
        <p>TABLE OF CONTENTS</p>
        <p>
          <br />
        </p>
        <p>
          <a href="https://app.termly.io/builder/websites/7f9269a0-852d-40e1-a81b-ddf548e9433b/documents/2808214/Final%20Details/Version%20Date#infocollect">
            1. WHAT INFORMATION DO WE COLLECT?
          </a>
        </p>
        <p>
          <a href="https://app.termly.io/builder/websites/7f9269a0-852d-40e1-a81b-ddf548e9433b/documents/2808214/Final%20Details/Version%20Date#infouse">
            2. HOW DO WE PROCESS YOUR INFORMATION?
          </a>
        </p>
        <p>
          <a href="https://app.termly.io/builder/websites/7f9269a0-852d-40e1-a81b-ddf548e9433b/documents/2808214/Final%20Details/Version%20Date#legalbases">
            3. WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR PERSONAL
            INFORMATION?
          </a>
        </p>
        <p>
          <a href="https://app.termly.io/builder/websites/7f9269a0-852d-40e1-a81b-ddf548e9433b/documents/2808214/Final%20Details/Version%20Date#whoshare">
            4. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?
          </a>
        </p>
        <p>
          <a href="https://app.termly.io/builder/websites/7f9269a0-852d-40e1-a81b-ddf548e9433b/documents/2808214/Final%20Details/Version%20Date#inforetain">
            5. HOW LONG DO WE KEEP YOUR INFORMATION?
          </a>
        </p>
        <p>
          <a href="https://app.termly.io/builder/websites/7f9269a0-852d-40e1-a81b-ddf548e9433b/documents/2808214/Final%20Details/Version%20Date#infosafe">
            6. HOW DO WE KEEP YOUR INFORMATION SAFE?
          </a>
        </p>
        <p>
          <a href="https://app.termly.io/builder/websites/7f9269a0-852d-40e1-a81b-ddf548e9433b/documents/2808214/Final%20Details/Version%20Date#infominors">
            7. DO WE COLLECT INFORMATION FROM MINORS?
          </a>
        </p>
        <p>
          <a href="https://app.termly.io/builder/websites/7f9269a0-852d-40e1-a81b-ddf548e9433b/documents/2808214/Final%20Details/Version%20Date#privacyrights">
            8. WHAT ARE YOUR PRIVACY RIGHTS?
          </a>
        </p>
        <p>
          <a href="https://app.termly.io/builder/websites/7f9269a0-852d-40e1-a81b-ddf548e9433b/documents/2808214/Final%20Details/Version%20Date#DNT">
            9. CONTROLS FOR DO-NOT-TRACK FEATURES
          </a>
        </p>
        <p>
          <a href="https://app.termly.io/builder/websites/7f9269a0-852d-40e1-a81b-ddf548e9433b/documents/2808214/Final%20Details/Version%20Date#caresidents">
            10. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
          </a>
        </p>
        <p>
          <a href="https://app.termly.io/builder/websites/7f9269a0-852d-40e1-a81b-ddf548e9433b/documents/2808214/Final%20Details/Version%20Date#virginia">
            11. DO VIRGINIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
          </a>
        </p>
        <p>
          <a href="https://app.termly.io/builder/websites/7f9269a0-852d-40e1-a81b-ddf548e9433b/documents/2808214/Final%20Details/Version%20Date#policyupdates">
            12. DO WE MAKE UPDATES TO THIS NOTICE?
          </a>
        </p>
        <p>
          <a href="https://app.termly.io/builder/websites/7f9269a0-852d-40e1-a81b-ddf548e9433b/documents/2808214/Final%20Details/Version%20Date#contact">
            13. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
          </a>
        </p>
        <p>
          <a href="https://app.termly.io/builder/websites/7f9269a0-852d-40e1-a81b-ddf548e9433b/documents/2808214/Final%20Details/Version%20Date#request">
            14. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM
            YOU?
          </a>
        </p>
        <p>
          <br />
        </p>
        <p>
          <br />
        </p>
        <p>1. WHAT INFORMATION DO WE COLLECT?</p>
        <p>
          <br />
        </p>
        <p>Personal information you disclose to us</p>
        <p>
          <br />
        </p>
        <p>In Short: We collect personal information that you provide to us.</p>
        <p>
          <br />
        </p>
        <p>
          We collect personal information that you voluntarily provide to us
          when you register on the Services, express an interest in obtaining
          information about us or our products and Services, when you
          participate in activities on the Services, or otherwise when you
          contact us.
        </p>
        <p>
          <br />
        </p>
        <p>
          Personal Information Provided by You. The personal information that we
          collect depends on the context of your interactions with us and the
          Services, the choices you make, and the products and features you use.
          The personal information we collect may include the following:
        </p>
        <ul>
          <li>
            <p>names</p>
          </li>
          <li>
            <p>phone numbers</p>
          </li>
          <li>
            <p>email addresses</p>
          </li>
          <li>
            <p>mailing addresses</p>
          </li>
          <li>
            <p>job titles</p>
          </li>
          <li>
            <p>usernames</p>
          </li>
          <li>
            <p>contact preferences</p>
          </li>
          <li>
            <p>billing addresses</p>
          </li>
          <li>
            <p>passwords</p>
          </li>
        </ul>
        <p>
          Sensitive Information. When necessary, with your consent or as
          otherwise permitted by applicable law, we process the following
          categories of sensitive information:
        </p>
        <ul>
          <li>
            <p>student data</p>
          </li>
        </ul>
        <p>
          Application Data.&nbsp;If you use our application(s), we also may
          collect the following information if you choose to provide us with
          access or permission:
        </p>
        <ul>
          <li>
            <p>
              Mobile Device Access.&nbsp;We may request access or permission to
              certain features from your mobile device, including your mobile
              device&apos;s storage, and other features. If you wish to change
              our access or permissions, you may do so in your device&apos;s
              settings.
            </p>
          </li>
        </ul>
        <p>
          This information is primarily needed to maintain the security and
          operation of our application(s), for troubleshooting, and for our
          internal analytics and reporting purposes.
        </p>
        <p>
          <br />
        </p>
        <p>
          All personal information that you provide to us must be true,
          complete, and accurate, and you must notify us of any changes to such
          personal information.
        </p>
        <p>
          <br />
        </p>
        <p>2. HOW DO WE PROCESS YOUR INFORMATION?</p>
        <p>
          <br />
        </p>
        <p>
          In Short: We process your information to provide, improve, and
          administer our Services, communicate with you, for security and fraud
          prevention, and to comply with law. We may also process your
          information for other purposes with your consent.
        </p>
        <p>
          <br />
        </p>
        <p>
          We process your personal information for a variety of reasons,
          depending on how you interact with our Services, including:
        </p>
        <ul>
          <li>
            <p>
              To facilitate account creation and authentication and otherwise
              manage user accounts. We may process your information so you can
              create and log in to your account, as well as keep your account in
              working order.
            </p>
          </li>
          <li>
            <p>
              To save or protect an individual&apos;s vital interest.&nbsp;We
              may process your information when necessary to save or protect an
              individual&rsquo;s vital interest, such as to prevent harm.
            </p>
          </li>
        </ul>
        <p>
          <br />
        </p>
        <p>3. WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR INFORMATION?</p>
        <p>
          <br />
        </p>
        <p>
          In Short: We only process your personal information when we believe it
          is necessary and we have a valid legal reason (i.e. legal basis) to do
          so under applicable law, like with your consent, to comply with laws,
          to provide you with services to enter into or fulfil our contractual
          obligations, to protect your rights, or to fulfil our legitimate
          business interests.
        </p>
        <p>
          <br />
        </p>
        <p>If you are located in the EU or UK, this section applies to you.</p>
        <p>
          <br />
        </p>
        <p>
          The General Data Protection Regulation (GDPR) and UK GDPR require us
          to explain the valid legal bases we rely on in order to process your
          personal information. As such, we may rely on the following legal
          bases to process your personal information:
        </p>
        <ul>
          <li>
            <p>
              Consent. We may process your information if you have given us
              permission (i.e. consent) to use your personal information for a
              specific purpose. You can withdraw your consent at any time.
              Click&nbsp;
              <a href="https://app.termly.io/builder/websites/7f9269a0-852d-40e1-a81b-ddf548e9433b/documents/2808214/Final%20Details/Version%20Date#withdrawconsent">
                here
              </a>{" "}
              to learn more.
            </p>
          </li>
          <li>
            <p>
              Legal Obligations.&nbsp;We may process your information where we
              believe it is necessary for compliance with our legal obligations,
              such as to cooperate with a law enforcement body or regulatory
              agency, exercise or defend our legal rights, or disclose your
              information as evidence in litigation in which we are involved.
            </p>
          </li>
          <li>
            <p>
              Vital Interests.&nbsp;We may process your information where we
              believe it is necessary to protect your vital interests or the
              vital interests of a third party, such as situations involving
              potential threats to the safety of any person.
            </p>
          </li>
        </ul>
        <p>
          <br />
        </p>
        <p>If you are located in Canada, this section applies to you.</p>
        <p>
          <br />
        </p>
        <p>
          We may process your information if you have given us specific
          permission (i.e. express consent) to use your personal information for
          a specific purpose, or in situations where your permission can be
          inferred (i.e. implied consent). You can withdraw your consent at any
          time. Click&nbsp;
          <a href="https://app.termly.io/builder/websites/7f9269a0-852d-40e1-a81b-ddf548e9433b/documents/2808214/Final%20Details/Version%20Date#withdrawconsent">
            here
          </a>{" "}
          to learn more.
        </p>
        <p>
          <br />
        </p>
        <p>
          In some exceptional cases, we may be legally permitted under
          applicable law to process your information without your consent,
          including, for example:
        </p>
        <ul>
          <li>
            <p>
              If collection is clearly in the interests of an individual and
              consent cannot be obtained in a timely way
            </p>
          </li>
          <li>
            <p>For investigations and fraud detection and prevention</p>
          </li>
          <li>
            <p>For business transactions provided certain conditions are met</p>
          </li>
          <li>
            <p>
              If it is contained in a witness statement and the collection is
              necessary to assess, process, or settle an insurance claim
            </p>
          </li>
          <li>
            <p>
              For identifying injured, ill, or deceased persons and
              communicating with next of kin
            </p>
          </li>
          <li>
            <p>
              If we have reasonable grounds to believe an individual has been,
              is, or may be victim of financial abuse
            </p>
          </li>
          <li>
            <p>
              If it is reasonable to expect collection and use with consent
              would compromise the availability or the accuracy of the
              information and the collection is reasonable for purposes related
              to investigating a br /each of an agreement or a contravention of
              the laws of Canada or a province
            </p>
          </li>
          <li>
            <p>
              If disclosure is required to comply with a subpoena, warrant,
              court order, or rules of the court relating to the production of
              records
            </p>
          </li>
          <li>
            <p>
              If it was produced by an individual in the course of their
              employment, business, or profession and the collection is
              consistent with the purposes for which the information was
              produced
            </p>
          </li>
          <li>
            <p>
              If the collection is solely for journalistic, artistic, or
              literary purposes
            </p>
          </li>
          <li>
            <p>
              If the information is publicly available and is specified by the
              regulations
            </p>
          </li>
        </ul>
        <p>
          <br />
        </p>
        <p>4. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?</p>
        <p>
          <br />
        </p>
        <p>
          In Short: We may share information in specific situations described in
          this section and/or with the following third parties.
        </p>
        <p>
          <br />
        </p>
        <p>
          We may need to share your personal information in the following
          situations:
        </p>
        <ul>
          <li>
            <p>
              Business Transfers. We may share or transfer your information in
              connection with, or during negotiations of, any merger, sale of
              company assets, financing, or acquisition of all or a portion of
              our business to another company.
            </p>
          </li>
        </ul>
        <p>
          <br />
        </p>
        <p>5. HOW LONG DO WE KEEP YOUR INFORMATION?</p>
        <p>
          <br />
        </p>
        <p>
          In Short: We keep your information for as long as necessary to fulfil
          the purposes outlined in this privacy notice unless otherwise required
          by law.
        </p>
        <p>
          <br />
        </p>
        <p>
          We will only keep your personal information for as long as it is
          necessary for the purposes set out in this privacy notice, unless a
          longer retention period is required or permitted by law (such as tax,
          accounting, or other legal requirements). No purpose in this notice
          will require us keeping your personal information for longer than the
          period of time in which users have an account with us.
        </p>
        <p>
          <br />
        </p>
        <p>
          When we have no ongoing legitimate business need to process your
          personal information, we will either delete or anonymise such
          information, or, if this is not possible (for example, because your
          personal information has been stored in backup archives), then we will
          securely store your personal information and isolate it from any
          further processing until deletion is possible.
        </p>
        <p>
          <br />
        </p>
        <p>6. HOW DO WE KEEP YOUR INFORMATION SAFE?</p>
        <p>
          <br />
        </p>
        <p>
          In Short: We aim to protect your personal information through a system
          of organisational and technical security measures.
        </p>
        <p>
          <br />
        </p>
        <p>
          We have implemented appropriate and reasonable technical and
          organisational security measures designed to protect the security of
          any personal information we process. However, despite our safeguards
          and efforts to secure your information, no electronic transmission
          over the Internet or information storage technology can be guaranteed
          to be 100% secure, so we cannot promise or guarantee that hackers,
          cybercriminals, or other unauthorised third parties will not be able
          to defeat our security and improperly collect, access, steal, or
          modify your information. Although we will do our best to protect your
          personal information, transmission of personal information to and from
          our Services is at your own risk. You should only access the Services
          within a secure environment.
        </p>
        <p>
          <br />
        </p>
        <p>7. DO WE COLLECT INFORMATION FROM MINORS?</p>
        <p>
          <br />
        </p>
        <p>
          In Short: We do not knowingly collect data from or market to children
          under 18 years of age.
        </p>
        <p>
          <br />
        </p>
        <p>
          We do not knowingly solicit data from or market to children under 18
          years of age. By using the Services, you represent that you are at
          least 18 or that you are the parent or guardian of such a minor and
          consent to such minor dependent&rsquo;s use of the Services. If we
          learn that personal information from users less than 18 years of age
          has been collected, we will deactivate the account and take reasonable
          measures to promptly delete such data from our records. If you become
          aware of any data we may have collected from children under age 18,
          please contact us at info@careerpro.uk.
        </p>
        <p>
          <br />
        </p>
        <p>8. WHAT ARE YOUR PRIVACY RIGHTS?</p>
        <p>
          <br />
        </p>
        <p>
          In Short: In some regions, such as the European Economic Area (EEA),
          United Kingdom (UK), and Canada, you have rights that allow you
          greater access to and control over your personal information. You may
          review, change, or terminate your account at any time.
        </p>
        <p>
          <br />
        </p>
        <p>
          In some regions (like the EEA, UK, and Canada), you have certain
          rights under applicable data protection laws. These may include the
          right (i) to request access and obtain a copy of your personal
          information, (ii) to request rectification or erasure; (iii) to
          restrict the processing of your personal information; and (iv) if
          applicable, to data portability. In certain circumstances, you may
          also have the right to object to the processing of your personal
          information. You can make such a request by contacting us by using the
          contact details provided in the section &apos;
          <a href="https://app.termly.io/builder/websites/7f9269a0-852d-40e1-a81b-ddf548e9433b/documents/2808214/Final%20Details/Version%20Date#contact">
            HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
          </a>
          &apos; below.
        </p>
        <p>
          <br />
        </p>
        <p>
          We will consider and act upon any request in accordance with
          applicable data protection laws.
        </p>
        <p>&nbsp;</p>
        <p>
          If you are located in the EEA or UK and you believe we are unlawfully
          processing your personal information, you also have the right to
          complain to your local data protection supervisory authority. You can
          find their contact details here:&nbsp;
          <a href="https://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm">
            https://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm
          </a>
          .
        </p>
        <p>
          <br />
        </p>
        <p>
          If you are located in Switzerland, the contact details for the data
          protection authorities are available here:&nbsp;
          <a href="https://www.edoeb.admin.ch/edoeb/en/home.html">
            https://www.edoeb.admin.ch/edoeb/en/home.html
          </a>
          .
        </p>
        <p>
          <br />
        </p>
        <p>
          Withdrawing your consent:&nbsp;If we are relying on your consent to
          process your personal information, which may be express and/or implied
          consent depending on the applicable law, you have the right to
          withdraw your consent at any time. You can withdraw your consent at
          any time by contacting us by using the contact details provided in the
          section &apos;
          <a href="https://app.termly.io/builder/websites/7f9269a0-852d-40e1-a81b-ddf548e9433b/documents/2808214/Final%20Details/Version%20Date#contact">
            HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
          </a>
          &apos; below or updating your preferences.
        </p>
        <p>
          <br />
        </p>
        <p>
          However, please note that this will not affect the lawfulness of the
          processing before its withdrawal nor, when applicable law allows, will
          it affect the processing of your personal information conducted in
          reliance on lawful processing grounds other than consent.
        </p>
        <p>
          <br />
        </p>
        <p>Account Information</p>
        <p>
          <br />
        </p>
        <p>
          If you would at any time like to review or change the information in
          your account or terminate your account, you can:
        </p>
        <ul>
          <li>
            <p>Log in to your account settings and update your user account.</p>
          </li>
        </ul>
        <p>
          Upon your request to terminate your account, we will deactivate or
          delete your account and information from our active databases.
          However, we may retain some information in our files to prevent fraud,
          troubleshoot problems, assist with any investigations, enforce our
          legal terms and/or comply with applicable legal requirements.
        </p>
        <p>
          <br />
        </p>
        <p>
          If you have questions or comments about your privacy rights, you may
          email us at&nbsp;info@careerpro.uk.
        </p>
        <p>
          <br />
        </p>
        <p>9. CONTROLS FOR DO-NOT-TRACK FEATURES</p>
        <p>
          <br />
        </p>
        <p>
          Most web br /owsers and some mobile operating systems and mobile
          applications include a Do-Not-Track (&apos;DNT&apos;) feature or
          setting you can activate to signal your privacy preference not to have
          data about your online br /owsing activities monitored and collected.
          At this stage no uniform technology standard for recognising and
          implementing DNT signals has been finalised. As such, we do not
          currently respond to DNT br /owser signals or any other mechanism that
          automatically communicates your choice not to be tracked online. If a
          standard for online tracking is adopted that we must follow in the
          future, we will inform you about that practice in a revised version of
          this privacy notice.
        </p>
        <p>
          <br />
        </p>
        <p>10. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</p>
        <p>
          <br />
        </p>
        <p>
          In Short: Yes, if you are a resident of California, you are granted
          specific rights regarding access to your personal information.
        </p>
        <p>
          <br />
        </p>
        <p>
          California Civil Code Section 1798.83, also known as the &apos;Shine
          The Light&apos; law, permits our users who are California residents to
          request and obtain from us, once a year and free of charge,
          information about categories of personal information (if any) we
          disclosed to third parties for direct marketing purposes and the names
          and addresses of all third parties with which we shared personal
          information in the immediately preceding calendar year. If you are a
          California resident and would like to make such a request, please
          submit your request in writing to us using the contact information
          provided below.
        </p>
        <p>
          <br />
        </p>
        <p>
          If you are under 18 years of age, reside in California, and have a
          registered account with Services, you have the right to request
          removal of unwanted data that you publicly post on the Services. To
          request removal of such data, please contact us using the contact
          information provided below and include the email address associated
          with your account and a statement that you reside in California. We
          will make sure the data is not publicly displayed on the Services, but
          please be aware that the data may not be completely or comprehensively
          removed from all our systems (e.g. backups, etc.).
        </p>
        <p>
          <br />
        </p>
        <p>CCPA Privacy Notice</p>
        <p>
          <br />
        </p>
        <p>
          The California Code of Regulations defines a &apos;resident&apos; as:
        </p>
        <p>
          <br />
        </p>
        <p>
          (1) every individual who is in the State of California for other than
          a temporary or transitory purpose and
        </p>
        <p>
          (2) every individual who is domiciled in the State of California who
          is outside the State of California for a temporary or transitory
          purpose
        </p>
        <p>
          <br />
        </p>
        <p>All other individuals are defined as &apos;non-residents&apos;.</p>
        <p>
          <br />
        </p>
        <p>
          If this definition of &apos;resident&apos; applies to you, we must
          adhere to certain rights and obligations regarding your personal
          information.
        </p>
        <p>
          <br />
        </p>
        <p>What categories of personal information do we collect?</p>
        <p>
          <br />
        </p>
        <p>
          We have collected the following categories of personal information in
          the past twelve (12) months:
        </p>
        <p>
          <br />
        </p>
        <div className="left">
          <table>
            <tbody>
              <tr>
                <td>
                  <p>Category</p>
                </td>
                <td>
                  <p>Examples</p>
                </td>
                <td>
                  <p>Collected</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>A. Identifiers</p>
                </td>
                <td>
                  <p>
                    Contact details, such as real name, alias, postal address,
                    telephone or mobile contact number, unique personal
                    identifier, online identifier, Internet Protocol address,
                    email address, and account name
                  </p>
                </td>
                <td>
                  <br />
                  <p>YES</p>
                  <br />
                </td>
              </tr>
              <tr>
                <td>
                  <p>
                    B. Personal information categories listed in the California
                    Customer Records statute
                  </p>
                </td>
                <td>
                  <p>
                    Name, contact information, education, employment, employment
                    history, and financial information
                  </p>
                </td>
                <td>
                  <br />
                  <p>YES</p>
                  <br />
                </td>
              </tr>
              <tr>
                <td>
                  <p>
                    C. Protected classification characteristics under California
                    or federal law
                  </p>
                </td>
                <td>
                  <p>Gender and date of birth</p>
                </td>
                <td>
                  <br />
                  <p>YES</p>
                  <br />
                </td>
              </tr>
              <tr>
                <td>
                  <p>D. Commercial information</p>
                </td>
                <td>
                  <p>
                    Transaction information, purchase history, financial
                    details, and payment information
                  </p>
                </td>
                <td>
                  <br />
                  <p>YES</p>
                  <br />
                </td>
              </tr>
              <tr>
                <td>
                  <p>E. Biometric information</p>
                </td>
                <td>
                  <p>Fingerprints and voiceprints</p>
                </td>
                <td>
                  <br />
                  <p>NO</p>
                  <br />
                </td>
              </tr>
              <tr>
                <td>
                  <p>F. Internet or other similar network activity</p>
                </td>
                <td>
                  <p>
                    Br /owsing history, search history, online behaviour,
                    interest data, and interactions with our and other websites,
                    applications, systems, and advertisements
                  </p>
                </td>
                <td>
                  <br />
                  <p>NO</p>
                  <br />
                </td>
              </tr>
              <tr>
                <td>
                  <p>G. Geolocation data</p>
                </td>
                <td>
                  <p>Device location</p>
                </td>
                <td>
                  <br />
                  <p>NO</p>
                  <br />
                </td>
              </tr>
              <tr>
                <td>
                  <p>
                    H. Audio, electronic, visual, thermal, olfactory, or similar
                    information
                  </p>
                </td>
                <td>
                  <p>
                    Images and audio, video or call recordings created in
                    connection with our business activities
                  </p>
                </td>
                <td>
                  <br />
                  <p>NO</p>
                  <br />
                </td>
              </tr>
              <tr>
                <td>
                  <p>I. Professional or employment-related information</p>
                </td>
                <td>
                  <p>
                    Business contact details in order to provide you our
                    Services at a business level or job title, work history, and
                    professional qualifications if you apply for a job with us
                  </p>
                </td>
                <td>
                  <br />
                  <p>YES</p>
                  <br />
                </td>
              </tr>
              <tr>
                <td>
                  <p>J. Education Information</p>
                </td>
                <td>
                  <p>Student records and directory information</p>
                </td>
                <td>
                  <br />
                  <p>YES</p>
                  <br />
                </td>
              </tr>
              <tr>
                <td>
                  <p>K. Inferences drawn from other personal information</p>
                </td>
                <td>
                  <p>
                    Inferences drawn from any of the collected personal
                    information listed above to create a profile or summary
                    about, for example, an individual&rsquo;s preferences and
                    characteristics
                  </p>
                </td>
                <td>
                  <br />
                  <p>YES</p>
                  <br />
                </td>
              </tr>
              <tr>
                <td>
                  <p>L. Sensitive Personal Information</p>
                </td>
                <td>
                  <p>Account login information and passport numbers</p>
                </td>
                <td>
                  <br />
                  <p>YES</p>
                  <br />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <p>
          <br />
        </p>
        <p>
          We will use and retain the collected personal information as needed to
          provide the Services or for:
        </p>
        <ul>
          <li>
            <p>Category A - As long as the user has an account with us</p>
          </li>
          <li>
            <p>Category B - As long as the user has an account with us</p>
          </li>
          <li>
            <p>Category C - As long as the user has an account with us</p>
          </li>
          <li>
            <p>Category D - As long as the user has an account with us</p>
          </li>
          <li>
            <p>Category I - As long as the user has an account with us</p>
          </li>
          <li>
            <p>Category J - As long as the user has an account with us</p>
          </li>
          <li>
            <p>Category K - As long as the user has an account with us</p>
          </li>
          <li>
            <p>Category L - As long as the user has an account with us</p>
          </li>
        </ul>
        <p>
          Category L information may be used, or disclosed to a service provider
          or contractor, for additional, specified purposes. You have the right
          to limit the use or disclosure of your sensitive personal information.
        </p>
        <p>
          <br />
        </p>
        <p>
          We may also collect other personal information outside of these
          categories through instances where you interact with us in person,
          online, or by phone or mail in the context of:
        </p>
        <ul>
          <li>
            <p>Receiving help through our customer support channels;</p>
          </li>
          <li>
            <p>Participation in customer surveys or contests; and</p>
          </li>
          <li>
            <p>
              Facilitation in the delivery of our Services and to respond to
              your inquiries.
            </p>
          </li>
        </ul>
        <p>How do we use and share your personal information?</p>
        <p>
          <br />
        </p>
        <p>
          More information about our data collection and sharing practices can
          be found in this privacy notice.
        </p>
        <p>
          <br />
        </p>
        <p>
          You may contact us by email at info@careerpro.uk, or by referring to
          the contact details at the bottom of this document.
        </p>
        <p>
          <br />
        </p>
        <p>
          If you are using an authorised agent to exercise your right to opt out
          we may deny a request if the authorised agent does not submit proof
          that they have been validly authorised to act on your behalf.
        </p>
        <p>
          <br />
        </p>
        <p>Will your information be shared with anyone else?</p>
        <p>
          <br />
        </p>
        <p>
          We may disclose your personal information with our service providers
          pursuant to a written contract between us and each service provider.
          Each service provider is a for-profit entity that processes the
          information on our behalf, following the same strict privacy
          protection obligations mandated by the CCPA.
        </p>
        <p>
          <br />
        </p>
        <p>
          We may use your personal information for our own business purposes,
          such as for undertaking internal research for technological
          development and demonstration. This is not considered to be
          &apos;selling&apos; of your personal information.
        </p>
        <p>
          <br />
        </p>
        <p>
          career pro has not disclosed, sold, or shared any personal information
          to third parties for a business or commercial purpose in the preceding
          twelve (12) months. career pro will not sell or share personal
          information in the future belonging to website visitors, users, and
          other consumers.
        </p>
        <p>
          <br />
        </p>
        <p>Your rights with respect to your personal data</p>
        <p>
          <br />
        </p>
        <p>Right to request deletion of the data &mdash; Request to delete</p>
        <p>
          <br />
        </p>
        <p>
          You can ask for the deletion of your personal information. If you ask
          us to delete your personal information, we will respect your request
          and delete your personal information, subject to certain exceptions
          provided by law, such as (but not limited to) the exercise by another
          consumer of his or her right to free speech, our compliance
          requirements resulting from a legal obligation, or any processing that
          may be required to protect against illegal activities.
        </p>
        <p>
          <br />
        </p>
        <p>Right to be informed &mdash; Request to know</p>
        <p>
          <br />
        </p>
        <p>Depending on the circumstances, you have a right to know:</p>
        <ul>
          <li>
            <p>whether we collect and use your personal information;</p>
          </li>
          <li>
            <p>the categories of personal information that we collect;</p>
          </li>
          <li>
            <p>
              the purposes for which the collected personal information is used;
            </p>
          </li>
          <li>
            <p>
              whether we sell or share personal information to third parties;
            </p>
          </li>
          <li>
            <p>
              the categories of personal information that we sold, shared, or
              disclosed for a business purpose;
            </p>
          </li>
          <li>
            <p>
              the categories of third parties to whom the personal information
              was sold, shared, or disclosed for a business purpose;
            </p>
          </li>
          <li>
            <p>
              the business or commercial purpose for collecting, selling, or
              sharing personal information; and
            </p>
          </li>
          <li>
            <p>
              the specific pieces of personal information we collected about
              you.
            </p>
          </li>
        </ul>
        <p>
          In accordance with applicable law, we are not obligated to provide or
          delete consumer information that is de-identified in response to a
          consumer request or to re-identify individual data to verify a
          consumer request.
        </p>
        <p>
          <br />
        </p>
        <p>
          Right to Non-Discrimination for the Exercise of a Consumer&rsquo;s
          Privacy Rights
        </p>
        <p>
          <br />
        </p>
        <p>
          We will not discriminate against you if you exercise your privacy
          rights.
        </p>
        <p>
          <br />
        </p>
        <p>
          Right to Limit Use and Disclosure of Sensitive Personal Information
        </p>
        <p>
          <br />
        </p>
        <p>If the business collects any of the following:</p>
        <ul>
          <li>
            <p>
              social security information, drivers&apos; licenses, state ID
              cards, passport numbers
            </p>
          </li>
          <li>
            <p>account login information</p>
          </li>
          <li>
            <p>
              credit card numbers, financial account information, or credentials
              allowing access to such accounts
            </p>
          </li>
          <li>
            <p>precise geolocation</p>
          </li>
          <li>
            <p>
              racial or ethnic origin, religious or philosophical beliefs, union
              membership
            </p>
          </li>
          <li>
            <p>
              the contents of email and text, unless the business is the
              intended recipient of the communication
            </p>
          </li>
          <li>
            <p>genetic data, biometric data, and health data</p>
          </li>
          <li>
            <p>data concerning sexual orientation and sex life</p>
          </li>
        </ul>
        <p>
          you have the right to direct that business to limit its use of your
          sensitive personal information to that use which is necessary to
          perform the Services.
        </p>
        <p>
          <br />
        </p>
        <p>
          Once a business receives your request, they are no longer allowed to
          use or disclose your sensitive personal information for any other
          purpose unless you provide consent for the use or disclosure of
          sensitive personal information for additional purposes.
        </p>
        <p>
          <br />
        </p>
        <p>
          Please note that sensitive personal information that is collected or
          processed without the purpose of inferring characteristics about a
          consumer is not covered by this right, as well as the publicly
          available information.
        </p>
        <p>
          <br />
        </p>
        <p>
          To exercise your right to limit use and disclosure of sensitive
          personal information, please email info@careerpro.uk or visit:
          info@careerpro.uk.
        </p>
        <p>
          <br />
        </p>
        <p>Verification process</p>
        <p>
          <br />
        </p>
        <p>
          Upon receiving your request, we will need to verify your identity to
          determine you are the same person about whom we have the information
          in our system. These verification efforts require us to ask you to
          provide information so that we can match it with information you have
          previously provided us. For instance, depending on the type of request
          you submit, we may ask you to provide certain information so that we
          can match the information you provide with the information we already
          have on file, or we may contact you through a communication method
          (e.g. phone or email) that you have previously provided to us. We may
          also use other verification methods as the circumstances dictate.
        </p>
        <p>
          <br />
        </p>
        <p>
          We will only use personal information provided in your request to
          verify your identity or authority to make the request. To the extent
          possible, we will avoid requesting additional information from you for
          the purposes of verification. However, if we cannot verify your
          identity from the information already maintained by us, we may request
          that you provide additional information for the purposes of verifying
          your identity and for security or fraud-prevention purposes. We will
          delete such additionally provided information as soon as we finish
          verifying you.
        </p>
        <p>
          <br />
        </p>
        <p>Other privacy rights</p>
        <ul>
          <li>
            <p>
              You may object to the processing of your personal information.
            </p>
          </li>
          <li>
            <p>
              You may request correction of your personal data if it is
              incorrect or no longer relevant, or ask to restrict the processing
              of the information.
            </p>
          </li>
          <li>
            <p>
              You can designate an authorised agent to make a request under the
              CCPA on your behalf. We may deny a request from an authorised
              agent that does not submit proof that they have been validly
              authorised to act on your behalf in accordance with the CCPA.
            </p>
          </li>
          <li>
            <p>
              You may request to opt out from future selling or sharing of your
              personal information to third parties. Upon receiving an opt-out
              request, we will act upon the request as soon as feasibly
              possible, but no later than fifteen (15) days from the date of the
              request submission.
            </p>
          </li>
        </ul>
        <p>
          To exercise these rights, you can contact us by email at
          info@careerpro.uk, or by referring to the contact details at the
          bottom of this document. If you have a complaint about how we handle
          your data, we would like to hear from you.
        </p>
        <p>
          <br />
        </p>
        <p>11. DO VIRGINIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</p>
        <p>
          <br />
        </p>
        <p>
          In Short: Yes, if you are a resident of Virginia, you may be granted
          specific rights regarding access to and use of your personal
          information.
        </p>
        <p>
          <br />
        </p>
        <p>Virginia CDPA Privacy Notice</p>
        <p>
          <br />
        </p>
        <p>Under the Virginia Consumer Data Protection Act (CDPA):</p>
        <p>
          <br />
        </p>
        <p>
          &apos;Consumer&apos; means a natural person who is a resident of the
          Commonwealth acting only in an individual or household context. It
          does not include a natural person acting in a commercial or employment
          context.
        </p>
        <p>
          <br />
        </p>
        <p>
          &apos;Personal data&apos; means any information that is linked or
          reasonably linkable to an identified or identifiable natural person.
          &apos;Personal data&apos; does not include de-identified data or
          publicly available information.
        </p>
        <p>
          <br />
        </p>
        <p>
          &apos;Sale of personal data&apos; means the exchange of personal data
          for monetary consideration.
        </p>
        <p>
          <br />
        </p>
        <p>
          If this definition &apos;consumer&apos; applies to you, we must adhere
          to certain rights and obligations regarding your personal data.
        </p>
        <p>
          <br />
        </p>
        <p>
          The information we collect, use, and disclose about you will vary
          depending on how you interact with career pro and our Services. To
          find out more, please visit the following links:
        </p>
        <ul>
          <li>
            <p>
              <a href="https://app.termly.io/builder/websites/7f9269a0-852d-40e1-a81b-ddf548e9433b/documents/2808214/Final%20Details/Version%20Date#infocollect">
                Personal data we collect
              </a>
            </p>
          </li>
          <li>
            <p>
              <a href="https://app.termly.io/builder/websites/7f9269a0-852d-40e1-a81b-ddf548e9433b/documents/2808214/Final%20Details/Version%20Date#infouse">
                How we use your personal data
              </a>
            </p>
          </li>
          <li>
            <p>
              <a href="https://app.termly.io/builder/websites/7f9269a0-852d-40e1-a81b-ddf548e9433b/documents/2808214/Final%20Details/Version%20Date#whoshare">
                When and with whom we share your personal data
              </a>
            </p>
          </li>
        </ul>
        <p>Your rights with respect to your personal data</p>
        <ul>
          <li>
            <p>
              Right to be informed whether or not we are processing your
              personal data
            </p>
          </li>
          <li>
            <p>Right to access your personal data</p>
          </li>
          <li>
            <p>Right to correct inaccuracies in your personal data</p>
          </li>
          <li>
            <p>Right to request deletion of your personal data</p>
          </li>
          <li>
            <p>
              Right to obtain a copy of the personal data you previously shared
              with us
            </p>
          </li>
          <li>
            <p>
              Right to opt out of the processing of your personal data if it is
              used for targeted advertising, the sale of personal data, or
              profiling in furtherance of decisions that produce legal or
              similarly significant effects (&apos;profiling&apos;)
            </p>
          </li>
        </ul>
        <p>
          career pro&nbsp;has not sold any personal data to third parties for
          business or commercial purposes.&nbsp;career pro&nbsp;will not sell
          personal data in the future belonging to website visitors, users, and
          other consumers.
        </p>
        <p>
          <br />
        </p>
        <p>Exercise your rights provided under the Virginia CDPA</p>
        <p>
          <br />
        </p>
        <p>
          More information about our data collection and sharing practices can
          be found in this privacy notice.
        </p>
        <p>
          <br />
        </p>
        <p>
          You may contact us by email at info@careerpro.uk, by visiting
          info@careerpro.uk, or by referring to the contact details at the
          bottom of this document.
        </p>
        <p>
          <br />
        </p>
        <p>
          If you are using an authorised agent to exercise your rights, we may
          deny a request if the authorised agent does not submit proof that they
          have been validly authorised to act on your behalf.
        </p>
        <p>
          <br />
        </p>
        <p>Verification process</p>
        <p>
          <br />
        </p>
        <p>
          We may request that you provide additional information reasonably
          necessary to verify you and your consumer&apos;s request. If you
          submit the request through an authorised agent, we may need to collect
          additional information to verify your identity before processing your
          request.
        </p>
        <p>
          <br />
        </p>
        <p>
          Upon receiving your request, we will respond without undue delay, but
          in all cases, within forty-five (45) days of receipt. The response
          period may be extended once by forty-five (45) additional days when
          reasonably necessary. We will inform you of any such extension within
          the initial 45-day response period, together with the reason for the
          extension.
        </p>
        <p>
          <br />
        </p>
        <p>Right to appeal</p>
        <p>
          <br />
        </p>
        <p>
          If we decline to take action regarding your request, we will inform
          you of our decision and reasoning behind it. If you wish to appeal our
          decision, please email us at info@careerpro.uk. Within sixty (60) days
          of receipt of an appeal, we will inform you in writing of any action
          taken or not taken in response to the appeal, including a written
          explanation of the reasons for the decisions. If your appeal if
          denied, you may contact the Attorney General to&nbsp;
          <a href="https://www.oag.state.va.us/consumer-protection/index.php/file-a-complaint">
            submit a complaint
          </a>
          .
        </p>
        <p>
          <br />
        </p>
        <p>12. DO WE MAKE UPDATES TO THIS NOTICE?</p>
        <p>
          <br />
        </p>
        <p>
          In Short: Yes, we will update this notice as necessary to stay
          compliant with relevant laws.
        </p>
        <p>
          <br />
        </p>
        <p>
          We may update this privacy notice from time to time. The updated
          version will be indicated by an updated &apos;Revised&apos; date and
          the updated version will be effective as soon as it is accessible. If
          we make material changes to this privacy notice, we may notify you
          either by prominently posting a notice of such changes or by directly
          sending you a notification. We encourage you to review this privacy
          notice frequently to be informed of how we are protecting your
          information.
        </p>
        <p>
          <br />
        </p>
        <p>13. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</p>
        <p>
          <br />
        </p>
        <p>
          If you have questions or comments about this notice, you may email us
          at info@careerpro.uk or by post to:
        </p>
        <p>
          <br />
        </p>
        <p>career pro</p>
        <p>2nd Floor, Ashford House 100 College Road,</p>
        <p>Harrow HA1 1BQ</p>
        <p>__________,&nbsp;London &nbsp;</p>
        <p>United Kingdom</p>
        <p>
          <br />
        </p>
        <p>
          14. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM
          YOU?
        </p>
        <p>
          <br />
        </p>
        <p>
          Based on the applicable laws of your country, you may have the right
          to request access to the personal information we collect from you,
          change that information, or delete it. To request to review, update,
          or delete your personal information, please visit:&nbsp;
          <a href="mailto:info@careerpro.uk">info@careerpro.uk</a>.
        </p>
        <p>
          <br />
        </p>
        <p>
          <br />
        </p>
        <p>
          <br />
        </p>
        <p>CareerPro General Terms of Service</p>
        <p>
          This is the Careerpro.uk web portal or Application. Your use of this
          web portal or application is subject to the following terms and
          conditions, which you are deemed to accept each time you use the web
          portal or application. We want you to understand it, which is why we
          must explain it in detail. We urge you to read these terms or any
          section of interest to you. You are agreeing to proceed under them.
        </p>
        <p>
          <br />
        </p>
        <p>1-Career consultation&nbsp;</p>
        <p>
          Careerpro offers you resume review, resume scan, offer coaching,
          consulting sessions, writing services, general career guidance, or
          other consultation services (collectively, &ldquo;Career
          Services&rdquo;). By using Career Services, you request and consent
          that Careerpro, provide you with the service you select. You may only
          use Career Services for advice concerning your own resume, offer, or
          employment situation and not another person&rsquo;s. As a user of the
          portal or mobile application Careerpro may also, from time to time,
          provide you career or hiring guides via our portal, mobile
          application, email, or other channels as part of its services to you.
          For example, Careerpro will provide tips on improving your resume or
          how to use your resume effectively in the job application process,
          commonly asked interview questions, the next steps after you have
          submitted an application, and how to prepare for a new job after you
          have been hired.&nbsp;
        </p>
        <p>
          Careerpro offers, as part of Career Services, the opportunity for you
          to engage with third parties, sometimes referred to as career coaches
          or professional writers, to discuss job search, resume writing, or
          career strategies, either through the Site or in person. Regardless of
          how you interact with them, you acknowledge that these third parties
          are not employees of careerpro and that Careerpro is not responsible
          for the content of any such discussion or any actions taken or not
          taken based on the contents of such discussion. Do not share any
          information you consider confidential or personally sensitive with
          these third parties. Careerpro does not guarantee the quality or
          effectiveness of any advice, recommendations, or guidance you may
          receive from these third parties. You agree that, to the maximum
          extent permitted by law, Careerpro will have no liability arising from
          any discussion you have with such a third party.&nbsp;
        </p>
        <p>
          <br />
        </p>
        <p>
          <br />
        </p>
        <p>
          When filling out forms associated with Career Services, you agree to
          provide complete and accurate information. Any information you upload
          through the forms, including a resume, constitutes User Content and
          shall be treated in accordance with any User Content sections
          herein.&nbsp;
        </p>
        <p>
          You understand that Careerpro may give suggestions or information
          regarding best practices, however, you understand that it is
          ultimately your responsibility to determine how to pursue your job
          search. You further understand that in providing any such career or
          hiring guides, Careerpro is not acting as a job placement agency or
          staffing firm. You are responsible for reviewing any career or
          hiring-related information provided to you, for example, for accuracy
          and completeness. You are also responsible for editing the information
          before you use it, for example, for an employment application.
          Careerpro is not responsible for the accuracy or completeness of any
          information that Careerpro provides you. You understand that by using
          Career Services or referring to or using any career or hiring
          information, you are not guaranteed Internships, work placements or
          sponsorship jobs, assurance of being hired, or a higher salary or
          increased benefit,and you take full responsibility for use of these
          services or information.&nbsp;
        </p>
        <p>
          <br />
        </p>
        <p>2. Job Ads or Job Listings</p>
        <p>
          CareerPro may make available Job Ads advertising employment
          opportunities and other job-related content, including links to
          third-party websites (&ldquo;Job Listings&rdquo; or &ldquo;Job
          Ads&rdquo;), through careerpro&rsquo;s search results or otherwise
          through the Site or mobile application. Careerpro displays Job Ads
          based on a combination of employers, Job Ads are created and provided
          by third parties over whom careerpro exercises no control; you
          acknowledge and understand that careerpro has no control over the
          content of Job Ads, links to or from Job Ads, or any conditions third
          parties might impose once a Job Seeker has submitted an application or
          left the Site. For example, some of these third parties may attempt to
          charge Job Seekers a fee to apply for a particular job.
        </p>
        <p>
          If you leave the career pro Site and choose to enter a third-party
          website, you accept any terms and conditions imposed by that third
          party. Careerpro has no obligation to screen any Job Ads, or to
          include any Job Ads, in its search results or other listings, and may
          exclude or remove any Job Ads from the Site or your search result
          without any obligation to provide reasoning for removal or exclusion.
          You understand and agree that Careerpro has no obligation to present
          you with any or all Job Ads. We cannot confirm the accuracy or
          completeness of any Job Ad or other information submitted by any
          Employer or other user, including the identity of such Employer or
          another user. Careerpro assumes no responsibility, and disclaims all
          liability for the content, accuracy, completeness, legality,
          reliability, or availability of any Job Ads, or other information
          submitted by any Employer or other user.
        </p>
        <p>
          Careerpro does not guarantee that applying to jobs through a dedicated
          page or website will lead to a better job application experience, a
          job interview, or a job offer. The dedicated pages or website do not
          contain an exhaustive list of Job Ads, and no inferences can be drawn
          with respect to Job Ads or Employers that are not displayed on
          dedicated pages.
        </p>
        <p>
          Please note that Job Ads may expire between the time you submit your
          application and from the time it is received. Careerpro has no
          responsibility for expired Job Ads or for delivering applications
          prior to a Job Ad&rsquo;s expiration, and Careerpro and its
          third-party providers may store your application and related
          information regardless of whether a Job Listing has been closed or is
          no longer available on the Site.
        </p>
        <p>
          By using Careerpro, you agree that Careerpro is not responsible for
          the content of the Employer&rsquo;s job application, messages,
          screener questions, skills assessments, or their format or method of
          delivery, and that Careerpro does not guarantee receipt of your
          application by the Employer, or your receipt of messages from the
          Employer. Please note that Careerpro does not choose the questions
          asked by Employers or decide the job qualification criteria of
          Employers. Employers are solely responsible for compliance with all
          applicable laws, including the Fair Credit Reporting Act and similar
          laws, anti-discrimination laws such as Title VII of the Civil Rights
          Act of 1964, as amended, the Age Discrimination in Employment
          Act,Disabilities Act, and any applicable data protection or privacy
          laws.
        </p>
        <p>3-Communications and Other Actions on the Site</p>
        <p>
          When you view, send, store or receive communications or materials
          (including Job Listings, resumes, messages, applications, questions
          and responses in applications, and any other information) &nbsp;on or
          through the Site, you agree to your communication and materials being
          processed, analyzed, reviewed, and stored, including via automated
          means, for data analysis, quality control, enforcement of the
          Sites&rsquo; rules and other Careerpro policies, content moderation,
          and to improve the Site or any other Careerpro product or service
          whether via automated means or otherwise.
        </p>
        <p>
          <br />
        </p>
        <p>4-Payment&nbsp;</p>
        <p>
          To the extent that you use a paid Career Services offering, you shall
          be charged a one-time payment of &pound;46, and limited cancellation
          rights are available. You acknowledge and agree that any credit card,
          bank account, and related billing and payment information that you
          provide to Careerpro may be shared by Careerpro with companies who
          work on Careerpro&rsquo;s behalf, such as payment processors.
        </p>
        <p>
          <br />
        </p>
        <p>
          <br />
        </p>
        <p>
          <br />
        </p>
        <p>
          <br />
        </p>
      </Row>

      <FooterCustom />
    </React.Fragment>
  )
}

export default PrivacyPolicy
