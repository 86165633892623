import React, { useEffect, useState } from "react"
import Breadcrumbs from "components/Common/Breadcrumb"
import { Button, Card, CardBody, Col, Container, Label, Row } from "reactstrap"
import { AvField, AvForm } from "availity-reactstrap-validation"
import MetaTag from "components/Common/Meta-tag"
import { useDispatch, useSelector } from "react-redux"
import { useHistory, useParams } from "react-router-dom"
import { getApplicationDetails, updateJobApplication } from "store/actions"
import { map } from "lodash"
import { categories, countries } from "./CategoryList"
import FormEditors from "./Editor"
import ImageUpload from "components/Common/ImageUploader"

function UpdateJobApplications() {
  const dispatch = useDispatch()
  const params = useParams()
  const history = useHistory()

  const applicationId = params.id
  const [jobTitle, setJobTitle] = useState()
  const [jobDescription, setJobDescription] = useState()
  const [selectedFile, setSelectedFile] = useState(null)
  const [previewURL, setPreviewURL] = useState(null)

  console.log(selectedFile)

  const { applicationData } = useSelector(state => ({
    applicationData: state.StudentDetails.applicationSingleData,
  }))
  console.log(applicationData)

  useEffect(() => {
    dispatch(getApplicationDetails(applicationId))
  }, [dispatch, params.id])

  const handleValidSubmit = e => {
    const updatedData = {
      description: jobDescription,
      title: jobTitle,
      image: selectedFile,
    }
    if (applicationId) {
      dispatch(updateJobApplication(updatedData, applicationId, history))
    }
  }

  useEffect(() => {
    setJobDescription(applicationData?.description)
    setJobTitle(applicationData?.title)
    setSelectedFile(applicationData?.image)
    setPreviewURL(applicationData?.image)
  }, [applicationData])

  return (
    <div>
      <MetaTag title="Update job Applications" />
      <div className="page-content">
        <Breadcrumbs
          title="Dashboard"
          breadcrumbItem="Update Job Application"
        />
        <Container fluid>
          <Card responsive className="">
            <CardBody>
              <AvForm
                onValidSubmit={(e, v) => {
                  handleValidSubmit(e, v)
                }}
                className="jobApplicationForm"
              >
                <Row className="my-1">
                  <Col md={8}>
                    <label>Job Title</label>
                    <FormEditors content={jobTitle} setContent={setJobTitle} />
                  </Col>
                  <Col md={4}>
                    <label>Job Images</label>
                    <ImageUpload
                      selectedFile={selectedFile}
                      setSelectedFile={setSelectedFile}
                      setPreviewURL={setPreviewURL}
                      previewURL={previewURL}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md={12}>
                    <label>Job Description</label>
                    <FormEditors
                      content={jobDescription}
                      setContent={setJobDescription}
                    />
                  </Col>
                </Row>
                {/* <div className="d-flex align-items-center justify-content-between mb-3">
                  <Label
                    className="p text-muted font-size-12"
                    style={{ width: 160 }}
                  >
                    Company:
                  </Label>
                  <AvField
                    label=""
                    name="company"
                    type="text"
                    value={applicationData?.company}
                  />
                </div>
                <div className="d-flex align-items-center justify-content-between mb-3">
                  <Label
                    className="text-muted font-size-12"
                    style={{ width: 160 }}
                  >
                    Position:
                  </Label>
                  <AvField
                    type="text"
                    name="position"
                    label=""
                    value={applicationData?.position}
                  />
                </div>

                <div className="d-flex align-items-center justify-content-between mb-3">
                  <Label
                    className="text-muted font-size-12"
                    style={{ width: 160 }}
                  >
                    Application Deadline:
                  </Label>
                  <AvField
                    type="text"
                    validate={{
                      required: {
                        value: false,
                      },
                    }}
                    label=""
                    name="last_date"
                    value={applicationData?.last_date}
                  />
                </div>
                <div className="d-flex align-items-center justify-content-between mb-3">
                  <Label
                    className="text-muted font-size-12"
                    style={{ width: 160 }}
                  >
                    Job Description:
                  </Label>

                  <AvField
                    type="text"
                    label=""
                    name="job_description"
                    value={applicationData?.job_description}
                  />
                </div>

                <div className="d-flex align-items-center justify-content-between mb-3">
                  <Label
                    className="text-muted font-size-12"
                    style={{ width: 160 }}
                  >
                    Category:
                  </Label>
                  <AvField
                    type="select"
                    label=""
                    name="category"
                    value={applicationData?.category}
                    validate={{
                      required: {
                        value: true,
                      },
                    }}
                  >
                    {" "}
                    <option>choose category.....</option>
                    {map(categories, (item, key) => (
                      <option key={key} value={item}>
                        {item}
                      </option>
                    ))}
                  </AvField>
                </div>

                <div className="d-flex align-items-center justify-content-between mb-3">
                  <Label
                    className="text-muted font-size-12"
                    style={{ width: 160 }}
                  >
                    Country:
                  </Label>
                  <AvField
                    type="select"
                    label=""
                    name="country"
                    value={applicationData?.country}
                    validate={{
                      required: {
                        value: true,
                      },
                    }}
                  >
                    {" "}
                    <option>choose country.....</option>
                    {map(countries, (item, key) => (
                      <option key={key} value={item}>
                        {item}
                      </option>
                    ))}
                  </AvField>
                </div>

                <div className="d-flex align-items-center justify-content-between mb-3">
                  <Label
                    className="text-muted font-size-12"
                    style={{ width: 160 }}
                  >
                    Job Link:
                  </Label>

                  <AvField
                    type="text"
                    label=""
                    name="job_link"
                    value={applicationData?.job_link}
                  />
                </div>

                <AvField
                  id="horizontal-email-Input"
                  hidden
                  name="stage"
                  value={applicationData?.stage || statusRole?.role}
                  className="form-control"
                  type="select"
                >
                  {statusRole?.map((item, key) => (
                    <option key={key} value={item.role}>
                      {item.role}
                    </option>
                  ))}
                </AvField> */}
                <div className="d-flex align-items-center justify-content-end my-3">
                  <Button color="success" className="w-md " type="submit">
                    Update
                  </Button>
                </div>
              </AvForm>
            </CardBody>
          </Card>
        </Container>
      </div>
    </div>
  )
}

export default UpdateJobApplications

const statusRole = [
  {
    role: "applied",
  },
  {
    role: "declined",
  },
  {
    role: "pending",
  },
]
