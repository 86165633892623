import React from "react"

import "./footer.css"
import { Row } from "reactstrap"
import { logo } from "assets/images/logo"
import { Link } from "react-router-dom"

function FooterCustom() {
  return (
    <footer className="footer-section">
      <div className="footer-top">
        <div className="container">
          <Row>
            <Link to="/" className="site-logo">
              <img
                src={logo}
                alt="logo"
                width={31}
                height={200}
                style={{
                  objectFit: "cover",
                  height: 31,
                  width: 200,
                  marginLeft: "-20px",
                }}
              />
            </Link>
          </Row>
          <div className="row p-3">
            <div className="col-lg-3 col-md-6 col-sm-6 ">
              <div className="widget company-intro-widget">
                <p className="mb-0 pb-0">
                  Associated with
                  <br />
                  <a href="#" className="post-date">
                    <i>Studbroad Overseas Education</i>
                  </a>
                </p>
                <Link to={"/privacy-policy"}>Privacy Policy</Link>

                <ul className="company-footer-contact-list">
                  <li className="mb-3">
                    <a href="mailto:info@careerpro.uk" className="text-black">
                      <i className="fa fa-envelope color-black"></i>
                      info@careerpro.uk
                    </a>
                  </li>
                  <li className="mb-3">
                    <a
                      href="http://www.studbroad.com/"
                      target="_blank"
                      rel="noreferrer"
                      className="text-black"
                    >
                      <i className="fas fa-globe color-black"></i>
                      www.studbroad.com
                    </a>
                  </li>
                  <li className="mb-3">
                    <a href="tel:+447741639374" className="text-black">
                      <i className="fas fa-phone color-black"></i>+447741639374
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-2 col-md-6 col-sm-6 md:d-none"></div>
            <div className="col-lg-2 col-md-6 col-sm-6 col-6 md:mt-4">
              <div className="widget course-links-widget">
                <h5 className="widget-title mb-0">London</h5>
                <a href="#" className="post-date">
                  <span className="text-muted">Corporate Office</span>
                </a>

                <div className="footer-newsletter mt-4">
                  <p>
                    2nd Floor, Ashford House 100 College Road, Harrow HA1 1BQ
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-2 col-md-6 col-sm-6 col-6 md:mt-4">
              <div className="widget latest-news-widget">
                <h5 className="widget-title mb-0">Wales</h5>
                <a href="#" className="post-date">
                  <span className="text-muted">Facilitate Office</span>
                </a>

                <div className="footer-newsletter mt-4">
                  <p>
                    Gellifaelog Terrace penydarren Merthyr Tydfil Wales CF47 9HL
                  </p>
                </div>
              </div>
            </div>
            <div
              className="col-lg-2 col-md-6 col-sm-6 col-6 md:mt-4"
              style={{ flex: 1 }}
            >
              <div className="widget newsletter-widget">
                <h5 className="widget-title mb-0">Malabar</h5>
                <a href="#" className="post-date">
                  <span className="text-muted">Head Office</span>
                </a>
                <div className="footer-newsletter mt-4">
                  <p>
                    2nd floor, Madeena Tower
                    <br /> Up Hill, Malappuram
                    <br /> 676505 Kerala
                  </p>
                </div>
              </div>
            </div>

            <div
              className="col-lg-2 col-md-6 col-sm-6 col-6 md:mt-4 "
              style={{ flex: 1 }}
            >
              <div className="widget newsletter-widget">
                <h5 className="widget-title mb-0">Cochin</h5>
                <a href="#" className="post-date">
                  <span className="text-muted">Branch Office</span>
                </a>
                <div className="footer-newsletter mt-4">
                  <p>
                    Opposite of Café Coffee Day
                    <br /> Kaloor - Kadavanthara Road Ernakulam, Kerala 682017
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-bottom">
        <div className="container">
          <div
            className="row d-flex align-items-center
    justify-content-center"
          >
            <div className="col-md-6 col-sm-6 text-sm-left text-center">
              <span className="copy-right-text">
                Powered By{" "}
                <a href="https://osperb.com" target="_blank" rel="noreferrer">
                  Osperb
                </a>{" "}
                {/* All Rights Reserved. */}
              </span>
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default FooterCustom
