import { AvField } from "availity-reactstrap-validation"
import React from "react"
import PropTypes from "prop-types"

function CareerInput(prop) {
  return (
    <div
      className={
        "d-flex align-items-center mb-4 w-100 cutsom-input " + prop.className
      }
    >
      {prop?.mylabel && (
        <span className="me-1" style={{ minWidth: "30px" }}>
          {prop?.mylabel}
        </span>
      )}
      <AvField {...prop} value={prop?.value ? prop?.value : ""} />
    </div>
  )
}

export default CareerInput

CareerInput.propTypes = {
  name: PropTypes.string,
  value: PropTypes.any,
}
