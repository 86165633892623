import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"

import { connect, useDispatch, useSelector } from "react-redux"
import "react-drawer/lib/react-drawer.css"
import { Link } from "react-router-dom"

// Import menuDropdown
import ProfileMenu from "../CommonForBoth/TopbarDropdown/ProfileMenu"

//i18n
import { withTranslation } from "react-i18next"

// Redux Store
import {
  showRightSidebarAction,
  toggleLeftmenu,
  changeSidebarType,
  showSidebarSm,
} from "../../store/actions"
import { logo, logo_sm } from "assets/images/logo"
import { HiOutlineLogout } from "react-icons/hi"
import useWindow from "Hooks/UseWindow"
import { useHistory } from "react-router-dom"

const Header = props => {
  const dispatch = useDispatch()
  const history = useHistory()

  const { isMobile } = useWindow()

  function toggleFullscreen() {
    if (
      !document.fullscreenElement &&
      /* alternative standard method */ !document.mozFullScreenElement &&
      !document.webkitFullscreenElement
    ) {
      // current working methods
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen()
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen()
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen(
          Element.ALLOW_KEYBOARD_INPUT
        )
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen()
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen()
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen()
      }
    }
  }

  const [sidebarSm, setSidebarSm] = useState(false)
  function tToggle() {
    var body = document.body
    if (window.screen.width <= 998) {
      body.classList.toggle("sidebar-enable")
    } else {
      body.classList.toggle("vertical-collpsed")
      body.classList.toggle("sidebar-enable")
    }
    setSidebarSm(!sidebarSm)
  }

  useEffect(() => {
    dispatch(showSidebarSm(sidebarSm))
  }, [dispatch, sidebarSm])

  const { sideBarSm } = useSelector(state => ({
    sideBarSm: state.Layout.sideBarSm,
  }))

  const handleLogout = () => {
    localStorage.clear()
    sessionStorage.clear()
    history.push("/login")
  }

  return (
    <React.Fragment>
      {isMobile ? (
        <>
          <div
            className="d-flex align-items-center justify-content-between p-3 px-4"
            style={{
              backgroundColor: "#034e60",
              boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
            }}
          >
            <div>
              <img
                src={logo}
                alt="header-logo"
                style={{
                  objectFit: "contain",
                  height: 30,
                  width: 80,
                }}
              />
              {/* <button
              type="button"
              onClick={() => {
                tToggle()
              }}
              className="btn btn-sm px-3 font-size-16 box-shadow-none"
              id="vertical-menu-btn"
            >
              <i className="fa fa-fw fa-bars" />
            </button> */}
            </div>
            <div>
              <Link to={"#"} onClick={handleLogout}>
                <HiOutlineLogout
                  style={{ cursor: "pointer", color: "#000" }}
                  size={"1.3rem"}
                  className="text-"
                />
              </Link>
            </div>
          </div>
        </>
      ) : (
        <header id="page-topbar">
          <div className="navbar-header">
            <div className="d-flex">
              <div className="navbar-brand-box d-md-block">
                <Link to="/" className="logo logo-dark">
                  <span
                    className="logo-sm"
                    style={{
                      width: sideBarSm ? "35px" : "",
                    }}
                  >
                    {sideBarSm ? (
                      <img
                        src={logo_sm}
                        alt="header-logo"
                        style={{
                          objectFit: "contain",
                          height: 20,
                          width: 70,
                        }}
                      />
                    ) : (
                      <img
                        src={logo}
                        alt="header-logo"
                        style={{
                          objectFit: "cover",
                          height: "20px",
                          width: "100%",
                        }}
                      />
                    )}
                  </span>
                </Link>
              </div>

              {isMobile && (
                <button
                  type="button"
                  onClick={() => {
                    tToggle()
                  }}
                  className="btn btn-sm px-3 font-size-16 header-item "
                  id="vertical-menu-btn"
                >
                  <i className="fa fa-fw fa-bars" />
                </button>
              )}
            </div>
            <div className="d-flex">
              <ProfileMenu />
              <div className="dropdown d-none d-lg-inline-block ms-1">
                <button
                  title="Full Screen"
                  type="button"
                  onClick={() => {
                    toggleFullscreen()
                  }}
                  className="btn header-item noti-icon "
                  data-toggle="fullscreen"
                >
                  <i className="bx bx-fullscreen" />
                </button>
              </div>
            </div>
          </div>
        </header>
      )}
    </React.Fragment>
  )
}

Header.propTypes = {
  changeSidebarType: PropTypes.func,
  leftMenu: PropTypes.any,
  leftSideBarType: PropTypes.any,
  showRightSidebar: PropTypes.any,
  showRightSidebarAction: PropTypes.func,
  t: PropTypes.any,
  toggleLeftmenu: PropTypes.func,
}

const mapStatetoProps = state => {
  const { layoutType, showRightSidebar, leftMenu, leftSideBarType } =
    state.Layout
  return { layoutType, showRightSidebar, leftMenu, leftSideBarType }
}

export default connect(mapStatetoProps, {
  showRightSidebarAction,
  toggleLeftmenu,
  changeSidebarType,
})(withTranslation()(Header))
