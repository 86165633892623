import { call, put, takeEvery } from "redux-saga/effects"

import {
  CREATE_JOB_APPLICATION,
  CREATE_STUDENT_NOTES,
  DELETE_JOB_APPLICATION,
  DELETE_STUDENT,
  DELETE_STUDENT_NOTE,
  GET_ADMIN_DASHBOARD_DETAILS,
  GET_JOB_APPLICATIONS,
  GET_SINGLE_APPLICATIONS_DETAILS,
  GET_STUDENTS,
  GET_STUDENTS_LIST,
  GET_STUDENT_NOTE,
  IMAGES_UPLOAD,
  SELF_DELETE_STUDENT,
  STUDENT_DETAILS,
  UPDATE_JOB_APPLICATION,
  UPDATE_STUDENT,
} from "./actionTypes"
import {
  createJobApplicationFail,
  createJobApplicationSucess,
  createStudentNoteFail,
  createStudentNotesSuccess,
  deleteJobApplicationFails,
  deleteJobApplicationSuccess,
  deleteStudentNoteFail,
  deleteStudentNoteSuccess,
  deleteStudentFail,
  deleteStudentSuccess,
  getJobApplicationsFail,
  getJobApplicationsSuccess,
  getApplicationDetailsFail,
  getApplicationDetailsSuccess,
  getStudentNoteFail,
  getStudentNoteSuccess,
  getStudentsFail,
  getStudentsSuccess,
  getStudentDetailsFail,
  getStudentDetailsSuccess,
  updateJobApplication,
  updateJobApplicationFail,
  updateStudentFail,
  updateStudentSuccess,
  getAdminDashboardDetailsSuccess,
  getAdminDashboardDetailsFail,
  getStudentsList,
  getStudentsListSuccess,
  getStudentsListFail,
  updateJobApplicationSuccess,
  imageUpload_success,
  imageUploadFail,
  selfDeleteStudentSuccess,
  selfDeleteStudentFail,
} from "./actions"
import {
  get,
  post,
  ApiPut,
  del,
  patch,
  BASE_URL,
  LOCAL_URL,
} from "helpers/api_methods"
import { doneNotification, errorNotification } from "constants/notification"
import axios from "axios"
import { useErrorNotification } from "components/Common/Notification"

// Get Students //

const student = "/student/student/"
const Role = localStorage.getItem("role")

const fetchStudentAPi = ({ tabChange, searchText, page }) => {
  console.log("tabChange")
  console.log(tabChange)
  if (tabChange === "true") {
    return get(
      `${student}?fees_paid=${tabChange}&search=${
        searchText && searchText
      }&page=${page ? page : 1}`
    )
  } else if (tabChange === "false") {
    return get(
      `${student}?fees_paid=${tabChange}&search=${
        searchText && searchText
      }&page=${page ? page : 1}
      `
    )
  } else {
    return get(
      `${student}?search=${searchText && searchText}&page=${page ? page : 1}`
    )
  }
}

function* fetchStudent({ payload }) {
  try {
    const response = yield call(fetchStudentAPi, payload)
    yield put(getStudentsSuccess(response))
  } catch (error) {
    yield put(getStudentsFail(error))
  }
}

// Get StudentsList //

const student_list = "/student/students-list"

const fetchStudentListAPi = () => {
  return get(`${student_list}`)
}

function* fetchStudentsList() {
  try {
    const response = yield call(fetchStudentListAPi)
    console.log(response)
    yield put(getStudentsListSuccess(response))
  } catch (error) {
    console.log(error)
    yield put(getStudentsListFail(error))
  }
}

// Students Single view //

const fetchSingleviewApi = studentId => {
  if (studentId) {
    return get(`${student}${studentId}/`)
  } else return get(`${student}`)
}
function* fetchStudentSingleview({ studentId }) {
  try {
    const response = yield call(fetchSingleviewApi, studentId)
    yield put(getStudentDetailsSuccess(response))
  } catch (error) {
    yield put(getStudentDetailsFail(error))
  }
}

// Update Students //
const UpdateStudentApi = async ({ updatedStudent, studentId }) => {
  return ApiPut(`${student}${studentId}/`, updatedStudent)
}
//   const form_data = new FormData()
//   {
//     {
//       cv && form_data.append("cv", cv[0], cv[0]?.name)
//     }
//     {
//       profilepic &&
//         form_data.append("profilepic", profilepic[0], profilepic[0]?.name)
//     }
//     {
//       cover_letter &&
//         form_data.append("cover_letter", cover_letter[0], cover_letter[0]?.name)
//     }

//     try {
//       let response = await axios({
//         method: "patch",
//         url: `${BASE_URL}${student}${studentId}/`,
//         headers: {
//           "content-type": "multipart/form-data",
//           Authorization: "token " + localStorage.getItem("token"),
//         },
//         data: form_data,
//       })
//       return response.data
//     } catch (err) {
//       return err?.response?.data
//     }
//   }
// }

function* fetchStudentUpdate({ payload }) {
  try {
    const response = yield call(UpdateStudentApi, payload)
    console.log(payload)
    if (response) {
      yield put(updateStudentSuccess(response))
      doneNotification("Student Update Success")
      payload?.history?.push("/dashboard")
      if (payload?.setIsOpenFile) {
        payload?.setIsOpenFile(true)
      }
    }
  } catch (error) {
    console.log(error)
    errorNotification()
    yield put(updateStudentFail(error))
  }
}

// image upload

const file = "student/student-file/"

const imageUpload = async ({ finalData, studentId }) => {
  console.log("finalData", finalData)
  const form_data = new FormData()
  {
    {
      finalData?.cv &&
        form_data.append("cv", finalData.cv[0], finalData.cv[0]?.name)
    }

    {
      finalData?.cover_letter &&
        form_data.append(
          "cover_letter",
          finalData.cover_letter[0],
          finalData.cover_letter[0]?.name
        )
    }
    {
      finalData?.cover_letter_two &&
        form_data.append(
          "cover_letter_two",
          finalData.cover_letter_two[0],
          finalData.cover_letter_two[0]?.name
        )
    }
    {
      finalData?.company_cv &&
        form_data.append(
          "company_cv",
          finalData.company_cv[0],
          finalData.company_cv[0]?.name
        )
    }
    {
      finalData?.company_cv_two &&
        form_data.append(
          "company_cv_two",
          finalData.company_cv_two[0],
          finalData.company_cv_two[0]?.name
        )
    }
    {
      finalData?.profilepic &&
        form_data.append(
          "profilepic",
          finalData.profilepic[0],
          finalData.profilepic[0]?.name
        )
    }

    try {
      let response = await axios({
        method: "patch",
        url: `${BASE_URL}${file}${studentId}/`,
        headers: {
          "content-type": "multipart/form-data",
          Authorization: "token " + localStorage.getItem("token"),
        },
        data: form_data,
      })
      return response.data
    } catch (err) {
      return err?.response?.data
    }
  }
}

function* fetchImgUpload({ payload }) {
  try {
    const response = yield call(imageUpload, payload)
    if (response) {
      yield put(imageUpload_success(response))
      doneNotification("Images Added")

      if (payload?.history) {
        if (Role === "student") {
          payload?.history?.push("/dashboard")
        } else if (Role === "admin") {
          payload?.history?.push("/students")
        }
      }
    }
  } catch (error) {
    console.log(error)
    errorNotification()
    yield put(imageUploadFail(error))
  }
}

// Delete Students //

const deleteStudentsApi = ({ student_id }) => {
  console.log(student_id)
  return del(`/account/delete-account/`)
}
function* selfDeleteStudents({ payload }) {
  try {
    const response = yield call(deleteStudentsApi, payload)
    yield put(selfDeleteStudentSuccess(payload.student_id))
    doneNotification(" Student Delete Success")
    payload.history.push("/login")
  } catch (error) {
    console.log(error)
    yield put(selfDeleteStudentFail(error))
    errorNotification()
  }
}

const selfDeleteStudentsApi = ({ student_id }) => {
  console.log(student_id)
  return del(`${student}${student_id}/`)
}
function* deleteStudents({ payload }) {
  try {
    const response = yield call(selfDeleteStudentsApi, payload)
    yield put(deleteStudentSuccess(payload.student_id))
    doneNotification(" Student Delete Success")
    payload.history.push("/students")
  } catch (error) {
    yield put(deleteStudentFail(error))
    errorNotification()
  }
}

// student Note //

//Get //

const StudentNote = "/student/student-note/"

const stuentNote = studentId => {
  if (studentId) {
    return get(`${StudentNote}?student=${studentId && studentId}`)
  } else {
    return get(`${StudentNote}`)
  }
}

function* getStudentNotes({ payload }) {
  try {
    const response = yield call(stuentNote, payload)
    yield put(getStudentNoteSuccess(response))
  } catch (error) {
    yield put(getStudentNoteFail(response?.error))
  }
}

// Create //

const createStudentNoteApi = ({ createDetails, studentId }) => {
  return post(`${StudentNote}?student=${studentId}`, createDetails)
}
function* createNotes({ payload }) {
  try {
    const response = yield call(createStudentNoteApi, payload)
    yield put(createStudentNotesSuccess(response))
    payload.setNotes("")
  } catch (error) {
    yield put(createStudentNoteFail(response?.error))
  }
}

// delete //

const deleteStudentNotes = ({ noteId }) => {
  return del(`${StudentNote}${noteId}/`)
}
function* deleteNote({ payload }) {
  try {
    const response = yield call(deleteStudentNotes, payload)
    doneNotification("NOTE DELETED SUCCESSFULLY")
    yield put(deleteStudentNoteSuccess(payload?.noteId))
  } catch (error) {
    yield put(deleteStudentNoteFail(response?.error))
    errorNotification("Somthing Wrong")
  }
}

//  Job Applicatons //

// Get //

const JobApllication = "student/job-application/"

const GetJobApplication = ({ searchText, page }) => {
  if (searchText) {
    return get(
      `${JobApllication}?search=${searchText}
      `
    )
  } else {
    return get(`${JobApllication}?page=${page ? page : 1}`)
  }
}
function* getApplication({ payload }) {
  try {
    const response = yield call(GetJobApplication, payload)
    yield put(getJobApplicationsSuccess(response))
  } catch (error) {
    yield put(getJobApplicationsFail(error))
  }
}
// single view //

const SingleApplication = applicationId => {
  return get(`${JobApllication}${applicationId}/`)
}

function* singleApplication({ applicationId }) {
  try {
    const response = yield call(SingleApplication, applicationId)
    yield put(getApplicationDetailsSuccess(response))
  } catch (error) {
    yield put(getApplicationDetailsFail(error))
  }
}

// Create //

// const createApplication = input => {
//   return post(`${JobApllication}`, input)
// }'

const createApplication = async input => {
  console.log("datas => ", input)

  const formData = new FormData()
  {
    formData?.append("description", input?.description),
      formData?.append("title", input?.title)
    input?.image &&
      formData?.append("image", input?.image?.image, input?.image?.image?.name)
  }

  try {
    let response = await axios({
      method: "post",
      url: `${BASE_URL}${JobApllication}`,
      headers: {
        "content-type": "multipart/form-data",
        Authorization: "token " + localStorage.getItem("token"),
      },
      data: formData,

      // finalValue,
    })
    return response.data
  } catch (err) {
    console.log(err)
    return err?.response?.data
  }
}
function* createJobApplication({ payload: { input, history } }) {
  try {
    const response = yield call(createApplication, input)
    yield put(createJobApplicationSucess(response))
    if (response?.id) {
      history.push("/job-application")
      doneNotification()
    } else {
      errorNotification(response?.title)
    }
    console.log(response)
  } catch (error) {
    console.log(error)
    yield put(createJobApplicationFail(error))
  }
}

// update //

const updateApplications = async ({ upatedData, applicationId }) => {
  // return ApiPut(`${JobApllication}${applicationId}/`, upatedData)

  console.log("datas => ", upatedData)

  const formData = new FormData()
  {
    formData?.append(
      "image",
      upatedData?.image?.image,
      upatedData?.image?.image?.name,
      formData?.append("description", upatedData?.description),
      formData?.append("title", upatedData?.title)
    )
  }

  try {
    let response = await axios({
      method: "patch",
      url: `https://api-v2.careerpro.uk/api/v1/${JobApllication}${applicationId}/`,
      headers: {
        "content-type": "multipart/form-data",
        Authorization: "token " + localStorage.getItem("token"),
      },
      data: formData,

      // finalValue,
    })
    return response.data
  } catch (err) {
    console.log(err)
    return err?.response?.data
  }
}

function* fetchUpdateApplication({ payload }) {
  try {
    const response = yield call(updateApplications, payload)
    if (response?.id) {
      yield put(updateJobApplicationSuccess(response))
      doneNotification()
      payload.history.push("/job-application")
    } else {
      yield put(updateJobApplicationFail(response))
    }
  } catch (error) {
    console.log(error)
    errorNotification()
    yield put(updateJobApplicationFail(error))
  }
}
// delete//

const deleteApplications = ({ applicationId }) => {
  console.log(applicationId)
  return del(`${JobApllication}${applicationId}/`)
}

function* fetchDeleteApplications({ payload }) {
  try {
    const response = yield call(deleteApplications, payload)
    doneNotification()
    yield put(deleteJobApplicationSuccess(payload.applicationId))
  } catch (error) {
    yield put(deleteJobApplicationFails(error))
    errorNotification()
  }
}

// Admin Dashboard Details //

const adminDashboardDetailsApi = () => {
  return get(`/student/dashboard`)
}

function* fetchAdminDashboardDetails({ payload }) {
  try {
    const response = yield call(adminDashboardDetailsApi, payload)
    yield put(getAdminDashboardDetailsSuccess(response))
  } catch (error) {
    yield put(getAdminDashboardDetailsFail(error))
  }
}

function* ShopsSaga() {
  //students//
  yield takeEvery(GET_STUDENTS, fetchStudent)
  yield takeEvery(STUDENT_DETAILS, fetchStudentSingleview)
  yield takeEvery(UPDATE_STUDENT, fetchStudentUpdate)
  yield takeEvery(DELETE_STUDENT, deleteStudents)
  yield takeEvery(SELF_DELETE_STUDENT, selfDeleteStudents)

  // job applicaton //
  yield takeEvery(GET_JOB_APPLICATIONS, getApplication)
  yield takeEvery(CREATE_JOB_APPLICATION, createJobApplication)
  yield takeEvery(GET_SINGLE_APPLICATIONS_DETAILS, singleApplication)
  yield takeEvery(UPDATE_JOB_APPLICATION, fetchUpdateApplication)
  yield takeEvery(DELETE_JOB_APPLICATION, fetchDeleteApplications)
  yield takeEvery(IMAGES_UPLOAD, fetchImgUpload)

  // Student Notes //

  yield takeEvery(GET_STUDENT_NOTE, getStudentNotes)
  yield takeEvery(GET_STUDENTS_LIST, fetchStudentsList)
  yield takeEvery(CREATE_STUDENT_NOTES, createNotes)
  yield takeEvery(DELETE_STUDENT_NOTE, deleteNote)

  // admin Dashboard

  yield takeEvery(GET_ADMIN_DASHBOARD_DETAILS, fetchAdminDashboardDetails)
}

export default ShopsSaga
