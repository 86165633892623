import React, { useEffect, useRef, useState } from "react"
import { Row, Col, Card, Button, UncontrolledTooltip } from "reactstrap"

import { useSelector, useDispatch } from "react-redux"
import loading from "assets/images/defualt/loading.gif"
import chat_bg from "./../../../../assets/images/chat-bg.png"

import google_tune from "./../../../../assets/audio/google_chat.mp3"
import facebook_chat from "./../../../../assets/audio/facebookchat.mp3"
import useWebSocket, { ReadyState } from "react-use-websocket"
import { axiosApi, WEBSOCKET_URL } from "helpers/api_methods"

//Import Scrollbar
import PerfectScrollbar from "react-perfect-scrollbar"
import { getChats, getMessages } from "store/actions"
import { map } from "lodash"
import moment from "moment"
import { FaUserShield } from "react-icons/fa"
import { MdKeyboardArrowLeft } from "react-icons/md"
import { useHistory } from "react-router-dom"
import useWindow from "Hooks/UseWindow"
// import "react-perfect-scrollbar/dist/css/styles.css"

const ChatBox = props => {
  const dispatch = useDispatch()
  const history = useHistory()
  const messagesEndRef = useRef(null)
  const { isMobile } = useWindow()

  const { chats } = useSelector(state => ({
    chats: state.ChatDetails.chats,
  }))

  const [messageBox, setMessageBox] = useState(null)
  // const Chat_Box_Username2 = "Henry Wells"
  const [currentRoomId, setCurrentRoomId] = useState(1)
  const [currentChat, setCurrentChat] = useState(false)
  // eslint-disable-next-line no-unused-vars
  const [currentUser, setCurrentUser] = useState({
    image: "user",
    name: localStorage.getItem("username"),
    online: "true",
    isActive: true,
  })

  const token = localStorage.getItem("token")
  const role = localStorage.getItem("role")

  const recieve_audio = new Audio(google_tune)
  const send_audio = new Audio(facebook_chat)

  const { readyState } = useWebSocket(`${WEBSOCKET_URL}/?token=` + token, {
    onOpen: () => {
      console.log("Connected!")
    },
    onClose: () => {
      console.log("Disconnected!")
      // if (alert("Chat Disconnected!.. please Reload the page")) {
      // } else window.location.reload()
    },
    // New onMessage handler
    onMessage: e => {
      let msg = JSON.parse(e.data)
      if (msg["sender"] != currentUser["name"]) {
        var playPromise = recieve_audio.play()
        if (playPromise !== undefined) {
          playPromise
            .then(_ => {
              console.log("recieved tune")
              scrollToBottom()
            })
            .catch(error => {
              console.log("tune : ", error)
            })
        }
      } else {
        var playPromise = send_audio.play()
        if (playPromise !== undefined) {
          playPromise
            .then(_ => {
              console.log("send tune")
              scrollToBottom()
            })
            .catch(error => {
              console.log("tune : ", error)
            })
        }
      }

      if (currentChat.messages) {
        chats.filter(item => item.id === msg["chat"])[0].messages.push(msg)
      } else {
        chats.filter(item => item.id === msg["chat"])[0].messages([msg])
      }
      setTimeout(1000)
      scrollToBottom()
    },
  })
  const { sendJsonMessage } = useWebSocket(`${WEBSOCKET_URL}/?token=` + token)

  const connectionStatus = {
    [ReadyState.CONNECTING]: "Connecting",
    [ReadyState.OPEN]: "Open",
    [ReadyState.CLOSING]: "Closing",
    [ReadyState.CLOSED]: "Closed",
    [ReadyState.UNINSTANTIATED]: "Uninstantiated",
  }[readyState]

  const [Chat_Box_Username, setChat_Box_Username] = useState("")
  const [Chat_Box_User_Status, setChat_Box_User_Status] = useState("online")
  const [curMessage, setcurMessage] = useState("")

  useEffect(() => {
    dispatch(getChats())
    // dispatch(getMessages())
    // setCurrentRoomId(chats[0].id)
  }, [dispatch])
  useEffect(() => {
    setTimeout(1000)
    scrollToBottom()
  }, [currentChat.messages, currentChat, chats, dispatch])

  useEffect(() => {
    if (chats[0]) {
      userChatOpen(chats[0])
    }
  }, [chats])

  //Use For Chat Box
  const userChatOpen = chat => {
    setChat_Box_Username(chat["name"])
    setCurrentRoomId(chat["id"])
    setCurrentChat(chat)
    dispatch(getMessages(chat["id"]))
    // setMessages(
    //   chat.messages
    // )
    scrollToBottom()
  }

  const addMessage = (chat, sender) => {
    if (curMessage) {
      const message = {
        chat,
        sender,
        sender_name: sender,
        content: curMessage,
        createdAt: new Date(),
        msg_type: "text",

        chat_members: currentChat.member,
      }
      setcurMessage("")
      sendJsonMessage(message)
    } else {
      setcurMessage("")
    }
  }

  const scrollToBottom = () => {
    if (messageBox) {
      messageBox.scrollTop = messageBox.scrollHeight + 1200
    }
  }

  const onKeyPress = e => {
    const { key, value } = e
    if (key === "Enter") {
      setcurMessage(value)
      addMessage(currentRoomId, currentUser.name)
    }
  }

  const handleImageUpload = async e => {
    const formData = new FormData()
    formData.append("msg_file", e.target.files[0])
    try {
      const response = await axiosApi({
        method: "post",
        url: "/chat/message-file/",
        data: formData,
        headers: { "Content-Type": "multipart/form-data" },
      })

      let msg_type = ""
      if (e.target.files[0]["type"].split("/")[0] == "image") {
        msg_type = "image"
      } else {
        msg_type = "file"
      }
      sendJsonMessage({
        chat: currentChat["id"],
        sender: currentUser.name,
        sender_name: currentUser.name,
        content: response["data"]["msg_file"],
        msg_type: msg_type,
        createdAt: new Date(),
        chat_members: currentChat.member,
      })
    } catch (error) {
      console.log(error)
    }
  }

  const scrollToBottom2 = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" })
  }

  useEffect(() => {
    scrollToBottom2()
  }, []);


  return (
    <React.Fragment>
      <div
        className="user-chat mobile-chat"
        style={{
          marginTop: role === "admin" ? "" : "6rem",
        }}
      >
        <Card className="bg-white px-2 sm:p-0">
          <div className="p-4 border-bottom chat-header">
            <Row>
              <Col md="4" xs="9">
                <h5 className="font-size-15 mb-1 d-flex align-items-center">
                  <MdKeyboardArrowLeft onClick={() => history.push('/dashboard')} className="me-2" size={"1.3rem"} />
                  <FaUserShield className="me-2 text-green" size={"1.2rem"} />
                  Admin
                </h5>
                {/* <p className="text-muted mb-0">
                  <i
                    className={
                      Chat_Box_User_Status === "online"
                        ? "mdi mdi-circle text-success align-middle me-1"
                        : Chat_Box_User_Status === "intermediate"
                        ? "mdi mdi-circle text-warning align-middle me-1"
                        : "mdi mdi-circle align-middle me-1"
                    }
                  />
                  {Chat_Box_User_Status}
                </p> */}
              </Col>
            </Row>
          </div>

          <div className="w-100">
            <div
              className="chat-conversation p-md-3"
            >
              <ul className="list-unstyled h-100">
                <PerfectScrollbar
                  style={{ maxHeight: isMobile ? "85vh" : '55vh' }}
                  containerRef={ref => setMessageBox(ref)}
                >
                  <li></li>
                  {currentChat?.messages ? (
                    map(currentChat.messages, message => (
                      <li
                        key={message.id}
                        className={
                          message.sender_name === currentUser.name
                            ? "right"
                            : ""
                        }
                      >
                        <div className="conversation-list">
                          <div className="ctext-wrap">
                            <div className="conversation-name">
                              {message.sender_name}
                            </div>
                            {message.msg_type === "file" ? (
                              <p style={{ margin: "0" }}>
                                <a
                                  href={message.content}
                                  rel="noreferrer"
                                  target="_blank"
                                >
                                  <i
                                    style={{ fontSize: "80px" }}
                                    className="mdi mdi-file-document-outline"
                                  />
                                  <br />
                                  {message.content.split("/").at(-1)}
                                </a>
                              </p>
                            ) : message.msg_type === "image" ? (
                              <p style={{ margin: "0" }}>
                                <a
                                  href={message.content}
                                  rel="noreferrer"
                                  target="_blank"
                                >
                                  <img src={message.content} />
                                  <br />
                                  {message.content.split("/").at(-1)}
                                </a>
                              </p>
                            ) : (
                              <p>{message.content}</p>
                            )}
                            <p className="chat-time mb-0">
                              <i className="bx bx bx-check align-middle me-1" />
                              {moment(message.createdAt).format(
                                "DD-MM-YY hh:mm"
                              )}
                            </p>
                          </div>
                        </div>
                      </li>
                    ))
                  ) : (
                    <>
                      <div className="d-flex align-items-center flex-column w-50 m-auto justify-content-center">
                        {/* <img src={chat_bg} alt="background" width={"250px"} /> */}
                        <h6 className="mt-3">Chat Web</h6>
                        {/* <p className="text-center">
                          Keep in touch with admin by sending and receiving
                          messages
                        </p> */}
                        <p className="d-flex align-items-end justify-content-center"><span style={{ paddingBottom: 6 }}>connecting</span>  <img src={loading} alt="background" className="mx-2" width={"30px"} height={'20px'} /></p>
                      </div>
                    </>
                  )}
                </PerfectScrollbar>
              </ul>
            </div>

            {currentChat ? (
              <div className="p-3 chat-input-section">
                <Row>
                  <Col>
                    <div className="position-relative">
                      <input
                        type="text"
                        value={curMessage}
                        onKeyPress={onKeyPress}
                        onChange={e => setcurMessage(e.target.value)}
                        className="form-control chat-input"
                        placeholder="Enter Message..."
                      />
                      <div className="chat-input-links">
                        <ul className="list-inline mb-0">
                          <li className="list-inline-item">
                            <input
                              className="d-none"
                              type={"file"}
                              id="image-upload"
                              onChange={(e, input) => {
                                handleImageUpload(e, input)
                              }}
                            ></input>

                            <label
                              htmlFor="image-upload"
                              style={{ fontSize: "20px", margin: 0 }}
                            >
                              <i
                                className="mdi mdi-file-document-outline"
                                id="Filetooltip"
                              />
                              <UncontrolledTooltip
                                placement="top"
                                target="Filetooltip"
                              >
                                Send Document
                              </UncontrolledTooltip>
                            </label>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </Col>
                  <Col className="col-auto">
                    <Button
                      type="button"
                      color="primary"
                      onClick={() =>
                        addMessage(currentRoomId, currentUser.name)
                      }
                      className="btn btn-primary btn-rounded chat-send w-md "
                      style={{
                        backgroundColor: isMobile && "#034e60",
                        border: isMobile && "none"
                      }}
                      ref={messagesEndRef}
                    >
                      <span className="d-none d-sm-inline-block me-2">
                        Send
                      </span>{" "}
                      <i className="mdi mdi-send" />
                    </Button>
                  </Col>
                </Row>
              </div>
            ) : (
              ""
            )}
          </div>
        </Card>
      </div>
    </React.Fragment>
  )
}

export default ChatBox
