import { get } from "../../helpers/api_methods"
import { call, put, takeEvery } from "redux-saga/effects"
import {
  getProfileDetails,
  getProfileDetailsFail,
  getProfileDetailsSuccess,
  getVacancyDetailsFail,
  getVacancyDetailsSuccess,
  searchJobVacanciesFail,
  searchJobVacanciesSuccess,
} from "./action"
import {
  GET_PROFILE_DETAILS,
  GET_VACENCY_DETAILS,
  SEARCH_JOB_VACCENCIES,
} from "./actionType"

const profile = "/student/my-profile"
const getProfileApi = () => {
  return get(`${profile}`)
}

function* fetchProfile({ payload }) {
  try {
    const res = yield call(getProfileApi, payload)
    yield put(getProfileDetailsSuccess(res))
  } catch (error) {
    yield put(getProfileDetailsFail(error?.error))
  }
}

// Student Job Vaccency Search //

const vaccency = "demo/vaccency"

const getVacency = () => {
  return get(`${vaccency}`)
}

function* fetchVacency({ payload }) {
  try {
    const res = yield call(getVacency, payload)
    yield put(searchJobVacanciesSuccess(res))
  } catch (error) {
    yield put(searchJobVacanciesFail(res?.error))
  }
}

// vacency Details //

const getVacancyDetails = () => {
  return get(`${vaccency}`)
}

function* fetchVacencyDetails({ payload }) {
  try {
    const res = yield call(getVacancyDetails, payload)
    yield put(getVacancyDetailsSuccess(res))
  } catch (error) {
    yield put(getVacancyDetailsFail(res?.error))
  }
}

function* studentDashboard() {
  yield takeEvery(GET_PROFILE_DETAILS, fetchProfile)
  yield takeEvery(SEARCH_JOB_VACCENCIES, fetchVacency)
  yield takeEvery(GET_VACENCY_DETAILS, fetchVacencyDetails)
}

export default studentDashboard
