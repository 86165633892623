import {
  useErrorNotification,
  useNotification,
} from "components/Common/Notification"
import { doneNotification, errorNotification } from "constants/notification"
import { post } from "helpers/api_methods"
import { call, put, takeEvery } from "redux-saga/effects"
import { error } from "toastr"
import {
  userLoginFail,
  userLoginSuccess,
  userRegisterFail,
  userRegisterSuccess,
  verificationSuccess,
  verificationFail,
  userForgetPasswordSuccess,
  userForgetPasswordError,
  userForgetNewPasswordSuccess,
  userForgetNewPasswordError,
  resetPasswordFail,
  resetPasswordSuccess,
  resendOtpFail,
  resendOtpSuccess,
} from "./actions"
import {
  FORGET_NEW_PASSWORD,
  FORGET_PASSWORD,
  LOGIN_USER_REQUEST,
  RESEND_OTP,
  RESET_PASSWORD,
  USER_REGISTER_REQUEST,
  VERIFICATION_REQUEST,
} from "./actionTypes"

const loginApi = input => {
  return post("account/login", input)
}

function* userLogin({ payload: { input, history } }) {
  try {
    const res = yield call(loginApi, input)
    if (res?.token) {
      localStorage.setItem("token", res?.token)
      localStorage.setItem("username", res?.email)
      localStorage.setItem("role", res?.role)
      yield put(userLoginSuccess(res))
      useNotification({
        type: "success",
        message: "Logged In Successfully!",
        title: "",
      })
      history.push("/dashboard")
    } else {
      yield put(userLoginFail(res))
    }
  } catch (error) {
    useErrorNotification({
      type: "error",
      message: "Invalid Credentials",
    })
    yield put(userLoginFail(error?.data))
  }
}

const registerApi = studentRegister => {
  console.log(studentRegister)
  return post("account/register", studentRegister)
}
function* userRegister({ payload: { studentRegister, history } }) {
  try {
    const res = yield call(registerApi, studentRegister)
    if (res?.session_key) {
      localStorage.setItem("session_key", res?.session_key)
      yield put(userRegisterSuccess(res))
      doneNotification("Email verification !")
      history.push("/verify")
    } else {
      yield put(userRegisterFail(error))
    }
  } catch (error) {
    console.log(error)
    yield put(userRegisterFail(error))
    errorNotification("Invalid Credentials")
  }
}

const verificationApi = data => {
  console.log(data)
  return post("account/email-verification/", data)
}
function* verification({ payload: { data, history } }) {
  try {
    const res = yield call(verificationApi, data)
    if (res?.token) {
      localStorage.setItem("token", res?.token)
      localStorage.setItem("role", res?.role)
      localStorage.setItem("username", res?.email)
      yield put(verificationSuccess(res))
      doneNotification("Verified successfully !")
      history.push("/dashboard")
    } else {
      yield put(verificationFail(error))
    }
  } catch (error) {
    console.log(error)
    yield put(verificationFail(error))
    errorNotification("Invalid Credentials")
  }
}

function forgetUserPassApi(email) {
  console.log("title =>")
  console.log("data :", email)
  return post("account/forgot_password/", email)
}

function forgetUserNewPassApi({ pass }) {
  return post("account/forget-password/", pass)
}

function* forgetUserPass({ payload: { email, history } }) {
  try {
    const response = yield call(forgetUserPassApi, email)
    if (response) {
      yield put(userForgetPasswordSuccess(response))
      useNotification({
        type: "success",
        message: "Your new password has been sent to your email !",
        title: "",
      })
      history.goBack()
    }
  } catch (error) {
    console.log("data :", error)
    yield put(userForgetPasswordError(error))
  }
}

function* forgetUserNewPass({ payload }) {
  try {
    const response = yield call(forgetUserNewPassApi, payload)
    if (response) {
      yield put(userForgetNewPasswordSuccess(response))
      payload?.history.push("/login")
      doneNotification(response?.response)
    }
  } catch (error) {
    yield put(userForgetNewPasswordError(error))
  }
}

function resetPasswordApi({ value, resetId }) {
  return post(`/account/new_password/${resetId}`, value)
}

function* onResetPassword({ payload }) {
  try {
    const response = yield call(resetPasswordApi, payload)
    if (response) {
      yield put(resetPasswordSuccess(response))
      useNotification({
        type: "success",
        message: "Password Changed Successfully!",
        title: "",
      })

      if (payload?.history) {
        payload?.history.push("/login")
      }
    }
  } catch (error) {
    yield put(resetPasswordFail(error?.response?.data))
    errorNotification()
  }
}


function resendOtpApi({ value }) {
  return post(`/account/resend_otp/`, value)
}

function* onResendOtp({ payload }) {
  try {
    const response = yield call(resendOtpApi, payload)
    if (response) {
      yield put(resendOtpSuccess(response))
      useNotification({
        type: "success",
        message: "OTP sent Successfully!",
        title: "",
      })

      // if (payload?.history) {
      //   payload?.history.push("/verify")
      // }
    }
  } catch (error) {
    yield put(resendOtpFail(error?.response?.data))
    errorNotification()
  }
}


function* loginSaaga() {
  yield takeEvery(LOGIN_USER_REQUEST, userLogin)
  yield takeEvery(USER_REGISTER_REQUEST, userRegister)
  yield takeEvery(VERIFICATION_REQUEST, verification)
  yield takeEvery(FORGET_PASSWORD, forgetUserPass)
  yield takeEvery(FORGET_NEW_PASSWORD, forgetUserNewPass)
  yield takeEvery(RESET_PASSWORD, onResetPassword)
  yield takeEvery(RESEND_OTP, onResendOtp)
}

export default loginSaaga
