//  student profile //

export const GET_PROFILE_DETAILS = "GET_PROFILE_DETAILS"
export const GET_PROFILE_DETAILS_SUCCESS = "GET_PROFILE_DETAILS_SUCCESS"
export const GET_PROFILE_DETAILS_FAIL = "GET_PROFILE_DETAILS_FAIL"

// Vaccencies  //

export const SEARCH_JOB_VACCENCIES = "SEARCH_JOB_VACCENCIES"
export const SEARCH_JOB_VACCENCIES_SUCCESS = "SEARCH_JOB_VACCENCIES_SUCCESS"
export const SEARCH_JOB_VACCENCIES_FAIL = "SEARCH_JOB_VACCENCIES_FAIL"

//   GET_Vacency Details //

export const GET_VACENCY_DETAILS = " GET_VACENCY_DETAILS"
export const GET_VACENCY_DETAILS_SUCCESS = " GET_VACENCY_DETAILS_SUCCESS"
export const GET_VACENCY_DETAILS_FAIL = " GET_VACENCY_DETAILS_FAIL"
