import React, { useEffect, useState } from "react"
import { Button, Col, Modal, Row } from "reactstrap"
import PropTypes from "prop-types"

import { AvField, AvForm } from "availity-reactstrap-validation"

function ModulModal({ modal_open, setModal_open, module, setModule }) {
  const [data, setData] = useState()

  function tog_center() {
    setModal_open(false)
    removeBodyCss()
  }
  function removeBodyCss() {
    document.body.classList.add("no_padding")
  }

  const Handle = () => {
    setModule([...module, data])
    setModal_open(false)
  }

  const hanldeControl = e => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    })
  }

  return (
    <div>
      <Modal
        isOpen={modal_open}
        toggle={() => {
          tog_center()
        }}
        centered={true}
      >
        <div className="p-1 px-2 modal-title">
          <div className="p-1 px-2">
            <Button
              type="button"
              onClick={() => {
                setModal_open(false)
              }}
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </Button>
          </div>
        </div>
        <div className="modal-body px-3 ">
          <Row>
            <Col md={12}>
              <AvForm
              // onValidSubmit={(e, v) => Handle(e, v)}
              >
                <Row className="my-2">
                  <Col md={12}>
                    <AvField
                      name="title"
                      onChange={e => hanldeControl(e)}
                      id="title"
                      label="Title"
                    />
                  </Col>{" "}
                  <Col md={12}>
                    <AvField
                      name="status"
                      onChange={e => hanldeControl(e)}
                      id="jsfbhj"
                      label="Status"
                    />
                  </Col>
                  <Col md={12}>
                    <AvField
                      name="description"
                      onChange={e => hanldeControl(e)}
                      label="Descrption"
                    />
                  </Col>
                </Row>

                <div className="d-flex justify-content-end my-4">
                  <div>
                    <Button
                      onClick={() => Handle()}
                      type="button"
                      className="px-5"
                      color="success"
                    >
                      Add
                    </Button>
                  </div>
                </div>
              </AvForm>
            </Col>
          </Row>
        </div>
      </Modal>
    </div>
  )
}

export default ModulModal
ModulModal.propTypes = {
  modal_open: PropTypes.bool,
  setModal_open: PropTypes.func,
  module: PropTypes.array,
  setModule: PropTypes.func,
}
