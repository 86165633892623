import { map } from "lodash"
import React from "react"
import {
  IoIosArrowDropleftCircle,
  IoIosArrowDroprightCircle,
} from "react-icons/io"
import { useSelector } from "react-redux"
import { Card, CardBody } from "reactstrap"
import SwiperCore, { Autoplay, Navigation } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"

SwiperCore.use([Navigation, Autoplay])

function MobileModulesSpecifications() {
  const { studentProfile } = useSelector(state => ({
    studentProfile: state?.StudentDashboard?.studentProfile,
  }))

  const modules = studentProfile?.specializations

  const handleStatusText = status => {
    switch (status) {
      case "pending":
        return "Pending"
      case "passed":
        return "Passed"
      case "not_assigned_yet":
        return "Not Assigned"
      case "ongoing":
        return "On Going"
      case "failed":
        return "Failed"
      case "submitted":
        return "Submitted"
      case "not_selected":
        return "Not Selected"
    }
  }

  return (
    <>
      <p className="text-black mt-3">
        {/* Modules & Specifications */}
        Qualifications
      </p>

      <div className="carausel-10-columns new_cat_slider">
        <Swiper
          autoplay={false}
          navigation={{
            prevEl: ".custom_prev_ct1",
            nextEl: ".custom_next_ct1",
          }}
          className="custom-class"
          slidesPerView={1.1}
        >
          {map(modules, (mod, key) => (
            <SwiperSlide key={key}>
              <Card
                className="blog-stats-wid glass-card border-none"
                style={{
                  marginRight: "1rem",
                }}
              >
                <CardBody>
                  <div>
                    <div className="mb-2 d-flex align-items-start justify-content-between mb-2 w-100">
                      <div className="d-flex flex-column align-items-start justify-content-satrt">
                        <p
                          style={{ opacity: 0.8 }}
                          className="sp-heading mb-0 "
                        >
                          Qualification Name
                        </p>
                        <p className="mb-0 sp-des text-start flex-1">
                          {mod?.title}
                        </p>
                      </div>
                      {/* <div className="text-end">
                        <h6 className="mb-2 text-darkGreen">Status</h6>
                        <div>
                          <p
                            className="mb-0 pb-0 text-darkGreen"
                            style={{ textTransform: "capitalize" }}
                          >
                            {handleStatusText(mod?.status)}
                          </p>
                        </div>
                      </div> */}
                    </div>

                    {studentProfile?.account?.email && (
                      <div className="mb-3 d-flex flex-column align-items-start justify-content-satrt mb-2 w-100">
                        <p
                          style={{ opacity: 0.8 }}
                          className="sp-heading mb-0  "
                        >
                          {/* Description */}
                          Specialization / Major
                        </p>
                        <p className="mb-0 sp-des text-start flex-1">
                          {mod?.description}{" "}
                        </p>
                      </div>
                    )}
                  </div>
                </CardBody>
              </Card>
            </SwiperSlide>
          ))}
        </Swiper>
        <div
          className="slider-arrow slider-arrow-2 mb-4 carausel-10-columns-arrow"
          style={{
            marginLeft: "auto",
            width: "fit-content",
          }}
        >
          <span className="slider-btn slider-prev slick-arrow custom_prev_ct1">
            <IoIosArrowDropleftCircle
              className="text-darkGreen"
              size={"25px"}
            />
          </span>
          <span className="slider-btn slider-next slick-arrow custom_next_ct1">
            <IoIosArrowDroprightCircle
              className="text-darkGreen"
              size={"25px"}
            />
          </span>
        </div>
      </div>
    </>
  )
}

export default MobileModulesSpecifications
