import { call, put, takeEvery } from "redux-saga/effects"

import {

  GET_CHATS,
  GET_MESSAGES,

} from "./actionTypes"

import { get, post, ApiPut, del, patch } from "helpers/api_methods"
import { doneNotification, errorNotification } from "constants/notification"
import { getChatsFail, getChatsSuccess } from "./actions"
import { getMessagesFail, getMessagesSuccess } from "./actions"

// Get Students //

const chat = "/chat/chat/"

const fetchChatAPi = ({ page, tabChange }) => {
  return get(`${chat}?page=${page ? page : 1}&type=${tabChange}`)
}
function* fetchChat({ payload }) {
  try {
    const response = yield call(fetchChatAPi, payload)
    // console.log(response);
    yield put(getChatsSuccess(response))
  } catch (error) {
    yield put(getChatsFail(error))
  }
}



const messages = "/chat/message/"

const fetchMessagesAPi = ({ chat_id,page, tabChange }) => {
  return get(`${messages}?chat=${chat_id}&page=${page ? page : 1}&type=${tabChange}`)
}

function* fetchMessages({ payload }) {
  try {
    const response = yield call(fetchMessagesAPi, payload)
    let res = {
      "chat_id": payload.chat_id,
      "messages": response
    }
    console.log(res);


    // console.log(res);
    yield put(getMessagesSuccess(res))
  } catch (error) {
    yield put(getMessagesFail(error))
  }
}


function* ChatsSaga() {
  yield takeEvery(GET_CHATS, fetchChat)
  yield takeEvery(GET_MESSAGES, fetchMessages)
}

export default ChatsSaga
