// import {
//   GET_CHATS,
//   GET_CHATS_SUCCESS,
//   GET_CHATS_FAIL,
//   GET_MESSAGES,
//   GET_MESSAGES_SUCCESS,
//   GET_MESSAGES_FAIL,
// } from "./actionTypes"

import {
  GET_CHATS,
  GET_CHATS_FAIL,
  GET_CHATS_SUCCESS,
  GET_MESSAGES,
  GET_MESSAGES_FAIL,
  GET_MESSAGES_SUCCESS,
} from "./actionTypes"

//Get Students //

export const getChats = (page, tabChange) => ({
  type: GET_CHATS,
  payload: { page, tabChange },
})

export const getChatsSuccess = Chats => ({
  type: GET_CHATS_SUCCESS,
  payload: Chats,
})
export const getChatsFail = error => ({
  type: GET_CHATS_FAIL,
  payload: error,
})

export const getMessages = (chat_id, page, tabChange) => ({
  type: GET_MESSAGES,
  payload: { chat_id, page, tabChange },
})

export const getMessagesSuccess = Chats => ({
  type: GET_MESSAGES_SUCCESS,
  payload: Chats,
})
export const getMessagesFail = error => ({
  type: GET_MESSAGES_FAIL,
  payload: error,
})
