import React, { useState } from "react"
import { Link, useHistory } from "react-router-dom"
import { Card, CardBody, Col, Container, Row } from "reactstrap"
import { AvForm, AvField } from "availity-reactstrap-validation"
import logo from "assets/images/logo/logo_sm.png"
import { resendOtp, userLoginRequest, verificationRequest } from "store/actions"
import { useDispatch } from "react-redux"
import PropTypes from "prop-types"
import { useSelector } from "react-redux"
import MetaTag from "components/Common/Meta-tag"

const Verify = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const [sessionKey, setSessionKey] = useState(
    localStorage.getItem("session_key")
  )

  const { loading, error } = useSelector(state => ({
    loading: state.Login.loading,
    error: state.Login.error,
  }))

  const hanldeVerify = (e, input) => {
    dispatch(verificationRequest(input, history))
  }
  const handleResendOtp = (e) => {
    let data = {"session_key":sessionKey}
    dispatch(resendOtp(data, history))
  }

  return (
    <React.Fragment>
      <MetaTag title="Login" />

      <Container
        className="p-5 mobile-verifiy sm:p-0 rounded-circle d-flex flex-column justify-content-center"
        style={{ height: "100vh" }}
      >
        <Row className="justify-content-center">
          <Col md={8} lg={6} xl={5}>
            <Card className="overflow-hidden shadow-rounded">
              <CardBody className="pt-0">
                <div className="d-flex justify-content-center aling-items-center pt-4">
                  <div className="avatar-md profile-user-wid">
                    <img alt={"logo"} height="70" src={logo} />
                  </div>
                </div>
                <p
                  className="p-2 pb-0 font-size-18 mb-4 text-center"
                  style={{ fontWeight: "500" }}
                >
                  Email Verification
                </p>
                <div className="p-2">
                  <AvForm
                    onValidSubmit={(e, input) => {
                      hanldeVerify(e, input)
                    }}
                  >
                    <div className="mb-3">
                      <AvField
                        name="session_key"
                        type="hidden"
                        required
                        value={sessionKey}
                      />
                    </div>

                    <div className="mb-3 position-relative">
                      <AvField
                        name="otp"
                        label="OTP"
                        type={"text"}
                        required
                        placeholder="Enter Otp"
                      />
                    </div>

                    <p className="pl-2 pb-2 pt-2 ml-10px">
                      Note: your OTP can be seen in your inbox, dont forget to
                      check on spam mail
                    </p>
                    <p className="pl-2 pb-2 pt-2 ml-10px">
                      <button type="button" className="btn btn-info btn-sm" onClick={(e) => {
                      handleResendOtp(e)
                    }}>Resend OTP</button>
                    </p>

                    <div className="mt-3 d-grid">
                      <button
                        className="btn btn-success btn-block"
                        type="submit"
                      >
                        {loading && (
                          <>
                            <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                          </>
                        )}
                        Verify
                      </button>
                    </div>

                    <div className="mt-4 text-center"></div>
                  </AvForm>
                </div>
              </CardBody>
            </Card>
            <div className="mt-5 text-center">
              <p>
                © {new Date().getFullYear()} Career Pro | All Right Issued |
                Crafted with <i className="mdi mdi-heart text-danger" /> by{" "}
                <a href="https://osperb.com/"> Osperb</a>
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  )
}

export default Verify

// Login.propTypes = {
//   history: PropTypes.object,
// }
