import React, { useState } from 'react'
import logo from "assets/images/logo/logo.png"
import loginBg from "assets/images/other/login-bg.png"
import { IoIosArrowBack } from 'react-icons/io'
import { AvField, AvForm } from 'availity-reactstrap-validation'
import { Link, useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { userLoginRequest } from 'store/actions'

function MobileLogin() {
    const history = useHistory()
    const dispatch = useDispatch()
    const [showPass, setShowPass] = useState(false)

    const { loading, error } = useSelector(state => ({
        loading: state.Login.loading,
        error: state.Login.error,
    }))

    const hanldeLogin = (e, input) => {
        console.log(input)
        dispatch(userLoginRequest(input, history))
    }

    return (
        <div className="mobile p-3">
            <div className="d-flex justify-content-between align-items-center">
                <IoIosArrowBack size={'1.2rem'} className="text-darkGreen" style={{ cursor: "pointer" }} />
                <div><img alt="logo" className="rounded" height={20} width={80} src={logo} /></div>
            </div>
            <div className='mt-5'>
                <p className='text-center text-darkGreen font-size-22 mb-4 mt-3'
                    style={{ fontWeight: "500" }}

                >Login</p>
                <div className='d-flex w-100 justify-content-evenly align-items-center'>
                    <div></div>
                    <img src={loginBg} alt="" style={{ height: 200 }} />
                </div>

                <div className="p-2">
                    <AvForm
                        onValidSubmit={(e, input) => {
                            hanldeLogin(e, input)
                        }}
                    >
                        <div className="mb-3">
                            <AvField
                                name="email"
                                label="Email"
                                className="form-control sm:input"
                                placeholder="Email"
                                type="email"
                                required
                            />
                        </div>

                        <div className="mb-3 position-relative">
                            <AvField
                                name="password"
                                label="Password"
                                type={showPass ? "text" : "password"}
                                required
                                className="sm:input"
                                placeholder="Enter Password"
                            />
                            <div
                                className="w-fit  position-absolute d-flex p-2 bg-light align-items-center justify-content-center"
                                style={{
                                    right: "1px",
                                    bottom: "1px",
                                    height: "43px",
                                    width: "40px",
                                    cursor: "pointer",
                                    borderRadius: "10px"
                                }}
                                onClick={() => setShowPass(!showPass)}
                            >
                                {showPass ? (
                                    <i className="far fa-eye-slash mx-1"></i>
                                ) : (
                                    <i className="far fa-eye mx-1"></i>
                                )}
                            </div>
                        </div>
                        {error?.response === "Error" && (
                            <span className="text-danger">
                                {error?.error_message}
                            </span>
                        )}

                        <p className="pl-2 pb-2 pt-2 font-size-11 ml-10px">
                            Don&#39;t have an account ?{" "}
                            <Link to="/register" className="fw-medium text-primary">
                                {" "}
                                Create now{" "}
                            </Link>{" "}
                        </p>

                        <div className="mt-3 d-grid">
                            <button
                                className="btn btn-success btn-block "
                                type="submit"
                            >
                                {loading && (
                                    <>
                                        <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                                    </>
                                )}
                                Log In
                            </button>
                        </div>

                        <div className="mt-4 text-center font-size-12">
                            <Link to="/forgetpassword" className="text-muted">
                                <i className="mdi mdi-lock me-1" />
                                Forgot your password?
                            </Link>
                        </div>

                        <div className="mt-4 text-center font-size-12">
                            <p>
                                © {new Date().getFullYear()} Career Pro |
                                Crafted with <i className="mdi mdi-heart text-danger" /> by{" "}
                                <a href="https://osperb.com/"> Osperb</a>
                            </p>
                        </div>


                    </AvForm>
                </div>
            </div>
        </div>
    )
}

export default MobileLogin