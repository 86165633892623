import { IMAGE_URL } from "helpers/api_methods"
import React from "react"
import { FaUpload } from "react-icons/fa"
import { useSelector } from "react-redux"
import { Link } from "react-router-dom"
import { Input } from "reactstrap"
import PropTypes from "prop-types"
import { MdOutlineModeEditOutline } from "react-icons/md"
import FileSaver from "file-saver"
import avatar from "assets/images/defualt/avatar.png"

function MobileStudentProfile({ onChangeHandleProfile }) {
  const { studentProfile } = useSelector(state => ({
    studentProfile: state?.StudentDashboard?.studentProfile,
  }))

  const Download = async FileUrl => {
    var myArray = FileUrl.split("://")
    var imageUrl = `${IMAGE_URL}${FileUrl}`

    if (myArray[0] == "http" || myArray[0] == "https") {
      imageUrl = `${FileUrl}`
    }

    const filename = myArray[0]?.split("/")
    saveAs(imageUrl, filename[filename?.length - 1])

    // var myArray = FileUrl.split("://")

    // var image = `${IMAGE_URL}${FileUrl}`
    // if (myArray[0] == "http" || myArray[0] == "https") {
    //   image = `${FileUrl}`
    // }
    // if (FileUrl) {
    //   FileSaver.saveAs(image, "image.jpg")
    // }
  }

  return (
    <>
      <div className="d-flex align-items-center justify-content-between mb-4">
        <div
          className="me-3 position-relative profile-images"
          style={{
            width: 97,
          }}
        >
          {studentProfile?.profilepic ? (
            <img
              src={`${IMAGE_URL}${studentProfile?.profilepic}`}
              alt=""
              className="avatar-md rounded-circle img-thumbnail"
            />
          ) : (
            <div>
              <img
                src={avatar}
                alt=""
                className="avatar-md rounded-circle img-thumbnail"
              />
            </div>
          )}

          {studentProfile?.profilepic && (
            <Link
              to={"#"}
              className="profile-download"
              onClick={() => Download(studentProfile?.profilepic)}
            >
              <div>
                <i
                  className="bx bx-download h3 py-2 "
                  style={{ color: "#034e60" }}
                ></i>
              </div>
            </Link>
          )}
          <label className="upload-icons m-0" htmlFor="profilepic">
            <FaUpload color="#034e60" />
          </label>
          <Input
            hidden
            content
            onChange={onChangeHandleProfile}
            type="file"
            name="profilepic"
            id="profilepic"
          />
        </div>
        <div className="w-100">
          <h5 className="text-white">{studentProfile?.account?.full_name}</h5>
          <p className="text-darkGreen mb-0">
            {studentProfile?.account?.phone}
          </p>
        </div>
      </div>
      <Link
        className="btn mb-3 btn-md btn-light me-3 w-100"
        to={{
          pathname: `/student/update/${studentProfile?.id}`,
          state: "update",
        }}
        style={{
          background: "#034e60",
          color: "#fff",
          border: "none",
        }}
      >
        <MdOutlineModeEditOutline
          className="me-1"
          style={{
            fontSize: "15px",
          }}
        />
        Edit Profile
      </Link>
    </>
  )
}

export default MobileStudentProfile

MobileStudentProfile.propTypes = {
  onChangeHandleProfile: PropTypes.any,
}
