import React from "react"
import { Card, CardBody } from "reactstrap"
import { MdLibraryBooks } from "react-icons/md"
import { AiOutlineCodepen } from "react-icons/ai"
import { map } from "lodash"
import { useSelector } from "react-redux"

function MobileCardItems() {
  const { studentProfile } = useSelector(state => ({
    studentProfile: state?.StudentDashboard?.studentProfile,
  }))
  const CardFullData = [
    // {
    //     title: "Course",
    //     icon: <MdLibraryBooks />,
    //     items: [
    //         {
    //             itemTitle: "Course Name",
    //             value: studentProfile?.course_title
    //         },
    //         {
    //             itemTitle: "Started on",
    //             value: studentProfile?.course_start_date
    //         },
    //         {
    //             itemTitle: "End in",
    //             value: studentProfile?.course_end_date
    //         },
    //         {
    //             itemTitle: "Course Duration",
    //             value: studentProfile?.course_duration
    //         },
    //     ]
    // },
    // {
    //     title: " University / VISA",
    //     icon: <MdLibraryBooks />,
    //     items: [
    //         {
    //             itemTitle: "University Name",
    //             value: studentProfile?.university
    //         },
    //         {
    //             itemTitle: "VISA Starts in",
    //             value: studentProfile?.visa_start_date
    //         },
    //         {
    //             itemTitle: "VISA Exp",
    //             value: studentProfile?.visa_end_date
    //         },
    //         {
    //             itemTitle: "Country",
    //             value: studentProfile?.course_campus
    //         },
    //     ]
    // },
    // {
    //     title: "LinkedIn",
    //     icon: <MdLibraryBooks />,
    //     items: [
    //         {
    //             itemTitle: "Username",
    //             value: studentProfile?.linkedin_username
    //         },
    //         {
    //             itemTitle: "Password",
    //             value: studentProfile?.linkedin_password
    //         },
    //     ]
    // },
    // {
    //   title: " Industrial Experience",
    //   icon: <AiOutlineCodepen />,
    //   items: [
    //     {
    //       itemTitle: "Working Type",
    //       value: studentProfile?.working_type,
    //     },
    //     {
    //       itemTitle: "Start Date",
    //       value: studentProfile?.work_start_date,
    //     },
    //     {
    //       itemTitle: "End Date",
    //       value: studentProfile?.work_end_date,
    //     },
    //     {
    //       itemTitle: "Duration",
    //       value: studentProfile?.work_duration,
    //     },
    //     {
    //       itemTitle: "Submission Deadline",
    //       value: studentProfile?.university_dead_line,
    //     },
    //   ],
    // },
  ]

  return (
    <>
      {CardFullData?.map((item, key) => (
        <Card key={key} className="blog-stats-wid glass-card border-white">
          <CardBody>
            <div className="">
              <div className="me-3 mb-3 d-flex align-items-center">
                <div className="avatar-xs me-3">
                  <span className="avatar-title rounded-circle bg-light bg-soft text-darkGreen font-size-14">
                    {item?.icon}
                  </span>
                </div>
                <p className="mb-1 pb-0 text-darkGreen">{item?.title}</p>
              </div>
              <div className="d-flex flex-wrap justify-content-between">
                {map(item?.items, (subItem, key1) => (
                  <div
                    key={key1}
                    className="w-50 mb-3 d-flex flex-column align-items-start justify-content-satrt mb-2"
                  >
                    <p
                      style={{ width: "auto", opacity: 0.8 }}
                      className="mb-0 sp-heading"
                    >
                      {subItem?.itemTitle}
                    </p>
                    <p
                      style={{ fontWeight: 500 }}
                      className="mb-0 sp-des text-start flex-1"
                    >
                      {subItem?.value}{" "}
                    </p>
                  </div>
                ))}
              </div>
            </div>
          </CardBody>
        </Card>
      ))}
    </>
  )
}

export default MobileCardItems
