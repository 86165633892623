import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { Card, CardBody, Button, Input } from "reactstrap"
import PersonalInfo from "./PersonalInfo"
import ModulesSpecifications from "./ModulesSpecifications"
import { AvForm } from "availity-reactstrap-validation"
import { useDispatch, useSelector } from "react-redux"
import { useHistory, useLocation, useParams } from "react-router-dom"
import {
  getStudentDetails,
  imageUpload,
  selfDeleteStudent,
  updateStudent,
} from "store/actions"
import UpdateCareerIndrest from "pages/Students/SingleView/elements/CareerInterest"
import { useWindowSize } from "react-use"
import { IoIosArrowDroprightCircle, IoIosArrowDropleft } from "react-icons/io"
import "react-datepicker/dist/react-datepicker.css"
import moment from "moment"
import YesOrNoQuestions from "./YesOrNoQuestions"
import DeleteModal from "components/Common/DeleteModal"

function ProfileTabs() {
  const dispatch = useDispatch()
  const params = useParams()
  const location = useLocation()
  const history = useHistory()

  const { studentSingleData, studentProfile } = useSelector(state => ({
    studentSingleData: state.StudentDetails?.StudentsSingleview,
    studentProfile: state?.StudentDashboard?.studentProfile,
  }))

  const [activeTab, setActiveTab] = useState("1")
  const [currentTabTitle, setCurrentTabTitle] = useState("")
  const [passedTab, setPassedTab] = useState(["1"])

  const [workingType, setWorkingType] = useState("Internship")

  const Role = localStorage.getItem("role")

  const [module, setModule] = useState([
    { uuid: 1 },
    { uuid: 2 },
    { uuid: 3 },
    { uuid: 4 },
  ])
  const [errorState, setErrorState] = useState()

  const [dob, setDob] = useState()

  const [courseStartDate, setCourseStartDate] = useState()
  const [courseEndDate, setCourseEndDate] = useState()
  const [visaStartDate, setVisaStartDate] = useState()
  const [visaEndDate, setVisaEndDate] = useState()

  const [workStartDate, setWorkStartDate] = useState()
  const [workEndDate, setWorkEndDate] = useState()
  const [submissionDeadline, setSubmissionDeadline] = useState()

  const [placementStartDate, setPlacementStartDate] = useState()
  const [placementEndDate, setPlacementEndDate] = useState()

  const [isNoInternship, setIsNoInternship] = useState(false)
  const [isInternshipInterested, setIsInternshipInterested] = useState(null)
  const [isPreviousExperience, setIsPreviousExperience] = useState(null)
  const [isWorkExperience, setIsWorkExperience] = useState(null)
  const [isConfidentWorkplaceSkills, setIsConfidentWorkplaceSkills] =
    useState(null)
  const [hasAcademicProjects, setHasAcademicProjects] = useState(null)
  const [isIncludeWorkProject, setIsIncludeWorkProject] = useState(null)
  const [hasInternshipExperience, setHasInternshipExperience] = useState(null)

  useEffect(() => {
    setDob(studentSingleData?.dob)
    setCourseStartDate(studentSingleData?.course_start_date)
    setVisaStartDate(studentSingleData?.visa_start_date)
    setVisaEndDate(studentSingleData?.visa_end_date)

    setWorkStartDate(studentSingleData?.work_start_date)
    setWorkEndDate(studentSingleData?.work_end_date)
    setSubmissionDeadline(studentSingleData?.university_dead_line)

    setPlacementStartDate(studentSingleData?.work_start_date)
    setPlacementEndDate(studentSingleData?.work_end_date)

    setIsNoInternship(studentSingleData?.is_no_internship)
    setIsInternshipInterested(studentSingleData?.is_internship_interested)
    setIsPreviousExperience(studentSingleData?.is_previous_experience)
    setIsWorkExperience(studentSingleData?.is_work_experience)
    setIsConfidentWorkplaceSkills(
      studentSingleData?.is_confident_workplace_skills
    )
    setHasAcademicProjects(studentSingleData?.has_academic_projects)
    setIsIncludeWorkProject(studentSingleData?.is_include_work_project)
    setHasInternshipExperience(studentSingleData?.has_internship_experience)
  }, [studentSingleData])

  useEffect(() => {
    dispatch(getStudentDetails(params.id))
  }, [dispatch, params.id])

  const handleValidUpdateSubmit = (e, values) => {
    const account = {
      email: values.email,
      phone: values.phone,
      full_name: values.full_name,
      role: studentSingleData?.account?.role,
    }

    const finalData = {
      ...values,
      // dob: useMoment(dob, 'YYYY-MM-DD').formatedDate,
      // dob: useMoment(dob, 'YYYY-MM-DD').formatedDate,

      dob: moment(dob).format("YYYY-MM-DD"),
      course_start_date: moment(courseStartDate).format("YYYY-MM-DD"),
      course_end_date: moment(courseEndDate).format("YYYY-MM-DD"),
      visa_start_date: moment(visaStartDate).format("YYYY-MM-DD"),
      visa_end_date: moment(visaEndDate).format("YYYY-MM-DD"),

      work_start_date: moment(workStartDate).format("YYYY-MM-DD"),
      work_end_date: moment(workEndDate).format("YYYY-MM-DD"),
      university_dead_line: moment(submissionDeadline).format("YYYY-MM-DD"),

      working_type: workingType,
      account: account,

      specializations:
        values?.specializations?.length >= 1 ? values?.specializations : [],

      work_end_date: moment(placementStartDate).format("YYYY-MM-DD"),
      work_start_date: moment(placementEndDate).format("YYYY-MM-DD"),

      is_no_internship: isNoInternship,
      is_internship_interested: isInternshipInterested,
      is_previous_experience: isPreviousExperience,
      is_work_experience: isWorkExperience,
      is_confident_workplace_skills: isConfidentWorkplaceSkills,
      has_academic_projects: hasAcademicProjects,
      is_include_work_project: isIncludeWorkProject,
      has_internship_experience: hasInternshipExperience,
    }
    dispatch(updateStudent(finalData, params.id, "", history))
  }

  const navigationCard = [
    {
      title: "Profile Info",
      tabId: "1",
      iconClass: "fas fa-user-tie",
    },
    {
      title: "University & Courses",
      tabId: "2",
      iconClass: "fas fa-university",
    },
    {
      title: "Moduls & Specification(optional)",
      tabId: "3",
      iconClass: "fas fa-hockey-puck",
    },
    {
      title: "Industrial Experience",
      tabId: "4",
      iconClass: "fas fa-building",
    },
    {
      title: "Career Interest",
      tabId: "5",
      iconClass: "bx bx-trophy",
    },
    {
      title: "LinkedIn(optional)",
      tabId: "6",
      iconClass: "fab fa-linkedin",
    },
  ]

  const Data = [
    {
      label: "Internship",
      id: "Internship",
    },
    {
      label: "Placement",
      id: "Placement",
    },
  ]

  const mobileNavigationCard = navigationCard?.map(item => ({
    ...item,
    title1: item?.title,
    title: item.tabId,
  }))

  const { width } = useWindowSize()

  const isMobile = width < 768 ? true : false

  const handleTab = item => {
    setActiveTab(item?.tabId)
    setCurrentTabTitle(item?.title1)
  }

  useEffect(() => {
    if (activeTab === "1") {
      setPassedTab(["1"])
    } else setPassedTab([...passedTab, activeTab])

    if (activeTab === "1") return setCurrentTabTitle("Profile Info")
    else if (activeTab === "2")
      return setCurrentTabTitle("University & Courses")
    else if (activeTab === "3")
      return setCurrentTabTitle("Moduls & Specification")
    else if (activeTab === "4")
      return setCurrentTabTitle("Industrial Experience")
    else if (activeTab === "5") return setCurrentTabTitle("Career Interest")
    else if (activeTab === "6") return setCurrentTabTitle("LinkedIn(optional)")
  }, [activeTab])

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" })
  }, [activeTab])

  useEffect(() => {
    setWorkingType(studentSingleData?.working_type)
  }, [studentSingleData?.working_type])

  // const [isOpenFile, setIsOpenFile] = useState(false)

  const [uploadCv, setUploadCv] = useState()
  const onChangeHandlerCv = e => {
    const fileName = Array.from(e.target.files)
    setUploadCv({
      name: fileName[0]?.name,
      size: fileName[0]?.size,
      image: e.target.files,
    })
    const finalData = {
      cv: e.target.files,
    }

    dispatch(
      imageUpload(finalData, params?.id ? params?.id : studentProfile?.id, "")
    )
  }

  const [openModal, setOpenModal] = useState(false)

  const handleDeleteEvent = () => {
    setOpenModal(false)
    dispatch(selfDeleteStudent(studentSingleData?.id, history))
  }

  return (
    <React.Fragment>
      <DeleteModal
        show={openModal}
        onCloseClick={() => setOpenModal(false)}
        onDeleteClick={handleDeleteEvent}
      />
      {/* new ui form start */}
      <div className="">
        <AvForm
          onValidSubmit={(e, v) => {
            handleValidUpdateSubmit(e, v)
          }}
          onSubmit={(e, er, vl) => setErrorState(er)}
          className="mobile-ui-form"
        >
          <Card className="sm:bg-transparent box-shadow-none w-75 sm:w-100">
            <CardBody className="sm:p-0">
              <div>
                {isMobile ? (
                  <h3
                    className="font-size-14 px-2 sm:pb-0 sm:mt-1 text-darkGreen sm:fw-600"
                    style={{ width: "fit-content" }}
                  >
                    Profile
                  </h3>
                ) : (
                  <div className="mb-3 sm:mb-0 d-flex justify-content-between align-items-center">
                    <p className="mb-0 ">Profile</p>
                    <i
                      onClick={() => setOpenModal(true)}
                      className="bx bx-trash-alt fs-4 text-danger"
                      style={{ cursor: "pointer" }}
                    />
                  </div>
                )}
                <PersonalInfo dob={dob} setDob={setDob} />
              </div>

              <ModulesSpecifications module={module} setModule={setModule} />

              <div className="mx-3 mb-4">
                <YesOrNoQuestions
                  content="Do you have any work experience ?"
                  setState={setIsPreviousExperience}
                  sate={isPreviousExperience}
                />
                <YesOrNoQuestions
                  content="Do you have any previous work experience that is relevant to your qualifications ?"
                  setState={setIsWorkExperience}
                  sate={isWorkExperience}
                />
              </div>

              <div className="px-3">
                <UpdateCareerIndrest />
              </div>

              <div className="mx-3 mb-4">
                <YesOrNoQuestions
                  content="Are you confident in your workplace skills for the job roles ?"
                  setState={setIsConfidentWorkplaceSkills}
                  sate={isConfidentWorkplaceSkills}
                />
                <YesOrNoQuestions
                  content="Have you ever worked on any academic projects ?"
                  setState={setHasAcademicProjects}
                  sate={hasAcademicProjects}
                />
                <YesOrNoQuestions
                  content="Have you ever been a part of any work-related projects ?"
                  setState={setIsIncludeWorkProject}
                  sate={isIncludeWorkProject}
                />
                <YesOrNoQuestions
                  content="Do you have any prior internship experience ?"
                  setState={setHasInternshipExperience}
                  sate={hasInternshipExperience}
                />
              </div>
              <div className="w-50 sm:w-100">
                <p className="sm:text-white">Upload your current resume</p>
                {/* <AvField type="file" label="" name="im" /> */}
                <label
                  className="bg-none rounded bg-soft label-edit d-flex flex-row"
                  style={{
                    border: "1px solid #cfcfcf",
                  }}
                >
                  <div>
                    <i
                      style={{ fontSize: "25px" }}
                      className="bx bx-image-add text-muted"
                    ></i>
                    <Input
                      hidden
                      content
                      onChange={onChangeHandlerCv}
                      type="file"
                      name="cv"
                      className=""
                    />
                  </div>
                  {uploadCv?.name && (
                    <p className="p-0 m-0 font-size-14 ms-2">
                      {uploadCv?.name}
                    </p>
                  )}
                </label>
                {/* {studentSingleData?.cv && (
                  <p className="p-0 m-0 font-size-14 ms-2">
                    {studentSingleData?.cv}
                  </p>
                )} */}
                {uploadCv?.name && (
                  <p className="sm:text-white">
                    Thank you for submitting your resume! We will contact you as
                    soon as we have reviewed it
                  </p>
                )}
              </div>
              {/* <AttachedFiles
                show={true}
                onCloseClick={() => setIsOpenFile(false)}
              /> */}
              <div className="d-flex flex-column ">
                <p className="mt-2 w-75 sm:w-100 sm:text-white">{`Let's work together to accomplish your career aspirations 🤝 For any updates, feel free to contact us through our chat option or WhatsApp at +44 7741639374. We're always here to assist you!`}</p>
                <Button
                  size="md"
                  color="success"
                  type="submit"
                  className="px-3 mt-4 mobile-submit"
                >
                  {location.state === "update" ? "Save Changes" : "Submit"}
                </Button>
              </div>
            </CardBody>
          </Card>
        </AvForm>
      </div>
      {/* new ui form end */}

      {/* // old ui start */}
      {/* <div className="checkout-tabs">
        <Row>
          <Col xl="3" sm="3" className="updateStudentProfile mt-2">
            {isMobile && Role === "student" ? (
              <p
                className="text-center font-size-11 text-gray mx-4 mb-3 sm:mx-0"
                style={{ fontWeight: "500", color: "#034e60" }}
              >
                <TbNotification className="font-size-13 text-danger" /> Complete
                your profile by filling out the following form
              </p>
            ) : (
              ""
            )}

            {!isMobile ? (
              <Nav className="flex-column" pills>
                {map(navigationCard, (item, key) => (
                  <NavItem key={key}>
                    <NavLink
                      className={classnames(
                        activeTab === item?.tabId && "bg-info",
                        {
                          active: activeTab === item?.tabId,
                        }
                      )}
                      onClick={() => {
                        setActiveTab(item?.tabId)
                      }}
                    >
                      <i className={`check-nav-icon ${item?.iconClass}`} />
                      <p className="font-weight-bold mb-0">{item?.title}</p>
                    </NavLink>
                  </NavItem>
                ))}
              </Nav>
            ) : (
              <>
                <div className="d-flex align-items-center justify-content-center">
                  <h6 className="text-black mt-3">{currentTabTitle}</h6>
                </div>
                <Nav className="flex-column mobile-nab-bar mx-3" pills>
                  {map(mobileNavigationCard, (item, key) => (
                    <NavItem key={key}>
                      <NavLink
                        className={classnames("bg-info", {
                          active: passedTab?.includes(item?.tabId),
                        })}
                        onClick={() => handleTab(item)}
                      >
                        <p className="font-weight-bold mb-0">{item?.title} </p>
                      </NavLink>
                    </NavItem>
                  ))}
                  <div className="nav-line"></div>
                  <div
                    className="nav-line active"
                    style={{ width: `${parseInt(activeTab) * 16.6}%` }}
                  ></div>
                </Nav>
              </>
            )}
          </Col>
          <Col className="col-lg-9 col-12">
            <AvForm
              onValidSubmit={(e, v) => {
                handleValidUpdateSubmit(e, v)
              }}
              onSubmit={(e, er, vl) => setErrorState(er)}
              className="mobile-ui-form"
            >
              <Card className="sm:bg-transparent box-shadow-none">
                <CardBody className="sm:p-0">
                  <TabContent activeTab={activeTab}>
                    <TabPane tabId="1" id="v-pills-presonalinfo">
                      <PersonalInfo dob={dob} setDob={setDob} />
                      <NavigationButtons
                        setActiveTab={setActiveTab}
                        activeTab={activeTab}
                      />
                    </TabPane>
                    <TabPane tabId="2" id="v-pills-university">
                      <div>
                        <h3
                          className="font-size-14 px-2 sm:pb-0 sm:mb-0 sm:mt-1 text-darkGreen sm:fw-600"
                          style={{ width: "fit-content" }}
                        >
                          University / Courses
                        </h3>
                        <div className="p-2 rounded">
                          <UniversityCourses />
                          <div className="sm:mt-4">
                            <p className="font-size-14 mt-3 sm:pb-0 sm:mb-0 sm:mt-1 text-darkGreen sm:fw-600 ">
                              Course Timeline
                            </p>
                            <Row className="pb-4 sm:pb-0">
                              <Col lg={6} className="mb-3">
                                <MyDatePicker
                                  label={"Course Start Date"}
                                  selectedDate={courseStartDate}
                                  setSelectedDate={setCourseStartDate}
                                />
                              </Col>
                              <Col lg={6} className="mb-3">
                                <MyDatePicker
                                  label={"Course End Date"}
                                  selectedDate={courseEndDate}
                                  setSelectedDate={setCourseEndDate}
                                />
                              </Col>
                              <Col lg={6} className="mb-3">
                                <MyDatePicker
                                  label={"VISA Start Date"}
                                  selectedDate={visaStartDate}
                                  setSelectedDate={setVisaStartDate}
                                />
                              </Col>
                              <Col lg={6} className="mb-3">
                                <MyDatePicker
                                  label={"VISA End Date"}
                                  selectedDate={visaEndDate}
                                  setSelectedDate={setVisaEndDate}
                                />
                              </Col>
                            </Row>
                          </div>
                        </div>
                      </div>
                      <NavigationButtons
                        setActiveTab={setActiveTab}
                        activeTab={activeTab}
                      />
                    </TabPane>

                    <TabPane tabId="3" id="v-pills-module">
                      <ModulesSpecifications
                        module={module}
                        setModule={setModule}
                      />
                      <NavigationButtons
                        setActiveTab={setActiveTab}
                        activeTab={activeTab}
                      />
                    </TabPane>

                    <TabPane tabId="4" id="v-pills-industrial">
                      {!isNoInternship && (
                        <div>
                          {isMobile ? (
                            <h3 className="font-size-14 px-2 mb-2 text-darkGreen">
                              Industrial experience method details <br />
                              <span className="font-size-10">
                                (Provide details according to your University
                                policy)
                              </span>
                            </h3>
                          ) : (
                            <h3 className="font-size-14 px-2 mb-4 ">
                              Industrial experience method details <br />
                              <span className="font-size-12">
                                (Provide details according to your University
                                policy)
                              </span>
                            </h3>
                          )}

                          <div className="d-flex flex-wrap">
                            {map(Data, (item, key) => (
                              <div className="me-2 mb-3" key={key}>
                                {" "}
                                <input
                                  type="radio"
                                  className="btn-check"
                                  name="btnradio2"
                                  id={item.id}
                                  checked={
                                    workingType === item.id ? true : false
                                  }
                                  autoComplete="off"
                                  value={item.id}
                                  onChange={e => setWorkingType(e.target.value)}
                                />
                                <label
                                  className={
                                    isMobile
                                      ? "btn btn-outline-warning"
                                      : "btn btn-outline-info"
                                  }
                                  htmlFor={item.id}
                                >
                                  {item.label}
                                </label>
                              </div>
                            ))}
                          </div>

                          {workingType === "Internship" ? (
                            <>
                              <Row className="pb-4 sm:pb-0">
                                <Col lg={6} className="mb-3">
                                  <MyDatePicker
                                    label={"Internship Start Date"}
                                    selectedDate={workEndDate}
                                    setSelectedDate={setWorkEndDate}
                                  />
                                </Col>
                                <Col lg={6} className="mb-3">
                                  <AvField
                                    name="work_duration"
                                    label="Internship Duration"
                                    value={studentSingleData?.work_duration}
                                    type="text"
                                  />
                                </Col>
                              </Row>
                              <Row>
                                <Col lg={6} className="mb-3">
                                  <MyDatePicker
                                    label={"Internship End Date"}
                                    selectedDate={workStartDate}
                                    setSelectedDate={setWorkStartDate}
                                  />
                                </Col>
                                <Col lg={6} className="mb-3">
                                  <MyDatePicker
                                    label={"Submission Deadline"}
                                    selectedDate={submissionDeadline}
                                    setSelectedDate={setSubmissionDeadline}
                                  />
                                </Col>
                              </Row>
                            </>
                          ) : workingType === "Placement" ? (
                            <>
                              <Row className="pb-4 sm:pb-0">
                                <Col lg={6} className="mb-3">
                                  <MyDatePicker
                                    label={"Placement Start Date"}
                                    selectedDate={placementStartDate}
                                    setSelectedDate={setPlacementStartDate}
                                  />
                                </Col>
                                <Col lg={6} className="mb-3">
                                  <AvField
                                    name="work_duration"
                                    label="Placement Duration"
                                    value={studentSingleData?.work_duration}
                                    type="text"
                                  />
                                </Col>
                              </Row>
                              <Row>
                                <Col lg={6} className="mb-3">
                                  <MyDatePicker
                                    label={"Placement End Date"}
                                    selectedDate={placementEndDate}
                                    setSelectedDate={setPlacementEndDate}
                                  />
                                </Col>
                                <Col lg={6} className="mb-3">
                                  <MyDatePicker
                                    label={"Submission Deadline"}
                                    selectedDate={submissionDeadline}
                                    setSelectedDate={setSubmissionDeadline}
                                  />
                                </Col>
                              </Row>
                            </>
                          ) : (
                            <></>
                          )}
                        </div>
                      )}

                      {isMobile ? (
                        <div className="d-flex align-items-center ">
                          <input
                            type="checkbox"
                            className="mx-2 myCheckBox"
                            name="isNoInternship"
                            id={"isNoInternshipId"}
                            autoComplete="off"
                            checked={isNoInternship}
                            onChange={e => setIsNoInternship(e.target.checked)}
                          />
                          <label
                            htmlFor="isNoInternshipId"
                            className="font-size-14 px-2 sm:pb-0 sm:mb-0 sm:mt-1 text-darkGreen"
                            style={{
                              width: "fit-content",
                              cursor: "pointer",
                            }}
                          >
                            My course does not include an internship or
                            placement
                          </label>
                        </div>
                      ) : (
                        <>
                          <label
                            htmlFor="isNoInternshipId"
                            style={{
                              borderRadius: "10px",
                              cursor: "pointer",
                            }}
                            className={`bg-light-${
                              isNoInternship ? "success" : "gray"
                            } p-3 f-flex align-items-center`}
                          >
                            My course does not include an internship or
                            placement
                            <input
                              type="checkbox"
                              className="mx-3"
                              name="isNoInternship"
                              id={"isNoInternshipId"}
                              checked={isNoInternship}
                              autoComplete="off"
                              onChange={e =>
                                setIsNoInternship(e.target.checked)
                              }
                            />
                          </label>
                        </>
                      )}

                      {isNoInternship && (
                        <div className="">
                          <p className="px-1 mt-2 isNoInternshipQuestion ">
                            {
                              "Are you interested in doing an internship or placement that isn't required by your syllabus ?"
                            }
                          </p>
                          <div
                            className="btn-group btn-group-example mb-3"
                            role="group"
                          >
                            <button
                              onClick={() => setIsInternshipInterested(true)}
                              type="button"
                              className={`btn ${
                                isInternshipInterested
                                  ? "bg-success"
                                  : "bg-light-success"
                              } w-xs`}
                            >
                              <i className="mdi mdi-thumb-up me-1"></i>
                              Yes
                            </button>
                            <button
                              onClick={() => setIsInternshipInterested(false)}
                              type="button"
                              className={`btn ${
                                isInternshipInterested === false
                                  ? "bg-success"
                                  : "bg-light-gray"
                              } w-xs`}
                            >
                              <i className="mdi mdi-thumb-down me-1"></i>
                              No
                            </button>
                          </div>
                          <p className="px-1 mt-2 isNoInternshipQuestion ">
                            <TbPointFilled className="me-2" />
                            {
                              "Completing an internship or placement can improve your chances of securing a job and enhance your CV"
                            }
                          </p>
                        </div>
                      )}

                      <NavigationButtons
                        setActiveTab={setActiveTab}
                        activeTab={activeTab}
                      />
                    </TabPane>
                    <TabPane tabId="5" id="v-pills-Career">
                      <UpdateCareerIndrest />
                      <NavigationButtons
                        setActiveTab={setActiveTab}
                        activeTab={activeTab}
                      />
                    </TabPane>

                    <TabPane tabId="6" id="v-pills-linkin">
                      <LInkedIn />
                      <div className="d-flex align-items-center justify-content-end mx-4 ">
                        {errorState?.length >= 1 && (
                          <p className="me-2 mb-0 pb-0 text-danger">
                            ({errorState}) this Field is Compulsory
                          </p>
                        )}
                        <NavigationButtons
                          setActiveTab={setActiveTab}
                          activeTab={activeTab}
                        />
                        <Button
                          size="md"
                          color="success"
                          type="submit"
                          className="px-3 mt-4 mobile-submit"
                        >
                          {location.state === "update"
                            ? "Submit Updates"
                            : "Submit"}
                        </Button>
                      </div>
                    </TabPane>
                  </TabContent>
                </CardBody>
              </Card>

              {isMobile ? (
                <>
                  {activeTab <= 5 && location.state === "update" && (
                    <div
                      className="d-flex align-items-center mx-2 justify-content-end mb-5"
                      style={{ borderRadius: 15 }}
                    >
                      {errorState?.length >= 1 && (
                        <p className="me-2 mb-0 pb-0 text-danger">
                          ({errorState}) this Field is Compulsory
                        </p>
                      )}
                      <Button
                        size="md"
                        type="submit"
                        className="px-3 w-100 submit-btn"
                      >
                        {location.state === "update"
                          ? "Submit Updates"
                          : "Submit"}
                      </Button>
                    </div>
                  )}
                </>
              ) : (
                <>
                  {activeTab <= 5 && location.state === "update" && (
                    <Card>
                      <CardBody>
                        <div className="d-flex align-items-center justify-content-end mx-4 ">
                          {errorState?.length >= 1 && (
                            <p className="me-2 mb-0 pb-0 text-danger">
                              ({errorState}) this Field is Compulsory
                            </p>
                          )}
                          <Button
                            size="md"
                            color="success"
                            type="submit"
                            className="px-3"
                          >
                            {location?.state === "update"
                              ? "Submit Updates"
                              : "Submit"}
                          </Button>
                        </div>
                      </CardBody>
                    </Card>
                  )}
                </>
              )}
            </AvForm>
          </Col>
        </Row>
      </div> */}
      {/* // old ui end */}
    </React.Fragment>
  )
}

export default ProfileTabs

function NavigationButtons({ setActiveTab, activeTab }) {
  const location = useLocation()
  const Role = localStorage.getItem("Role")
  return (
    <>
      {location.state === "update" && Role === "student" ? (
        <>
          <div className="d-flex mx-3 justify-content-end mt-2 mobile-nav-button">
            <IoIosArrowDropleft
              size={"1.5rem"}
              className="me-2 text-darkGreen"
              onClick={() =>
                activeTab !== "1" &&
                setActiveTab((parseInt(activeTab) - 1).toString())
              }
            />
            {activeTab <= 5 && (
              <IoIosArrowDroprightCircle
                size={"1.5rem"}
                className="text-darkGreen"
                onClick={() =>
                  setActiveTab((parseInt(activeTab) + 1).toString())
                }
              />
            )}
          </div>
        </>
      ) : (
        <div className="d-flex justify-content-end mt-4 mobile-nav-button">
          <Button
            color="info"
            disabled={activeTab === "1" ? true : false}
            className="mx-2 px-3 prev-b "
            onClick={() => setActiveTab((parseInt(activeTab) - 1).toString())}
          >
            {" "}
            Previuos
          </Button>
          {activeTab <= 5 && (
            <Button
              color="info"
              className="px-6"
              onClick={() => setActiveTab((parseInt(activeTab) + 1).toString())}
            >
              Next
            </Button>
          )}
        </div>
      )}
    </>
  )
}

NavigationButtons.propTypes = {
  setActiveTab: PropTypes.func,
  activeTab: PropTypes.any,
}
