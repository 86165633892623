import {
  GET_CHATS,
  GET_CHATS_SUCCESS,
  GET_CHATS_FAIL,
  GET_MESSAGES,
  GET_MESSAGES_SUCCESS,
  GET_MESSAGES_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  chats: [],
  chatDetails: {},
  error: {},
  loading: false,
}

const ChatDetails = (state = INIT_STATE, action) => {
  switch (action.type) {
    // Get Students//

    case GET_CHATS:
      return {
        ...state,
        loading: true,
      }
    case GET_CHATS_SUCCESS:
      return {
        ...state,
        chats: action.payload,
        error: {},
        loading: false,
      }

    case GET_CHATS_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }


    case GET_MESSAGES:
      return {
        ...state,
        loading: true,
      }
    case GET_MESSAGES_SUCCESS:

      let messages = action.payload.messages.sort((a, b) => (a.id > b.id) ? 1 : ((b.id > a.id) ? -1 : 0));
      state.chats.filter((item) => item.id === action.payload.chat_id)[0].messages = messages
      console.log(action.payload.messages);
      return {
        ...state,
        error: {},
        loading: false,
      }

    case GET_MESSAGES_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    default:
      return state
  }
}

export default ChatDetails
