import React from "react"
import PropTypes from "prop-types"

function YesOrNoQuestions({ content, setState, sate, fullWidth }) {
  return (
    <div>
      {" "}
      <p className={`px-1 mt-2 isNoInternshipQuestion  ${fullWidth && "full"}`}>
        {content}
      </p>
      <div className="btn-group btn-group-example mb-3" role="group">
        <button
          onClick={() => setState && setState(true)}
          type="button"
          className={`btn ${
            sate ? "bg-success text-white" : "bg-light-gray"
          } w-xs`}
        >
          <i className="mdi mdi-thumb-up me-1"></i>
          Yes
        </button>
        <button
          onClick={() => setState && setState(false)}
          type="button"
          className={`btn ${
            sate === false ? "bg-success text-white" : "bg-light-gray"
          } w-xs`}
        >
          <i className="mdi mdi-thumb-down me-1"></i>
          No
        </button>
      </div>
    </div>
  )
}

export default YesOrNoQuestions

YesOrNoQuestions.propTypes = {
  content: PropTypes.string,
  setState: PropTypes.func,
  sate: PropTypes.bool,
  fullWidth: PropTypes.bool,
}
