import React, { useEffect } from "react"
import { Col, Row } from "reactstrap"
import { AvField } from "availity-reactstrap-validation"
import { useSelector } from "react-redux"
import PropTypes from "prop-types"
import MyDatePicker from "components/Common/DatePicker"

const PersonalInfo = ({ setDob, dob }) => {
  const { studentSingleData } = useSelector(state => ({
    studentSingleData: state.StudentDetails?.StudentsSingleview,
  }))

  useEffect(() => {
    let newDate = new Date()
    setDob(newDate)
  }, [])
  console.log("title =>")
  console.log("data :", dob)

  return (
    <Row>
      <Col lg={6} className="mb-3">
        <AvField
          name="full_name"
          label="Full Name"
          value={studentSingleData?.account?.full_name}
          type="text"
          placeholder="Enter your name"
          validate={{
            required: {
              value: true,
              errorMessage: "Full Name is Required",
            },
          }}
        />
      </Col>
      <Col lg={6} className="mb-3">
        <MyDatePicker
          label={"Date Of Birth"}
          selectedDate={dob}
          setSelectedDate={setDob}
        />
      </Col>
      <Col lg={6} className="mb-3">
        <AvField
          name="email"
          label="Email Address"
          type="email"
          value={studentSingleData?.account?.email}
          placeholder="Enter your email"
          validate={{
            required: {
              value: true,
              errorMessage: "Email Address is Required",
            },
          }}
        />
      </Col>
      <Col lg={6} className="mb-3">
        <AvField
          name="phone"
          value={studentSingleData?.account?.phone}
          label="Phone Number"
          type="number"
          placeholder="Contact Number"
        />
      </Col>

      <Col lg={6} className="mb-3">
        <AvField
          name="current_location"
          value={studentSingleData?.current_location}
          label="Current Location"
          type="text"
          placeholder="Location"
        />
      </Col>
      {/* <Col lg={12} className="mb-3">
        <AvField
          value={studentSingleData?.address}
          name="address"
          label="Address"
          type="textarea"
          placeholder=""
        />
      </Col> */}
    </Row>
  )
}

export default PersonalInfo

PersonalInfo.propTypes = {
  setDob: PropTypes.func,
  dob: PropTypes.any,
}
