import React, { useState } from "react"
import "./ImageUpload.css"
import PropTypes from "prop-types"
import { IoMdCloudUpload } from "react-icons/io"

import { errorNotification } from "constants/notification"

function ImageUpload({
  selectedFile,
  setSelectedFile,
  setPreviewURL,
  previewURL,
}) {
  console.log(previewURL)

  const handleFileChange = e => {
    const fileName = Array.from(e.target.files)
    console.log(e.target.files)
    const name =
      fileName[0]?.name.length <= 100
        ? fileName[0]?.name
        : errorNotification("Ensure this filename has at most 100 characters ")

    setSelectedFile({
      name: name,
      size: fileName[0]?.size,
      image: e.target.files[0],
    })
    if (e.target.files && e.target.files[0]) {
      let reader = new FileReader()
      reader.onload = e => {
        setPreviewURL({ image: e.target.result })
      }
      reader.readAsDataURL(e.target.files[0])
    }
  }
  return (
    <div className="Neon Neon-theme-dragdropbox">
      <input
        style={{
          zIndex: 999,
          opacity: 0,
          width: 280,
          height: 180,
          position: "absolute",
          right: 0,
          left: 0,
          margin: "0 auto",
          top: 0,
          bottom: 0,
        }}
        name="files[]"
        id="filer_input2"
        multiple={false}
        type="file"
        onChange={e => handleFileChange(e)}
      />
      <div className="Neon-input-dragDrop">
        <div className="Neon-input-inner">
          <div className="Neon-input-icon">
            <IoMdCloudUpload />
            <div className="fs-3">
              Drop files here or
              <br /> click to upload
            </div>
          </div>
        </div>
      </div>
      {previewURL && (
        <div className="image-preview">
          <img src={previewURL?.image} alt="Preview" />
        </div>
      )}
    </div>
  )
}

export default ImageUpload

ImageUpload.propTypes = {
  selectedFile: PropTypes.instanceOf(File),
  setSelectedFile: PropTypes.func,
  previewURL: PropTypes.instanceOf(File),
  setPreviewURL: PropTypes.func,
}
