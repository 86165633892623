import React from "react"
import "react-modern-calendar-datepicker/lib/DatePicker.css"
import DatePicker from "react-modern-calendar-datepicker"
import useMoment from "Hooks/useMoment"
import PropTypes from "prop-types"
import moment from "moment"
import ModernDatepicker from "react-modern-datepicker"

function MyDatePicker({ selectedDate, setSelectedDate, label }) {
  const today = useMoment(Date.now(), "dateObject")
  const modifiedDate = selectedDate?.day
    ? selectedDate
    : useMoment(selectedDate, "dateObject")

  // const yearsAgo = useMoment(moment(Date.now()).subtract(6, 'year').toDate(), "dateObject")
  // const date = moment(Date.now()).format('DD-MM-YYYY')

  React.useEffect(() => {
    if (selectedDate === null) {
      setSelectedDate(today)
    }
  }, [selectedDate === null])

  return (
    <div className="date-picker-input">
      <p className="mb-1 pb-1 sm:text-white ">{label}</p>
      <DatePicker
        value={
          selectedDate == "Invalid date" || selectedDate == null
            ? today
            : modifiedDate
        }
        onChange={setSelectedDate}
        inputPlaceholder=" "
        shouldHighlightWeekends
        id="checkDate"
        calendarClassName="responsive-calendar"
      />
      {/* <ModernDatepicker
                date={selectedDate === null ? date : moment(selectedDate).format('DD-MM-YYYY')}
                format={'DD-MM-YYYY'}
                showBorder
                onChange={date => setSelectedDate(date)}
                placeholder={'Select a date'}
            /> */}
    </div>
  )
}

export default MyDatePicker

MyDatePicker.propTypes = {
  selectedDate: PropTypes.any,
  setSelectedDate: PropTypes.func,
  label: PropTypes.any,
}
