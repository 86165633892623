import {
  LOGIN_USER_REQUEST,
  LOGIN_USER_SUCCESS,
  LOGIN_USER_FAIL,
  USER_REGISTER_REQUEST,
  USER_REGISTER_SUCCESS,
  USER_REGISTER_FAIL,
  FORGET_PASSWORD,
  FORGET_PASSWORD_SUCCESS,
  FORGET_PASSWORD_ERROR,
  FORGET_NEW_PASSWORD,
  FORGET_NEW_PASSWORD_SUCCESS,
  FORGET_NEW_PASSWORD_ERROR,
  RESET_PASSWORD,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAIL,
} from "./actionTypes"

const initial_state = {
  error: "",
  loading: false,
  admin: [],
  studentRegister: {},
  forgetSuccessMsg: "",
  forgetSuccessOtp: "",
  forgetError: "",
  forgetPassOk: "",
  passwords:{}
}

const Login = (state = initial_state, action) => {
  switch (action.type) {
    case LOGIN_USER_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case LOGIN_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        admin: action.payload,
      }

    case LOGIN_USER_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }

    // Register //
    case USER_REGISTER_REQUEST:
      return {
        ...state,
        loading: true,
      }

    case USER_REGISTER_SUCCESS:
      return {
        ...state,
        loading: false,
        studentRegister: action.payload,
      }

    case USER_REGISTER_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }

    case FORGET_PASSWORD:
      return {
        ...state,
        forgetSuccessMsg: '',
        forgetError: '',
        loading: true,
      }
    case FORGET_PASSWORD_SUCCESS:
      return {
        ...state,
        forgetSuccessMsg: action.payload,
        loading: false,
      }
    case FORGET_PASSWORD_ERROR:
      return {
        ...state,
        forgetError: action.payload, loading: false
      }
    case FORGET_NEW_PASSWORD:
      return {
        ...state,
        loading: true,
      }
    case FORGET_NEW_PASSWORD_SUCCESS:
      return {
        ...state,
        forgetPassOk: action.payload,
        loading: false,
      }
    case FORGET_NEW_PASSWORD_ERROR:
      return {
        ...state,
        forgetError: action.payload, loading: false
      }

      case RESET_PASSWORD:
        return{
          ...state,
          loading:true
        }

        case RESET_PASSWORD_SUCCESS:
          return{
            ...state,
            loading:false,
            passwords:action.payload
          }

          case RESET_PASSWORD_FAIL:
            return{
              ...state,
              loading:false,
              error:action.payload
            }
    default:
      return state
  }
}

export default Login
