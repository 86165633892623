import MetaTag from "components/Common/Meta-tag"
import useWindow from "Hooks/UseWindow"
import StudentSingleView from "pages/Students/SingleView"
import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { useHistory } from "react-router-dom"
import { Container } from "reactstrap"
import AdminDashboard from "./admin"
import DashBoardLoad from "./student/elements/DashBoardLoad"

function index() {
  const history = useHistory()
  const { isMobile } = useWindow()
  const { studentProfile, loading } = useSelector(state => ({
    studentProfile: state?.StudentDashboard?.studentProfile,
    loading: state.StudentDetails.loading,
  }))

  const Role = localStorage.getItem("role")

  useEffect(() => {
    if (Role === "student") {
      if (
        studentProfile?.id &&
        studentProfile?.application_submitted === false
      ) {
        history.push({
          pathname: `/student/update/${studentProfile?.id}`,
        })
      } else {
        history.push("/dashboard")
      }
    }
  }, [Role, studentProfile?.application_submitted])

  const [customLoad, setCustomLoad] = useState(false)

  useEffect(() => {
    setCustomLoad(true)
    setTimeout(() => {
      setCustomLoad(false)
    }, 1800);
  }, [loading])


  return (
    <React.Fragment>
      <MetaTag title="Dashboard" />
      <Container fluid>
        {Role === "admin" && <AdminDashboard />}
        {Role === "student" &&
          <>
            {customLoad && isMobile ? <DashBoardLoad /> :
              <StudentSingleView isStudent={true} />}
          </>
        }
      </Container>
    </React.Fragment>
  )
}
export default index
