import { AvField } from "availity-reactstrap-validation"
import { map } from "lodash"
import React from "react"
import PropTypes from "prop-types"

import { Card, CardBody, Row, Table } from "reactstrap"

function Modules({ module }) {
  console.log(module)
  return (
    <CardBody>
      <Row className="">
        <div className="table-responsive">
          <Table className="table mb-0 table-bordered">
            <thead className="text-center">
              <tr>
                <th>
                  <i className="bx bx-eraser" /> #
                </th>
                <th>
                  <i className="bx bx-eraser" /> Qualification Name
                </th>
                <th>
                  <i className="bx bx-eraser" /> Specification / Major
                </th>
              </tr>
            </thead>
            <tbody className="text-center">
              {map(module, (mod, key) => (
                <tr key={key}>
                  <td>{key + 1}</td>
                  <td>
                    <AvField
                      style={{
                        fontWeight: "500",
                        fontSize: "14px",
                      }}
                      id="horizontal-username-Input"
                      className="border-0 placeholder-muted"
                      name={`specializations[${key}].title`}
                      // placeholder="enter subject"
                      type="text"
                      value={mod?.title}
                    />
                  </td>
                  <td>
                    <AvField
                      style={{
                        fontWeight: "500",
                        fontSize: "14px",
                      }}
                      id="horizontal-description-Input"
                      className="border-0 placeholder-muted"
                      name={`specializations[${key}].description`}
                      // placeholder="enter description"
                      type="text"
                      value={mod?.description}
                    />
                  </td>

                  {/* <td>
                      <AvField
                        style={{
                          fontWeight: "500",
                          fontSize: "14px",
                        }}
                        id="horizontal-username-Input"
                        className="border-0"
                        name={`specializations[${key}].status`}
                        type="select"
                        value={mod?.status}
                        onChange={e => console.log("title =>", e.target.value)}
                      >
                        <option>Select the status</option>
                        {statusRole?.map((role, key) => (
                          <option key={key} value={role.role}>
                            {role.name}
                          </option>
                        ))}
                      </AvField>
                    </td> */}
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </Row>
    </CardBody>
  )
}

export default Modules

Modules.propTypes = {
  module: PropTypes.any,
}

export const statusRole = [
  {
    name: "Pending",
    role: "pending",
  },
  {
    name: "Passed",
    role: "passed",
  },
  {
    name: "Not Assigned Yet",
    role: "not_assigned_yet",
  },
  {
    name: "On Going",
    role: "ongoing",
  },
  {
    name: "Failed",
    role: "failed",
  },
  {
    name: "Submitted",
    role: "submitted",
  },
  {
    name: "Not Selected",
    role: "not_selected",
  },
]
