import {
  GET_PROFILE_DETAILS,
  GET_PROFILE_DETAILS_FAIL,
  GET_PROFILE_DETAILS_SUCCESS,
  GET_VACENCY_DETAILS,
  GET_VACENCY_DETAILS_FAIL,
  GET_VACENCY_DETAILS_SUCCESS,
  SEARCH_JOB_VACCENCIES,
  SEARCH_JOB_VACCENCIES_FAIL,
  SEARCH_JOB_VACCENCIES_SUCCESS,
} from "./actionType"

// student profile //

export const getProfileDetails = () => ({
  type: GET_PROFILE_DETAILS,
})
export const getProfileDetailsSuccess = studentProfile => ({
  type: GET_PROFILE_DETAILS_SUCCESS,
  payload: studentProfile,
})
export const getProfileDetailsFail = error => ({
  type: GET_PROFILE_DETAILS_FAIL,
  payload: error,
})

// Search Vaccenies //

export const searchJobVacancies = () => ({
  type: SEARCH_JOB_VACCENCIES,
  payload: "",
})

export const searchJobVacanciesSuccess = () => ({
  type: SEARCH_JOB_VACCENCIES_SUCCESS,
  payload: "",
})

export const searchJobVacanciesFail = error => ({
  type: SEARCH_JOB_VACCENCIES_FAIL,
  payload: error,
})

// Vacency Details //

export const getVacancyDetails = () => ({
  type: GET_VACENCY_DETAILS,
  payload: "",
})

export const getVacancyDetailsSuccess = () => ({
  type: GET_VACENCY_DETAILS_SUCCESS,
  payload: "",
})

export const getVacancyDetailsFail = error => ({
  type: GET_VACENCY_DETAILS_FAIL,
  payload: error,
})
