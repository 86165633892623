import React from "react"
import { Badge, Col, Container, Input, Row } from "reactstrap"
import { useEffect, useState } from "react"
import { Card, CardBody, Media } from "reactstrap"
import { Link } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router-dom"
import PropTypes from "prop-types"
import FileSaver from "file-saver"

//componetns
import Breadcrumbs from "components/Common/Breadcrumb"
import MetaTag from "components/Common/Meta-tag"
import Carditem from "./elements/mini-widget"
import AttachedFiles from "./elements/attachedFiles"
import ModulesSpecifications from "./elements/Modules&Specifications"
import NoteScreen from "./elements/NoteScreen"
import {
  getProfileDetails,
  getStudentDetails,
  imageUpload,
  updateStudent,
} from "store/actions"

//Import Images
import { AvForm } from "availity-reactstrap-validation"

// icons
import { MdOutlineModeEditOutline, MdPayment } from "react-icons/md"
import DeleteModal from "./elements/DeleteModal"
import UpdateCareerIndrest from "./elements/CareerInterest"
import Vacancies from "pages/Vacancies/DatatableTables"
import { FaUpload, FaUser } from "react-icons/fa"
import { IMAGE_URL } from "helpers/api_methods"
import useWindow from "Hooks/UseWindow"
import MobileStudentProfile from "components/Mobile/MobileStudentProfile"
import MobileCardItems from "components/Mobile/MobileCardItems"
import MobileAttachedFiles from "components/Mobile/MobileAttachedFiles"
import MobileModulesSpecifications from "components/Mobile/MobileModulesSpecifications"
import MobileCareerIndrest from "components/Mobile/MobileCareerIndrest"
import YesOrNoQuestions from "../Crud/elements/YesOrNoQuestions"

//actions

const StudentSingleView = ({ isStudent }) => {
  const dispatch = useDispatch()
  const params = useParams()
  const { isMobile } = useWindow()
  const [delete_modal, setDelete_modal] = useState(false)

  const { singleData, studentProfile } = useSelector(state => ({
    singleData: state.StudentDetails.StudentsSingleview,
    studentProfile: state?.StudentDashboard?.studentProfile,
  }))

  const Role = localStorage.getItem("role")

  const handleStudentAdminData = () => {
    if (Role === "student") {
      return studentProfile
    } else return singleData
  }

  useEffect(() => {
    if (isStudent === true) {
      dispatch(getProfileDetails())
    } else {
      dispatch(getStudentDetails(params.id))
    }
  }, [dispatch, params.id])

  const handlePayment = () => {
    const finalData = {
      ...singleData,
      fees_paid: true,
    }
    dispatch(updateStudent(finalData, params.id, ""))
  }

  const onChangeHandleProfile = e => {
    const finalData = {
      profilepic: e.target.files,
    }
    dispatch(
      imageUpload(finalData, params?.id ? params?.id : studentProfile?.id)
    )
  }

  const Download = async FileUrl => {
    var myArray = FileUrl.split("://")
    var imageUrl = `${IMAGE_URL}${FileUrl}`

    if (myArray[0] == "http" || myArray[0] == "https") {
      imageUrl = `${FileUrl}`
    }

    const filename = myArray[0]?.split("/")
    saveAs(imageUrl, filename[filename?.length - 1])

    // var myArray = FileUrl.split("://")
    // console.log(myArray)
    // var image = `${IMAGE_URL}${FileUrl}/`
    // if (myArray[0] == "http" || myArray[0] == "https") {
    //   image = `${FileUrl}`
    // }
    // if (FileUrl) {
    //   FileSaver.saveAs(image, "image.jpg")
    // }
  }
  console.log("data :", handleStudentAdminData()?.profilepic)

  return (
    <>
      <DeleteModal
        delete_modal={delete_modal}
        setDelete_modal={setDelete_modal}
      />

      <MetaTag title="student view" />

      <div className="page-content mobile-student-view">
        {!isMobile && <Breadcrumbs title="Student" breadcrumbItem="Student" />}
        <Container
          fluid
          style={{
            height: isStudent ? "auto" : "70vh",
          }}
          className="sm:p-0"
        >
          <div className="container-fluid sm:p-0">
            <Row>
              <Col
                className={isStudent ? "" : "student-details-container"}
                lg={Role === "admin" ? 8 : 12}
              >
                <Row>
                  <Col lg={isStudent ? 4 : 6}>
                    {isMobile ? (
                      <MobileStudentProfile
                        onChangeHandleProfile={onChangeHandleProfile}
                      />
                    ) : (
                      <Card>
                        <CardBody>
                          <div className="media">
                            <div className="me-3 position-relative profile-images">
                              {isStudent ? (
                                <img
                                  src={`${IMAGE_URL}${
                                    handleStudentAdminData()?.profilepic
                                  }`}
                                  alt=""
                                  className="avatar-md rounded-circle img-thumbnail"
                                />
                              ) : (
                                <img
                                  src={`${
                                    handleStudentAdminData()?.profilepic
                                  }`}
                                  alt=""
                                  className="avatar-md rounded-circle img-thumbnail"
                                />
                              )}
                              <Link
                                to={"#"}
                                className="profile-download"
                                onClick={() =>
                                  Download(handleStudentAdminData()?.profilepic)
                                }
                              >
                                <div>
                                  <i className="bx bx-download h3 py-2 text-green"></i>
                                </div>
                              </Link>
                              <label
                                className="upload-icons m-0"
                                htmlFor="profilepic"
                              >
                                <FaUpload />
                              </label>
                              <Input
                                hidden
                                content
                                onChange={onChangeHandleProfile}
                                type="file"
                                name="profilepic"
                                id="profilepic"
                              />
                            </div>
                            <div className="media-body">
                              <Media>
                                <div className="media-body">
                                  <div className="text-muted">
                                    <h5 className="mb-1">
                                      {
                                        handleStudentAdminData()?.account
                                          ?.full_name
                                      }
                                    </h5>
                                    <p className="mb-0">
                                      {handleStudentAdminData()?.account?.phone}
                                    </p>
                                  </div>
                                </div>

                                <div className="d-flex flex-column">
                                  {handleStudentAdminData()?.fees_paid ===
                                  true ? (
                                    <Badge className="rounded bg-success font-size-10">
                                      {" "}
                                      Fee Paid
                                    </Badge>
                                  ) : (
                                    <Badge className="rounded bg-danger font-size-10">
                                      {" "}
                                      Unpaid{" "}
                                    </Badge>
                                  )}

                                  {Role === "admin" && (
                                    <>
                                      <Link
                                        className="btn btn-md"
                                        to="#"
                                        onClick={() => setDelete_modal(true)}
                                        title="Remove Profile"
                                      >
                                        <i
                                          className="fas fa-trash me-1"
                                          style={{
                                            fontSize: "10px",
                                          }}
                                        />{" "}
                                      </Link>
                                    </>
                                  )}
                                </div>
                              </Media>

                              <hr className="mb-2" />
                            </div>
                          </div>
                          <div className="d-flex align-items-start justify-content-between mt-3">
                            <Link
                              className="btn btn-md btn-light me-3 w-100"
                              to={{
                                pathname: `/student/update/${
                                  handleStudentAdminData()?.id
                                }`,
                                state: "update",
                              }}
                            >
                              <MdOutlineModeEditOutline
                                className="me-1"
                                style={{
                                  fontSize: "15px",
                                }}
                              />
                              Edit Profile
                            </Link>
                            {/* {Role === "admin" &&
                              singleData?.fees_paid === false && (
                                <Link
                                  className="btn btn-md btn-success w-100"
                                  to="#"
                                  onClick={handlePayment}
                                  title="Pay"
                                >
                                  <MdPayment size={"1rem"} className="mx-1" />{" "}
                                  PAY
                                </Link>
                              )} */}
                          </div>
                        </CardBody>
                      </Card>
                    )}

                    {isMobile ? (
                      <Card className="blog-stats-wid glass-card border-white">
                        <CardBody>
                          <div className="">
                            <div className="me-3 mb-3 d-flex align-items-center">
                              <div className="avatar-xs me-3">
                                <span className="avatar-title rounded-circle text-darkGreen bg-light bg-soft font-size-14">
                                  <FaUser />
                                </span>
                              </div>
                              <p className="mb-1 pb-0 text-darkGreen">
                                {" "}
                                Personal Info
                              </p>
                            </div>
                            <div>
                              {handleStudentAdminData()?.address && (
                                <div className="mb-2 d-flex flex-column align-items-start justify-content-satrt mb-2 w-100">
                                  <p
                                    style={{ width: 80, opacity: 0.8 }}
                                    className="text-white mb-0 sp-heading"
                                  >
                                    Address
                                  </p>
                                  <p className="mb-0 text-black text-start flex-1 sp-des">
                                    {handleStudentAdminData()?.address}{" "}
                                  </p>
                                </div>
                              )}
                              {handleStudentAdminData()?.account?.email && (
                                <div className="mb-3 d-flex flex-column align-items-start justify-content-satrt mb-2 w-100">
                                  <p
                                    style={{ width: 80, opacity: 0.8 }}
                                    className="text-white mb-0 sp-heading"
                                  >
                                    Email
                                  </p>
                                  <p className="mb-0 text-black text-start flex-1 sp-des">
                                    {handleStudentAdminData()?.account?.email}{" "}
                                  </p>
                                </div>
                              )}
                              {handleStudentAdminData()?.dob && (
                                <div className="d-flex flex-column align-items-start justify-content-satrt mb-2 w-100">
                                  <p
                                    style={{ opacity: 0.8 }}
                                    className="text-white  mb-0  sp-heading "
                                  >
                                    Birth Date
                                  </p>
                                  <p className="mb-0 text-start text-black flex-1 sp-des">
                                    {handleStudentAdminData()?.dob}
                                  </p>
                                </div>
                              )}
                            </div>
                          </div>
                        </CardBody>
                      </Card>
                    ) : (
                      <Card className="blog-stats-wid">
                        <CardBody>
                          <div className="">
                            <div className="me-3 mb-3 d-flex align-items-center">
                              <div className="avatar-xs me-3">
                                <span className="avatar-title rounded-circle bg-primary bg-soft text-primary font-size-14">
                                  <FaUser />
                                </span>
                              </div>
                              <p className="mb-1 pb-0"> Personal Info</p>
                            </div>
                            <div>
                              <div className="d-flex align-items-start justify-content-satrt mb-2 w-100">
                                <p
                                  style={{ width: 80 }}
                                  className="text-muted mb-0  "
                                >
                                  First Name :
                                </p>
                                <p className="mb-0 text-start flex-1 ms-1">
                                  {handleStudentAdminData()?.account?.full_name}{" "}
                                </p>
                              </div>
                              {/* <div className="d-flex align-items-start justify-content-satrt mb-2 w-100">
                                <p
                                  style={{ width: 80 }}
                                  className="text-muted mb-0 "
                                >
                                  Address :
                                </p>
                                <p className="mb-0 text-start flex-1 ms-1">
                                  {handleStudentAdminData()?.address}{" "}
                                </p>
                              </div> */}
                              <div className="d-flex align-items-start justify-content-satrt mb-2 w-100">
                                <p
                                  style={{ width: 80 }}
                                  className="text-muted mb-0  "
                                >
                                  Email :
                                </p>
                                <p className="mb-0 text-start flex-1 ms-1">
                                  {handleStudentAdminData()?.account?.email}{" "}
                                </p>
                              </div>
                              <div className="d-flex align-items-start justify-content-satrt mb-2 w-100">
                                <p
                                  style={{ width: 80 }}
                                  className="text-muted mb-0  "
                                >
                                  Contact :
                                </p>
                                <p className="mb-0 text-start flex-1 ms-1">
                                  {handleStudentAdminData()?.account?.phone}{" "}
                                </p>
                              </div>
                              <div className="d-flex align-items-start justify-content-satrt mb-2 w-100">
                                <p
                                  style={{ width: 80 }}
                                  className="text-muted mb-0 "
                                >
                                  Current Location :
                                </p>
                                <p className="mb-0 text-start flex-1 ms-1">
                                  {handleStudentAdminData()?.current_location}{" "}
                                </p>
                              </div>
                              <div className="d-flex align-items-start justify-content-satrt mb-2 w-100">
                                <p
                                  style={{ width: 80 }}
                                  className="text-muted mb-0  "
                                >
                                  Birth Date :
                                </p>
                                <p className="mb-0 text-start flex-1 ms-1">
                                  {handleStudentAdminData()?.dob}
                                </p>
                              </div>
                            </div>
                          </div>
                        </CardBody>
                      </Card>
                    )}

                    {/* // YesOrNoQuestions */}
                    {isMobile && (
                      <Card className="blog-stats-wid glass-card border-white">
                        <CardBody>
                          <div className="">
                            <div className="text-muted font-size-12 w-100">
                              <YesOrNoQuestions
                                content="Do you have any work experience ?"
                                fullWidth
                                sate={
                                  handleStudentAdminData()
                                    ?.is_previous_experience
                                }
                              />
                              <YesOrNoQuestions
                                content="Do you have any previous work experience that is relevant to your qualifications ?"
                                fullWidth
                                sate={
                                  handleStudentAdminData()?.is_work_experience
                                }
                              />
                              <YesOrNoQuestions
                                content="Are you confident in your workplace skills for the job roles ?"
                                fullWidth
                                sate={
                                  handleStudentAdminData()
                                    ?.is_confident_workplace_skills
                                }
                              />
                              <YesOrNoQuestions
                                content="Have you ever worked on any academic projects ?"
                                fullWidth
                                sate={
                                  handleStudentAdminData()
                                    ?.has_academic_projects
                                }
                              />
                              <YesOrNoQuestions
                                content="Have you ever been a part of any work-related projects ?"
                                fullWidth
                                sate={
                                  handleStudentAdminData()
                                    ?.is_include_work_project
                                }
                              />
                              <YesOrNoQuestions
                                content="Do you have any prior internship experience ?"
                                fullWidth
                                sate={
                                  handleStudentAdminData()
                                    ?.has_internship_experience
                                }
                              />
                              {/* <p className="mb-1">
                    <Row>
                      <Col sm="9">
                        previous course did not offer any internship or
                        placement opportunities.
                      </Col>
                      <Col sm="3">
                        {" "}
                        :{" "}
                        <span>
                          {" "}
                          <Badge className="rounded bg-success font-size-10 px-2">
                            {CardData?.is_no_internship ? "Yes" : "No"}
                          </Badge>
                        </span>
                      </Col>
                    </Row>
                  </p>
                  <p className="mb-1 mt-3">
                    <Row>
                      <Col sm="9">
                        interested in doing an internship or placement
                      </Col>
                      <Col sm="3">
                        {" "}
                        :{" "}
                        <span>
                          <Badge className="rounded bg-success font-size-10 px-2">
                            {CardData?.is_internship_interested ? "Yes" : "No"}
                          </Badge>
                        </span>
                      </Col>
                    </Row>
                  </p> */}
                            </div>
                          </div>
                        </CardBody>
                      </Card>
                    )}

                    {isMobile && isStudent ? (
                      <>
                        <MobileCardItems />
                      </>
                    ) : (
                      <>
                        {isStudent ? (
                          <Carditem CardData={handleStudentAdminData()} />
                        ) : (
                          <Carditem
                            CardData={handleStudentAdminData()}
                            showLast={true}
                          />
                        )}
                      </>
                    )}
                  </Col>

                  {!isStudent && (
                    <Col lg={6}>
                      <Carditem
                        hideLast={true}
                        CardData={handleStudentAdminData()}
                      />
                    </Col>
                  )}

                  <Col lg={isStudent ? 8 : 12}>
                    {isMobile ? (
                      <MobileAttachedFiles />
                    ) : (
                      <AttachedFiles CardData={handleStudentAdminData()} />
                    )}

                    {isMobile ? (
                      <MobileModulesSpecifications />
                    ) : (
                      <ModulesSpecifications
                        CardData={handleStudentAdminData()}
                      />
                    )}

                    {isMobile ? (
                      <MobileCareerIndrest />
                    ) : (
                      <Card className="pb-0">
                        <CardBody>
                          <AvForm className="d-flex align-item-center justify-content-center">
                            <UpdateCareerIndrest
                              isReadOnly={true}
                              CardData={handleStudentAdminData()}
                            />
                          </AvForm>
                        </CardBody>
                      </Card>
                    )}
                  </Col>
                </Row>
                {!isMobile && (
                  <Row>
                    <Vacancies isInner={true} />
                  </Row>
                )}
              </Col>

              {Role == "admin" ? (
                <Col lg={4}>
                  <NoteScreen />
                </Col>
              ) : (
                <></>
              )}
            </Row>
          </div>
        </Container>
      </div>
    </>
  )
}

export default StudentSingleView

StudentSingleView.propTypes = {
  isStudent: PropTypes.bool,
}
// const CardData = [
//   {
//     icon: "bx bx-copy-alt",
//     title: "Orders",
//     value: "1,452",
//     badgeValue: "+ 0.2%",
//     color: "success",
//     desc: "From previous period",
//   },
//   {
//     icon: "bx bx-archive-in",
//     title: "Revenue",
//     value: "$ 28,452",
//     badgeValue: "+ 0.2%",
//     color: "success",
//     desc: "From previous period",
//   },
//   {
//     icon: "bx bx-purchase-tag-alt",
//     title: "Average Price",
//     value: "$ 16.2",
//     badgeValue: "0%",
//     color: "warning",
//     desc: "From previous period",
//   },
//   {
//     icon: "bx bx-purchase-tag-alt",
//     title: "Average Price",
//     value: "$ 16.2",
//     badgeValue: "0%",
//     color: "warning",
//     desc: "From previous period",
//   },
//   {
//     icon: "bx bx-purchase-tag-alt",
//     title: "Average Price",
//     value: "$ 16.2",
//     badgeValue: "0%",
//     color: "warning",
//     desc: "From previous period",
//   },
//   {
//     icon: "bx bx-purchase-tag-alt",
//     title: "Average Price",
//     value: "$ 16.2",
//     badgeValue: "0%",
//     color: "warning",
//     desc: "From previous period",
//   },
// ]

const careerInterests = [
  {
    data: [
      {
        icon: "bx bxs-notepad",
        status: "Orderd",
        date: "15 Mar",
        desc: "New common language will be more simple and regular than the existing.",
      },
      {
        date: "14 Mar",
        status: "Packed",
        icon: "bx bx-package",
        desc: "To achieve this, it would be necessary to have uniform grammar.",
      },
      {
        date: "13 Mar",
        status: "Shipped",
        icon: "bx bxs-truck",
        desc: "It will be as simple ",
      },
    ],
  },
  {
    data: [
      {
        icon: "bx bxs-notepad",
        status: "Orderd",
        date: "15 Mar",
        desc: "New common language will be more simple and regular than the existing.",
      },
      {
        date: "14 Mar",
        status: "Rashid",
        icon: "bx bx-package",
        desc: "To achieve this, it would be necessary to have uniform grammar.",
      },
      {
        date: "13 Mar",
        status: "Shipped",
        icon: "bx bxs-truck",
        desc: "It will be as simple ",
      },
    ],
  },
  {
    data: [
      {
        icon: "bx bxs-notepad",
        status: "Orderd",
        date: "15 Mar",
        desc: "New common language will be more simple and regular than the existing.",
      },
      {
        date: "14 Mar",
        status: "Packed",
        icon: "bx bx-package",
        desc: "To achieve this, it would be necessary to have uniform grammar.",
      },
      {
        date: "13 Mar",
        status: "Shipped",
        icon: "bx bxs-truck",
        desc: "It will be as simple ",
      },
    ],
  },
]
