import { AvField, AvForm } from "availity-reactstrap-validation"
import React, { useState } from "react"
import { Link, useHistory } from "react-router-dom"
import { Alert, Button, Card, CardBody, Col, Container, Row } from "reactstrap"
import logo from "assets/images/logo/logo.png"
import { useDispatch, useSelector } from "react-redux"
import { userRegisterRequest } from "store/actions"
import useWindow from "Hooks/UseWindow"
import { IoIosArrowBack } from "react-icons/io"
import loginBg from "assets/images/other/register.png"

function Register() {
  const [showPass, setShowPass] = useState(false)
  const dispatch = useDispatch()
  const history = useHistory()
  const { loading, error } = useSelector(state => ({
    loading: state.Login.loading,
    error: state.Login.error,
  }))

  const handleRegister = (e, studentRegister) => {
    dispatch(userRegisterRequest(studentRegister, history))
  }

  const { isMobile } = useWindow()

  return (
    <div>
      {isMobile ? <>
        <div className="mobile p-3">
          <div className="d-flex justify-content-between align-items-center">
            <Link to="/login">
              <IoIosArrowBack size={'1.2rem'} className="text-darkGreen" style={{ cursor: "pointer" }} />
            </Link>
            <div><img alt="logo" className="rounded" height={20} width={80} src={logo} /></div>
          </div>
          <div className='mt-5 '>
            <p className='text-center text-darkGreen font-size-22 mb-4 mt-3'
              style={{ fontWeight: "500" }}

            >Register</p>
            <div className='d-flex w-100 justify-content-center align-items-center'>
              <img src={loginBg} alt="" style={{ height: 120 }} />
            </div>

            <div className="p-2">
              <AvForm
                onValidSubmit={(e, v) => {
                  handleRegister(e, v)
                }}
              >
                <div className="mb-3">
                  <AvField
                    name="email"
                    label="Email"
                    className="form-control sm:input"
                    placeholder="Enter email"
                    type="email"
                    required
                  />
                </div>

                <div className="mb-3">
                  <AvField
                    name="full_name"
                    label=" Full Name"
                    type="text"
                    required
                    placeholder="Enter Name"
                    className="sm:input"
                  />
                </div>

                <div className="mb-3">
                  <AvField
                    name="phone"
                    label="Contact Number"
                    type="number"
                    className="sm:input"
                    required
                    placeholder="Enter Contact"
                  />
                </div>
                <div className="mb-3 position-relative">
                  <AvField
                    name="password"
                    label=" Password"
                    type={showPass ? "text" : "password"}
                    className="sm:input"
                    required
                    placeholder="Enter Password"
                  />
                  <div
                    className="w-fit rounded position-absolute d-flex p-2 bg-light align-items-center justify-content-center"
                    style={{
                      right: "1px",
                      bottom: "1px",
                      height: "35px",
                      width: "40px",
                      cursor: "pointer",
                    }}
                    onClick={() => setShowPass(!showPass)}
                  >
                    {showPass ? (
                      <i className="far fa-eye-slash mx-1"></i>
                    ) : (
                      <i className="far fa-eye mx-1"></i>
                    )}
                  </div>
                </div>

                <div
                  style={{
                    display: "flex",
                    alignItems: "flex-end",
                    justifyContent: "unset"
                  }}
                  className="mt-3 d-grid "
                >
                  <Button className="btn btn-success w-100" type="submit">
                    {loading && (
                      <>
                        <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                      </>
                    )}
                    Register
                  </Button>
                </div>

                <div className="mt-4 text-center">
                  You have Already account ?
                  <Link to="/login" className="">
                    Login
                  </Link>
                </div>
              </AvForm>
            </div>
          </div>
        </div>
      </> :
        <Container className="mt-5">
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden shadow-rounded">
                <CardBody className="pt-0">
                  <div className=" d-flex justify-content-center aling-items-center pt-4 h-100">
                    <div className="" style={{ height: 70, width: 130 }}>
                      <span className="avatar-title  bg-white w-100 h-100">
                        <img alt="logo" className="" src={logo} />
                      </span>
                    </div>
                  </div>
                  <h3 className="p-2 font-size-16 text-center">Register Now</h3>
                  {error?.data?.email[0] && <Alert color="danger" >{error?.data?.email[0]}</Alert>}
                  <div className="p-2">
                    <AvForm
                      onValidSubmit={(e, v) => {
                        handleRegister(e, v)
                      }}
                    >
                      <div className="mb-3">
                        <AvField
                          name="email"
                          label="Email"
                          className="form-control"
                          placeholder="Enter email"
                          type="email"
                          required
                        />
                      </div>

                      <div className="mb-3">
                        <AvField
                          name="full_name"
                          label=" Full Name"
                          type="text"
                          required
                          placeholder="Enter Name"
                        />
                      </div>

                      <div className="mb-3">
                        <AvField
                          name="phone"
                          label="Contact Number"
                          type="number"
                          required
                          placeholder="Enter Contact"
                        />
                      </div>
                      <div className="mb-3 position-relative">
                        <AvField
                          name="password"
                          label=" Password"
                          type={showPass ? "text" : "password"}
                          required
                          placeholder="Enter Password"
                        />
                        <div
                          className="w-fit rounded position-absolute d-flex p-2 bg-light align-items-center justify-content-center"
                          style={{
                            right: "1px",
                            bottom: "1px",
                            height: "35px",
                            width: "40px",
                            cursor: "pointer",
                          }}
                          onClick={() => setShowPass(!showPass)}
                        >
                          {showPass ? (
                            <i className="far fa-eye-slash mx-1"></i>
                          ) : (
                            <i className="far fa-eye mx-1"></i>
                          )}
                        </div>
                      </div>

                      <div
                        style={{
                          display: "flex",
                          alignItems: "flex-end",
                          justifyContent: "flex-end",
                        }}
                        className="mt-3 d-grid "
                      >
                        <Button className="btn btn-success" type="submit">
                          {loading && (
                            <>
                              <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                            </>
                          )}
                          Register
                        </Button>
                      </div>

                      <div className="mt-4 text-center">
                        You have Already account ?
                        <Link to="/login" className="">
                          Login
                        </Link>
                      </div>
                    </AvForm>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>
                  © {new Date().getFullYear()} Career Pro | All Right Issued |
                  Crafted with <i className="mdi mdi-heart text-danger" /> by{" "}
                  <a href="https://osperb.com/"> Osperb</a>
                </p>
              </div>
            </Col>
          </Row>
        </Container>}
    </div>
  )
}

export default Register
