import { all, fork } from "redux-saga/effects"
import loginSaaga from "./auth/login/saga"

//public
import LayoutSaga from "./layout/saga"
import studentDashboard from "./studentDashboard/saga"
import ShopsSaga from "./students/saga"
import ChatsSaga from "./chat/saga"

export default function* rootSaga() {
  yield all([
    //public
    fork(LayoutSaga),
    fork(loginSaaga),
    fork(ShopsSaga),
    fork(studentDashboard),
    fork(ChatsSaga),
  ])
}
