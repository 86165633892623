import {
  LOGIN_USER_FAIL,
  LOGIN_USER_REQUEST,
  LOGIN_USER_SUCCESS,
  USER_REGISTER_FAIL,
  USER_REGISTER_REQUEST,
  USER_REGISTER_SUCCESS,
  VERIFICATION_FAIL,
  VERIFICATION_REQUEST,
  VERIFICATION_SUCCESS,
  FORGET_PASSWORD,
  FORGET_PASSWORD_SUCCESS,
  FORGET_PASSWORD_ERROR,
  FORGET_NEW_PASSWORD,
  FORGET_NEW_PASSWORD_SUCCESS,
  FORGET_NEW_PASSWORD_ERROR,
  RESET_PASSWORD,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAIL,
  RESEND_OTP,
  RESEND_OTP_SUCCESS,
  RESEND_OTP_FAIL,
} from "./actionTypes"

// Login  //
export const userLoginRequest = (input, history) => (
  console.log(input),
  {
    type: LOGIN_USER_REQUEST,
    payload: { input, history },
  }
)

export const userLoginSuccess = input => ({
  type: LOGIN_USER_SUCCESS,
  payload: input,
})
export const userLoginFail = error => ({
  type: LOGIN_USER_FAIL,
  payload: error,
})

// Register  //
export const userRegisterRequest = (studentRegister, history) => (
  console.log(history),
  {
    type: USER_REGISTER_REQUEST,
    payload: { studentRegister, history },
  }
)

export const userRegisterSuccess = studentRegister => (
  console.log(studentRegister),
  {
    type: USER_REGISTER_SUCCESS,
    payload: studentRegister,
  }
)

export const userRegisterFail = error => ({
  type: USER_REGISTER_FAIL,
  payload: error,
})

export const verificationRequest = (data, history) => (
  console.log(history),
  {
    type: VERIFICATION_REQUEST,
    payload: { data, history },
  }
)

export const verificationSuccess = data => (
  console.log(data),
  {
    type: VERIFICATION_SUCCESS,
    payload: data,
  }
)

export const verificationFail = error => ({
  type: VERIFICATION_FAIL,
  payload: error,
})

export const userForgetPassword = (email, history) => (
  console.log(history, "ksjdfnkjsdfn"),
  {
    type: FORGET_PASSWORD,
    payload: { email, history },
  }
)

export const userForgetPasswordSuccess = message => ({
  type: FORGET_PASSWORD_SUCCESS,
  payload: message,
})

export const userForgetPasswordError = message => ({
  type: FORGET_PASSWORD_ERROR,
  payload: message,
})

export const userForgetNewPassword = (pass, history) => {
  return {
    type: FORGET_NEW_PASSWORD,
    payload: { pass, history },
  }
}

export const userForgetNewPasswordSuccess = message => {
  return {
    type: FORGET_NEW_PASSWORD_SUCCESS,
    payload: message,
  }
}

export const userForgetNewPasswordError = message => {
  return {
    type: FORGET_NEW_PASSWORD_ERROR,
    payload: message,
  }
}


export const resetPassword = (value,resetId,history)=> ({
  type:RESET_PASSWORD,
  payload:{value,resetId,history}
})

export const resetPasswordSuccess = (passwords)=> ({
  type:RESET_PASSWORD_SUCCESS,
  payload:passwords
})

export const resetPasswordFail = (error)=> ({
  type:RESET_PASSWORD_FAIL,
  payload:error
})


export const resendOtp = (value,history)=> ({
  type:RESEND_OTP,
  payload:{value,history}
})

export const resendOtpSuccess = (message)=> ({
  type:RESEND_OTP_SUCCESS,
  payload:message
})

export const resendOtpFail = (error)=> ({
  type:RESEND_OTP_FAIL,
  payload:error
})