import React, { useEffect, useState } from "react"
import {
  Row,
  Col,
  Card,
  CardBody,
  Spinner,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap"
import { Link, useHistory, useLocation } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { map, range } from "lodash"

// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next"
import ToolkitProvider from "react-bootstrap-table2-toolkit"

//actions

import "assets/scss/datatables.scss"
import MyPagination from "components/Common/MyPagination"
import { AvForm } from "availity-reactstrap-validation"
import { getStudents } from "store/actions"
import classnames from "classnames"

const DataTable = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const location = useLocation()

  const [searchText, setSearchText] = useState("")
  const [activeTab, setactiveTab] = useState("1")
  const [tabChange, setTabChange] = useState("")

  const { student } = useSelector(state => ({
    student: state.StudentDetails.student,
  }))
  console.log(student?.full_name)
  const loading = false

  const settings = set => {
    if (tabChange !== set) {
      setTabChange(set)
      console.log(set, "dfg")
    }
  }

  const [page, setPage] = useState(1)
  const totalPages = Math.ceil(student && student?.count / 10)
  const pages = range(1, totalPages + 1)

  useEffect(() => {
    dispatch(getStudents(tabChange, searchText, page))
  }, [dispatch, tabChange, page, searchText])

  useEffect(() => {
    history.push({
      pathname: location.pathname,
      search: `p=${1}`,
      state: {
        page: 1,
      },
    })
  }, [tabChange])

  const columns = [
    {
      dataField: "full_name",
      text: "Name",
      sort: true,
    },
    {
      dataField: "email",
      text: "Email",
    },
    {
      dataField: "application_submitted",
      text: "Application",
    },
    {
      dataField: "fees_paid",
      text: "Fee Paid",
    },

    {
      dataField: "action",
      text: "Action",
    },
  ]

  const studentsData = map(student?.results, (item, index) => ({
    ...item,
    key: index,
    full_name: item?.account?.full_name,
    email: item?.account?.email,
    application_submitted:
      item.application_submitted === true ? `Submitted` : `Not Submitted`,

    fees_paid:
      item.fees_paid === true ? (
        <b className="text-success">Paid</b>
      ) : (
        <b className="text-danger">Not Paid</b>
      ),

    action: (
      <div>
        <Link to={`/student/${item?.id}`} className="btn-light btn-sm">
          View
        </Link>
      </div>
    ),
  }))

  const defaultSorted = [
    {
      dataField: "id",
      order: "asc",
    },
  ]

  // Select All Button operation
  const selectRow = {
    mode: "checkbox",
  }

  const handleSearch = e => {
    e.preventDefault()
    setSearchText(e.target.value)
  }

  console.log("title =>", totalPages)
  console.log("title =>", page)
  console.log("data :", pages)

  return (
    <React.Fragment>
      <Row>
        <Col className="col-12">
          <Card>
            <CardBody>
              <Nav pills>
                <NavItem>
                  <NavLink
                    style={{
                      cursor: "pointer",
                    }}
                    className={classnames({
                      active: tabChange === "",
                    })}
                    onClick={() => {
                      settings("")
                    }}
                  >
                    All Students
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      active: tabChange === "true",
                    })}
                    onClick={() => {
                      settings("true")
                    }}
                  >
                    Active Students
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      active: tabChange === "false",
                    })}
                    onClick={() => {
                      settings("false")
                    }}
                  >
                    Pending Students
                  </NavLink>
                </NavItem>
              </Nav>

              <ToolkitProvider
                keyField="id"
                columns={columns}
                data={studentsData}
                search
              >
                {toolkitProps => (
                  <React.Fragment>
                    <Row className="mb-2">
                      <Col md="4">
                        <div className="search-box me-2 mb-2 d-inline-block">
                          <div className="position-relative">
                            <AvForm
                              className="app-search d-lg-block"
                              onChange={e => handleSearch(e)}
                            >
                              <div className="position-relative">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Search..."
                                  defaultValue={searchText}
                                />
                                <span className="bx bx-search-alt" />
                              </div>
                            </AvForm>
                          </div>
                        </div>
                      </Col>
                    </Row>
                    {loading ? (
                      <Spinner color="secondary" className="d-block m-auto" />
                    ) : (
                      <>
                        <Row>
                          <Col xl="12">
                            <div className="table-responsive ">
                              <BootstrapTable
                                keyField={"id"}
                                responsive
                                bordered={false}
                                striped={false}
                                defaultSorted={defaultSorted}
                                selectRow={selectRow}
                                classes={"table align-middle table-nowrap "}
                                headerWrapperClasses={"thead-light"}
                                {...toolkitProps.baseProps}
                              />
                            </div>
                          </Col>
                        </Row>
                        <MyPagination
                          totalPages={pages}
                          page={page}
                          setPage={setPage}
                        />
                      </>
                    )}
                  </React.Fragment>
                )}
              </ToolkitProvider>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default DataTable
