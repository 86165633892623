import React, { useEffect } from "react"
import { Card, CardBody, Col, Container, Row } from "reactstrap"
import { Link, useHistory } from "react-router-dom"
import Slider from "react-slick"

// Import css files
import "./frontpage.css"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

import FooterCustom from "./FooterCustom"

//screenshorts
import {
  shot1,
  shot2,
  shot3,
  shot4,
  shot5,
  shot6,
} from "assets/images/screenShot"
import {
  cover_letter,
  curriculum,
  linkedinIcon,
  linkein,
  home_banner,
} from "assets/images/other"

import { logo } from "assets/images/logo"
import MetaTag from "components/Common/Meta-tag"
import useWindow from "Hooks/UseWindow"

function LandingPage() {
  const history = useHistory()
  const { isMobile } = useWindow()

  const settings = {
    dots: false,
    infinite: true,
    autoplay: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    draggable: true,
  }

  // useEffect(() => {
  //   if (isMobile) {
  //     history.push('/login')
  //   }
  // }, [isMobile])

  return (
    <>
      <React.Fragment>
        <MetaTag title="Welcome!" />

        <Row className="gutter_zero  fullkit">
          <img id="mainBgImg" src={home_banner} />

          <nav
            id="navbar-transparent"
            className="navbar navbar-expand-lg navbar-light bg-light"
          >
            <div className="navbar-collapse h-full" id="navbarButtonsExample">
              <Row className="gutter_zero marg-right h-full w-full">
                <Container className="h-full">
                  <Col className="col-lg-12 col-md-12 col-sm-12 h-full d-flex justify-content-between">
                    <div className="d-flex align-items-center">
                      <Link className="btn text-light me-3">
                        <img src={logo} width={"200"} height={"31"}
                        />
                      </Link>
                    </div>
                    <div className="d-flex align-items-center">
                      <Col className="nav-sizing buttonNewWrap">
                        <Link
                          to="/register"
                          type="button"
                          className="btn whitespace btn-outline-info text-adjust"
                        >
                          Create Account
                        </Link>
                        <Link
                          to="/login"
                          className="btn whitespace btn-light px-3 font-weight-bold-sign text-info text-adjust"
                          role="button"
                        >
                          Sign In
                        </Link>
                      </Col>
                    </div>
                  </Col>
                </Container>
              </Row>
            </div>
          </nav>
          <div className="my-gradiant">
            <Container className="pb-4">
              <Row className="gutter_zero py-4 main-small-heading">
                <h2 className="text-light">&nbsp;</h2>
              </Row>
              <Row className="gutter_zero mainQuotes">
                <h2 className="text-light mx-2 mb-0">
                  INTERNSHIP & PLACEMENT PORTAL
                </h2>
                <h1 className="text-light">CONNECTING YOUR TALENT</h1>
              </Row>
              <Row>
                <Col className="col-lg-12 col-md-12 col-sm-12 d-flex justify-content-between main-sub-heading">
                  <h3 className="text-light mx-3">
                    Sign up to start your internship / Placement application
                  </h3>
                  <Link
                    to="/register"
                    className="text-light btn btn-primary main-bottom-btn"
                  >
                    Sign Up
                  </Link>
                </Col>
              </Row>
            </Container>
          </div>
        </Row>
        <Row
          className="gutter_zero splitter fullkit md:flxe-col"
          style={{
            maxWidth: "1600px",
            margin: "auto",
          }}
        >
          <Col className="col-12 col-md-6 md:w-full">
            <Card className="imgA1">
              <CardBody>
                <Container className="">
                  <h1 className="cstm-txt text-primary text-big pb-4">
                    Apply To Top <br /> UK Companies
                    <br /> With Your <br /> Pro Profile
                  </h1>
                  <Col className="col-12 d-flex">
                    <div className="marginLg">
                      <img
                        className="base-logos base-logos-first"
                        src={linkein}
                        alt="linkedin"
                      />
                    </div>
                    <div className="marginLg">
                      <img
                        className="base-logos base-logos-second"
                        src={curriculum}
                        alt="curriculum vitae"
                      />
                    </div>
                    <div className="marginMd">
                      <img
                        className="base-logos base-logos-third"
                        src={cover_letter}
                        alt="cover letter"
                      />
                    </div>
                  </Col>
                </Container>
              </CardBody>
            </Card>
          </Col>
          <Col className="col-12 col-md-6 md:w-full">
            <img height="750px" src={linkedinIcon} alt="img" />
          </Col>
        </Row>
        <Row className="gutter_zero carausalCard">
          <Container className="resizer">
            <div className="rcontainer">
              <Slider {...settings}>
                <div className="slide_img" id="one">
                  <img src={shot1} />
                </div>

                <div className="slide_img" id="two">
                  <img src={shot2} />
                </div>

                <div className="slide_img" id="three">
                  <img src={shot3} />
                </div>

                <div className="slide_img" id="four">
                  <img src={shot4} />
                </div>

                <div className="slide_img" id="five">
                  <img src={shot5} />
                </div>
                <div className="slide_img" id="five">
                  <img src={shot6} />
                </div>
              </Slider>
            </div>
          </Container>
        </Row>
        <FooterCustom />
      </React.Fragment>
    </>
  )
}

export default LandingPage
