import React from "react"
import { Card, CardBody, Col, Container, Row } from "reactstrap"
import { AvForm, AvField } from "availity-reactstrap-validation"
import { Link, useHistory } from "react-router-dom"
import logo from "assets/images/logo/logo.png"
import MetaTag from "components/Common/Meta-tag"
import useWindow from "Hooks/UseWindow"
import { IoIosArrowBack } from "react-icons/io"
import loginBg from "assets/images/other/login-bg.png"
import { useDispatch } from "react-redux"
import { userForgetPassword } from "store/actions"

function ForgetPassword() {
  const { isMobile } = useWindow()
  const history = useHistory()
  const dispatch = useDispatch()

  const handleForget = (e, v) => {
    dispatch(userForgetPassword(v, history))
  }

  // const handleForgetW = (e, v) => {
  //   dispatch(userForgetPassword(v, history))
  // }

  return (
    <div>
      <React.Fragment>
        <MetaTag title=" forget password" />
        {isMobile ? (
          <>
            <div className="mobile p-3">
              <div className="d-flex justify-content-between align-items-center">
                <IoIosArrowBack
                  onClick={() => history.push("/login")}
                  size={"1.2rem"}
                  className="text-darkGreen"
                  style={{ cursor: "pointer" }}
                />
                <div>
                  <img
                    alt="logo"
                    className="rounded"
                    height={20}
                    width={80}
                    src={logo}
                  />
                </div>
              </div>
              <div className="mt-5">
                <p
                  className="text-center text-darkGreen font-size-22 mb-2 mt-3"
                  style={{ fontWeight: "500" }}
                >
                  Forget Password
                </p>
                <p className="text-center font-size-12">
                  No worries,We will send you reset instrections
                </p>
                <div className="d-flex w-100 justify-content-evenly align-items-center">
                  <div></div>
                  <img src={loginBg} alt="" style={{ height: 200 }} />
                </div>

                <div className="p-2">
                  <AvForm
                    onValidSubmit={(e, v) => {
                      handleForget(e, v)
                    }}
                  >
                    <div className="mb-3">
                      <AvField
                        name="email"
                        label="Email"
                        className="form-control"
                        placeholder="Enter email"
                        type="email"
                        required
                      />
                    </div>
                    <div className="d-flex justify-content-end">
                      <button
                        type="submit"
                        className="btn btn-success  mt-1 w-100 rounded"
                      >
                        send
                      </button>
                    </div>
                  </AvForm>
                </div>
              </div>
            </div>
          </>
        ) : (
          <Container
            style={{ height: "100vh" }}
            className="p-5 d-flex justify-content-center align-items-center"
          >
            <Row className="justify-content-center w-100">
              <Col md={8} lg={6} xl={5}>
                <Card className="overflow-hidden shadow-rounded p-4">
                  <CardBody className="pt-0">
                    <div className=" d-flex justify-content-center aling-items-center ">
                      <div className="avatar-md profile-user-wid">
                        <span className="avatar-title bg-white">
                          <img src={logo} alt="" height="90" />
                        </span>
                      </div>
                    </div>
                    <div>
                      <h6 className="d-flex justify-content-center ">
                        Forget Your Password
                      </h6>
                      <p className="text-center font-size-12">
                        No worries,We will send you reset instrections
                      </p>
                    </div>
                    <div className="p-2">
                      <AvForm
                        onValidSubmit={(e, v) => {
                          handleForget(e, v)
                        }}
                      >
                        <div className="mb-3">
                          <AvField
                            name="email"
                            label="Email"
                            className="form-control"
                            placeholder="Enter email"
                            type="email"
                            required
                          />
                        </div>
                        <div className="d-flex justify-content-end">
                          <button
                            type="submit"
                            className="btn btn-success  mt-1 w-100 rounded"
                          >
                            send
                          </button>
                        </div>
                      </AvForm>
                    </div>
                  </CardBody>
                </Card>
                <div className="mt-5 text-center">
                  <p>
                    Go back to{" "}
                    <Link
                      to="login"
                      className="font-weight-medium text-primary"
                    >
                      Login
                    </Link>{" "}
                  </p>
                  <p>
                    © {new Date().getFullYear()} Career Pro | All Right Issued |
                    Crafted with <i className="mdi mdi-heart text-danger" /> by{" "}
                    <a href="https://osperb.com/"> Osperb</a>
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        )}
      </React.Fragment>
    </div>
  )
}

export default ForgetPassword
