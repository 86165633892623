import {
  IMAGES_UPLOAD_SUCCEESS,
  UPDATE_STUDENT_SUCCESS,
} from "store/students/actionTypes"

const {
  GET_PROFILE_DETAILS,
  GET_PROFILE_DETAILS_SUCCESS,
  GET_PROFILE_DETAILS_FAIL,
  SEARCH_JOB_VACCENCIES,
  SEARCH_JOB_VACCENCIES_SUCCESS,
  SEARCH_JOB_VACCENCIES_FAIL,
  GET_VACENCY_DETAILS,
  GET_VACENCY_DETAILS_SUCCESS,
  GET_VACENCY_DETAILS_FAIL,
} = require("./actionType")

const Initial_state = {
  error: {},
  loading: false,
  studentProfile: {},
  searchJobVacencies: {},
  vacencyDetails: [],
}

const StudentDashboard = (state = Initial_state, action) => {
  switch (action.type) {
    case GET_PROFILE_DETAILS:
      return {
        ...state,
        loading: true,
      }
    case GET_PROFILE_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        studentProfile: action.payload,
      }
    case GET_PROFILE_DETAILS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }
    //  Search Job Vaccencies  //

    case SEARCH_JOB_VACCENCIES:
      return {
        ...state,
        loading: true,
      }

    case SEARCH_JOB_VACCENCIES_SUCCESS:
      return {
        ...state,
        loading: false,
        searchJobVacencies: action.payload,
      }

    case SEARCH_JOB_VACCENCIES_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }

    // Get Vacancy Details //
    case GET_VACENCY_DETAILS:
      return {
        ...state,
        loading: true,
      }

    case GET_VACENCY_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        vacencyDetails: action.payload,
      }

    case GET_VACENCY_DETAILS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }

    case IMAGES_UPLOAD_SUCCEESS:
      return {
        ...state,
        loading: false,
        studentProfile: {
          ...state.studentProfile,
          ...action.payload,
        },
      }

    case UPDATE_STUDENT_SUCCESS:
      return {
        ...state,
        loading: false,
        studentProfile: action.payload,
      }
    default:
      return state
  }
}
export default StudentDashboard
