import React, { useEffect } from "react"
import { Col, Container, Row } from "reactstrap"
import CardData from "./CardData"
import Moduls from "./Moduls"
import Breadcrumb from "components/Common/Breadcrumb"
import MetaTag from "components/Common/Meta-tag"
import { useDispatch, useSelector } from "react-redux"
import { getProfileDetails } from "store/actions"

function CourseDetails() {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getProfileDetails())
  }, [dispatch])
  return (
    <div>
      <MetaTag title="Course Details" />
      <div className="page-content">
        <Container fluid>
          <div className="container-fluid">
            <Row>
              <h5 className="p-2"> Course Details</h5>
              <Col>
                <CardData />
              </Col>
              {/* <Col xl={4}>
                <CardData />
              </Col>
              <Col xl={4}>
                <CardData />
              </Col> */}
            </Row>
            <h5 className="p-3 px-0"> Moduls & Specifications</h5>
            <Col md={12}>
              <Moduls />
            </Col>
          </div>
        </Container>
      </div>
    </div>
  )
}
export default CourseDetails
