import PropTypes from "prop-types"
import React from "react"
import { useHistory, useParams } from "react-router-dom"
import { Col, Card, CardBody } from "reactstrap"
import { FaUsers, IconName } from "react-icons/fa"
import { useSelector } from "react-redux"

const MiniWidget = () => {
  const history = useHistory()
  const { adminDashboardDetails } = useSelector(state => ({
    adminDashboardDetails: state.StudentDetails.adminDashboardDetails,
  }))
  console.log(adminDashboardDetails)
  const handleLink = () => {
    history.push("/students")
  }
  return (
    <React.Fragment>
      <Col sm="4" xl={4}>
        <Card onClick={handleLink} style={{ cursor: "pointer" }}>
          <CardBody>
            <div className="d-flex align-items-center mb-0">
              {/* <div className="avatar-xs me-3">
                <span className="avatar-title rounded-circle bg-primary bg-soft text-primary font-size-18">
                  <FaUsers />
                </span>
              </div> */}
              <h5 className="font-size-18 text-info mb-0">
                {adminDashboardDetails?.students_count}
              </h5>
            </div>
            <div className="text-muted mt-2">
              <p className="p-0 m-0">Totel Students </p>
            </div>
          </CardBody>
        </Card>
      </Col>
      <Col sm="4" xl={4}>
        <Card onClick={handleLink} style={{ cursor: "pointer" }}>
          <CardBody>
            <div className="d-flex align-items-center mb-0">
              {/* <div className="avatar-xs me-3">
                <span className="avatar-title rounded-circle bg-primary bg-soft text-primary font-size-18">
                  <i className="fas fa-dollar-sign" />
                </span>
              </div> */}
              <h5 className="font-size-18 text-success mb-0">
                {adminDashboardDetails?.paid_students_count}
              </h5>
            </div>
            <div className="text-muted mt-2">
              <p className="p-0 m-0">Fee Paid Students</p>
            </div>
          </CardBody>
        </Card>
      </Col>
      <Col sm="4" xl={4}>
        <Card onClick={handleLink} style={{ cursor: "pointer" }}>
          <CardBody>
            <div className="d-flex align-items-center mb-0">
              <h5 className="font-size-18 text-danger mb-0">
                {adminDashboardDetails?.unpaid_students_count}
              </h5>
            </div>
            <div className="text-muted mt-2">
              <p className="p-0 m-0">Not Paid Students </p>
            </div>
          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  )
}

export default MiniWidget
