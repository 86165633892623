import React, { useState } from "react"
import { Button, Card, CardBody, Input } from "reactstrap"
import { MdDownload, MdUploadFile } from "react-icons/md"
import { TiTick } from "react-icons/ti"
import resume from "assets/images/icons/cv.png"
import { useDispatch, useSelector } from "react-redux"
import { imageUpload } from "store/actions"
import { map } from "lodash"
import { IMAGE_URL } from "helpers/api_methods"
import { saveAs } from "file-saver"

function MobileAttachedFiles() {
  const dispatch = useDispatch()
  const { studentProfile } = useSelector(state => ({
    studentProfile: state?.StudentDashboard?.studentProfile,
  }))

  const [uploadCv, setUploadCv] = useState()
  const [uploadCoverLetter, setUploadCoverLetter] = useState()
  const [uploadCoverLetter2, setUploadCoverLetter2] = useState()
  const [uploadCompanyCv, setUploadCompanyCv] = useState()
  const [uploadCompanyCv2, setUploadCompanyCv2] = useState()

  const onChangeHandler = (e, setState, data) => {
    const fileName = Array.from(e.target.files)
    setState({
      name: fileName[0]?.name,
      size: fileName[0]?.size,
      image: e.target.files,
    })
    dispatch(imageUpload(data, studentProfile?.id))
  }

  const fileCardData = [
    {
      img: resume,
      title: "CV",
      id: "user-cv",
      name: "cv",
      setState: setUploadCv,
      state: uploadCv,
      isUpload: true,
      url: studentProfile?.cv ? studentProfile?.cv : "",
    },
    // {
    //   img: resume,
    //   title: "Cover letter",
    //   id: "user-cover_letter",
    //   name: "cover_letter",
    //   isUpload: true,
    //   setState: setUploadCoverLetter,
    //   state: uploadCoverLetter,
    //   url: studentProfile?.cover_letter ? studentProfile?.cover_letter : "",
    // },
    // {
    //   img: resume,
    //   title: "Modified Cover letter",
    //   id: "user-cover_letter_two",
    //   name: "cover_letter_two",
    //   setState: setUploadCoverLetter2,
    //   state: uploadCoverLetter2,
    //   url: studentProfile?.cover_letter_two
    //     ? studentProfile?.cover_letter_two
    //     : "",
    // },
    // {
    //   img: resume,
    //   title: "Modified  Cv",
    //   id: "user-company_cv",
    //   name: "company_cv",
    //   setState: setUploadCompanyCv,
    //   state: uploadCompanyCv,
    //   url: studentProfile?.company_cv ? studentProfile?.company_cv : "",
    // },
    // {
    //   img: resume,
    //   title: "Modified  Cv Two",
    //   id: "user-company_cv_two",
    //   name: "company_cv_two",
    //   setState: setUploadCompanyCv2,
    //   state: uploadCompanyCv2,
    //   url: studentProfile?.company_cv_two ? studentProfile?.company_cv_two : "",
    // },
  ]

  const Download = async FileUrl => {
    var myArray = FileUrl.split("://")
    var imageUrl = `${IMAGE_URL}${FileUrl}`

    if (myArray[0] == "http" || myArray[0] == "https") {
      imageUrl = `${FileUrl}`
    }

    const filename = myArray[0]?.split("/")
    saveAs(imageUrl, filename[filename?.length - 1])

    // var myArray = FileUrl.split("://")

    // var image = `${IMAGEURL}${FileUrl}`
    // if (myArray[0] == "http" || myArray[0] == "https") {
    //     image = `${FileUrl}`
    // }
    // if (FileUrl) {
    //     FileSaver.saveAs(image, "image.jpg")
    // }
  }

  return (
    <>
      <p className="text-black mt-3">Documents</p>
      <div className="d-flex flex-wrap justify-content-between mobile-attached-file">
        {map(fileCardData, (item, key) => (
          <Card style={{ width: "48%" }} key={key}>
            <CardBody className="d-flex flex-column justify-content-between align-items-center">
              <div className="text-center">
                <img src={item?.img} alt="resume" style={{ height: 100 }} />
                <p className="font-size-13 mt-1">{item?.title}</p>
              </div>
              <div className="d-flex align-items-center justify-content-between w-100">
                {item?.isUpload === true ? (
                  <Button className={`flex-1 me-1 w-100 isActive`}>
                    <label
                      htmlFor={item?.id}
                      className="mb-0 w-100 h-100 font-size-11"
                    >
                      {item?.url ? (
                        <>
                          <TiTick size={"1.1rem"} /> Uploaded
                        </>
                      ) : (
                        <>
                          <MdUploadFile size={"1.1rem"} /> Upload
                        </>
                      )}
                    </label>
                    <Input
                      hidden
                      content
                      onChange={e =>
                        onChangeHandler(e, item?.setState, {
                          [item?.name]: e.target.files,
                        })
                      }
                      type="file"
                      name={item?.name}
                      id={item?.id}
                    />
                  </Button>
                ) : (
                  <Button
                    className={`flex-1 me-1 w-100 ${item?.url && "isActive"}`}
                  >
                    <label
                      htmlFor={item?.id}
                      className="mb-0 w-100 h-100 font-size-11"
                    >
                      Download{" "}
                      <MdDownload
                        className="ms-1"
                        size={"1rem"}
                        onClick={() => Download(item?.url)}
                      />
                    </label>
                  </Button>
                )}

                {item?.url && (
                  <MdDownload
                    className="ms-2"
                    size={"1rem"}
                    onClick={() => Download(item?.url)}
                  />
                )}
              </div>
              {[item?.state]?.name && (
                <p
                  className="p-0 m-0 mt-1 pt-1 font-size-10 text-muted"
                  style={{ lineHeight: "12px" }}
                >
                  {uploadCv?.name}
                </p>
              )}
            </CardBody>
          </Card>
        ))}
      </div>
    </>
  )
}

export default MobileAttachedFiles
