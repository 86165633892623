import { useWindowSize } from 'react-use'

function useWindow() {
    const { width } = useWindowSize()
    const isMobile = width < 768 ? true : false

    return {
        isMobile: isMobile
    }
}

export default useWindow