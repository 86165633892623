import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import PropTypes from "prop-types"

import { Button, CardTitle } from "reactstrap"
import ModulModal from "./Modals/ModulModal"
import Modules from "./ModulesWeb"
import { FaPlus } from "react-icons/fa"
import ModulesMobile from "../../../../components/Mobile/ModulesMobile"
import useWindow from "Hooks/UseWindow"

function ModulesSpecifications({ module, setModule }) {
  const [modal_open, setModal_open] = useState(false)

  const { isMobile } = useWindow()

  const { modules } = useSelector(state => ({
    modules: state.StudentDetails.StudentsSingleview?.specializations,
  }))

  useEffect(() => {
    if (modules?.length >= 1) {
      setModule(modules)
    } else setModule(module)
  }, [modules])

  return (
    <>
      <ModulModal
        modal_open={modal_open}
        setModal_open={setModal_open}
        module={module}
        setModule={setModule}
      />
      {isMobile ? (
        <h3
          className="font-size-14 px-2 sm:pb-0 sm:mt-1 text-darkGreen sm:fw-600"
          style={{ width: "fit-content" }}
        >
          Qualifications
        </h3>
      ) : (
        <div className="mb-0 mt-3 sm:mb-0 d-flex justify-content-between align-items-center">
          <p className="mb-0">Qualifications</p>
        </div>
      )}
      <div className="table-responsive mobile-overflow-vissible">
        {isMobile ? (
          <ModulesMobile setModule={setModule} module={module} />
        ) : (
          <Modules module={module} />
        )}
        {/* <div className="mt-3 d-flex justify-content-center align-items-center mx-3">
          <Button
            className="px-4 boxshadow-none mobile-module-add py-2 w-100 mb-2"
            color="light"
            onClick={() => {
              setModule([...module, { uuid: module?.length + 1 }])
            }}
          >
            Add Module <FaPlus size={"12px"} />
          </Button>
        </div> */}
      </div>
    </>
  )
}

export default ModulesSpecifications

ModulesSpecifications.propTypes = {
  module: PropTypes.any,
  setModule: PropTypes.func,
}
