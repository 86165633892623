import React, { useEffect, useState } from "react"
import { Card, CardBody, Table } from "reactstrap"

//redux
import { useSelector, useDispatch } from "react-redux"
import { getStudentDetails, getStudents } from "store/actions"
import { map, range } from "lodash"
import MyPagination from "components/Common/MyPagination"
//actions

const StudentPayment = props => {
  const dispatch = useDispatch()
  const [page, setPage] = useState(1)

  const { notPaidStudents } = useSelector(state => ({
    notPaidStudents: state?.StudentDetails?.student,
  }))
  console.log(notPaidStudents?.results)

  const totalPages = Math.ceil(notPaidStudents?.count / 10)
  const pages = range(1, totalPages + 1)

  useEffect(() => {
    dispatch(getStudents("false", "", page))
  }, [dispatch, page])

  return (
    <React.Fragment>
      <Card>
        <CardBody>
          <div className="text-muted text-center">
            <p className="mb-2">Total not paid students</p>
            <h4>{notPaidStudents?.count}</h4>
          </div>

          <div className="table-responsive mt-4">
            <Table className="table align-middle mb-0">
              <tbody>
                {map(notPaidStudents?.results, (data, index) => (
                  <>
                    <tr key={index}>
                      <td>
                        <h5 className="font-size-14 mb-1">
                          {data?.account?.full_name}
                        </h5>
                      </td>
                      <td>
                        <p className="text-muted mb-0">
                          {data?.account?.phone}
                        </p>
                      </td>
                    </tr>
                  </>
                ))}
              </tbody>
            </Table>
          </div>
          <MyPagination
            totalPages={pages}
            page={page}
            setPage={setPage}
            noInput={true}
          />
        </CardBody>
      </Card>
    </React.Fragment>
  )
}

export default StudentPayment
