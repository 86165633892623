import React, { useState } from "react"
import { Button, Card, CardBody, Input, Table } from "reactstrap"
import { Link, useParams } from "react-router-dom"
import { saveAs } from "file-saver"

import { IMAGE_URL } from "helpers/api_methods"
import PropTypes from "prop-types"
import { imageUpload } from "store/actions"
import { useDispatch, useSelector } from "react-redux"
import { useWindowSize } from "react-use"

const AttachedFiles = ({ CardData }) => {
  const dispatch = useDispatch()
  const params = useParams()

  const Role = localStorage?.getItem("role")

  const { studentProfile, loading, singleData } = useSelector(state => ({
    studentProfile: state?.StudentDashboard?.studentProfile,
    loading: state?.StudentDetails?.loading,
    singleData: state.StudentDetails.StudentsSingleview,
  }))

  const [uploadCv, setUploadCv] = useState()
  const [uploadCoverLetter, setUploadCoverLetter] = useState()
  const [uploadCoverLetter2, setUploadCoverLetter2] = useState()
  const [uploadCompanyCv, setUploadCompanyCv] = useState()
  const [uploadCompanyCv2, setUploadCompanyCv2] = useState()

  const onChangeHandlerCv = e => {
    const fileName = Array.from(e.target.files)
    setUploadCv({
      name: fileName[0]?.name,
      size: fileName[0]?.size,
      image: e.target.files,
    })
  }

  const onChangeHandlerCompanyVc = e => {
    const fileName = Array.from(e.target.files)
    setUploadCompanyCv({
      name: fileName[0]?.name,
      size: fileName[0]?.size,
      image: e.target.files,
    })
  }
  const onChangeHandlerCompanyVc2 = e => {
    const fileName = Array.from(e.target.files)
    setUploadCompanyCv2({
      name: fileName[0]?.name,
      size: fileName[0]?.size,
      image: e.target.files,
    })
  }

  const onChangeCover = e => {
    const coverFile = Array.from(e.target.files)
    setUploadCoverLetter({
      name: coverFile[0]?.name,
      size: coverFile[0]?.size,
      image: e.target.files,
    })
  }

  const onChangeCover2 = e => {
    const coverFile = Array.from(e.target.files)
    setUploadCoverLetter2({
      name: coverFile[0]?.name,
      size: coverFile[0]?.size,
      image: e.target.files,
    })
  }

  const Download = async FileUrl => {
    var myArray = FileUrl.split("://")
    var imageUrl = `${IMAGE_URL}${FileUrl}`

    if (myArray[0] == "http" || myArray[0] == "https") {
      imageUrl = `${FileUrl}`
    }

    const filename = myArray[0]?.split("/")
    saveAs(imageUrl, filename[filename?.length - 1])

    // var myArray = FileUrl.split("://")
    // var imageUrl = `${IMAGEURL}${FileUrl}`
    // if (myArray[0] == "http" || myArray[0] == "https") {
    //   imageUrl = `${FileUrl}`
    // }
    // if (FileUrl) {
    //   FileSaver.saveAs(imageUrl, "image.jpg")
    // }
  }

  const DocumentSubmit = () => {
    const finalData = {
      cv: uploadCv?.image,
      company_cv: uploadCompanyCv?.image,
      company_cv_two: uploadCompanyCv2?.image,
      cover_letter: uploadCoverLetter?.image,
      cover_letter_two: uploadCoverLetter2?.image,
    }

    dispatch(
      imageUpload(finalData, params?.id ? params?.id : studentProfile?.id)
    )
  }

  const { width } = useWindowSize()
  console.log(singleData)
  return (
    <Card>
      <CardBody>
        <h6 className="mb-3">Documents</h6>
        <div>
          <Table className="table table-nowrap table-centered table-hover mb-0">
            <tbody>
              <tr>
                <td style={{ width: "45px" }}>
                  <div className="avatar-sm">
                    <span className="avatar-title rounded-circle bg-soft bg-primary text-primary font-size-24">
                      <i className="bx bxs-file-doc" />
                    </span>
                  </div>
                </td>
                <td
                  style={{
                    verticalAlign: "middle",
                  }}
                >
                  <div
                    className={`d-flex  justify-content-between ${
                      width >= 768
                        ? "align-items-center"
                        : " flex-column align-items-start"
                    }`}
                  >
                    <h6 className="font-size-13 mb-4">
                      Cv <br />
                    </h6>
                    <div
                      className="text-start"
                      style={{
                        display: "flex",
                        justifyContent: "space-evenly",
                      }}
                    >
                      <label className="bg-none rounded bg-soft label-edit">
                        <i
                          style={{ fontSize: "25px" }}
                          className="bx bx-image-add text-muted"
                        >
                          <Input
                            hidden
                            content
                            onChange={onChangeHandlerCv}
                            type="file"
                            name="cv"
                            multiple
                          />
                        </i>
                        {uploadCv?.name && (
                          <p
                            className="p-0 m-0 font-size-10"
                            style={{
                              maxWidth: 180,
                              overflow: "hidden",
                            }}
                          >
                            {uploadCv?.name}
                          </p>
                        )}
                      </label>

                      {CardData?.cv && (
                        <Link
                          to={"#"}
                          className=" "
                          onClick={() => Download(CardData?.cv)}
                        >
                          <i className="bx bx-download h3 py-2 text-muted"></i>
                        </Link>
                      )}
                    </div>
                  </div>
                </td>
              </tr>
              {singleData && (
                <tr>
                  <td style={{ width: "45px" }}>
                    <div className="avatar-sm">
                      <span className="avatar-title rounded-circle bg-soft bg-primary text-primary font-size-24">
                        <i className="bx bxs-file-doc" />
                      </span>
                    </div>
                  </td>
                  <td
                    style={{
                      verticalAlign: "middle",
                    }}
                  >
                    <div
                      className={`d-flex  justify-content-between ${
                        width >= 768
                          ? "align-items-center"
                          : " flex-column align-items-start"
                      }`}
                    >
                      <h6 className="font-size-13 mb-4">
                        Company Cv <br />
                      </h6>
                      <div
                        className="text-start"
                        style={{
                          display: "flex",
                          justifyContent: "space-evenly",
                        }}
                      >
                        <label className="bg-none rounded bg-soft label-edit">
                          <i
                            style={{ fontSize: "25px" }}
                            className="bx bx-image-add text-muted"
                          >
                            <Input
                              hidden
                              content
                              onChange={onChangeHandlerCompanyVc}
                              type="file"
                              name="company_cv"
                              multiple
                            />
                          </i>
                          {uploadCompanyCv?.name && (
                            <p
                              className="p-0 m-0 font-size-10"
                              style={{
                                maxWidth: 180,
                                overflow: "hidden",
                              }}
                            >
                              {uploadCompanyCv?.name}
                            </p>
                          )}
                        </label>

                        {CardData?.cv && (
                          <Link
                            to={"#"}
                            className=" "
                            onClick={() => Download(CardData?.cv)}
                          >
                            <i className="bx bx-download h3 py-2 text-muted"></i>
                          </Link>
                        )}
                      </div>
                    </div>
                  </td>
                </tr>
              )}

              {/* <tr>
                <td style={{ width: "25px" }}>
                  <div className="avatar-sm">
                    <span className="avatar-title rounded-circle bg-soft bg-primary text-primary font-size-24">
                      <i className="bx bxs-file-doc " />
                    </span>
                  </div>
                </td>
                <td
                  style={{
                    verticalAlign: "middle",
                  }}
                >
                  <div
                    className={`d-flex  justify-content-between ${
                      width >= 768
                        ? "align-items-center"
                        : " flex-column align-items-start"
                    }`}
                  >
                    <h6 className="font-size-13 mb-1">
                      Cover letter
                      <br />
                    </h6>
                    <div
                      className="text-start "
                      style={{
                        display: "flex",
                        justifyContent: "space-evenly",
                      }}
                    >
                      <label className="bg-none rounded bg-soft label-edit">
                        <i
                          style={{ fontSize: "25px" }}
                          className="bx bx-image-add text-muted"
                        >
                          <Input
                            hidden
                            content
                            multiple
                            onChange={onChangeCover}
                            type="file"
                            name="cover_letter"
                          />
                        </i>
                        {uploadCoverLetter?.name && (
                          <p
                            className="p-0 m-0 font-size-10"
                            style={{
                              maxWidth: 180,
                              overflow: "hidden",
                            }}
                          >
                            {uploadCoverLetter?.name}
                          </p>
                        )}
                      </label>

                      {CardData?.cover_letter && (
                        <Link
                          to={"#"}
                          onClick={() => Download(CardData?.cover_letter)}
                        >
                          <i className="bx bx-download text-muted h3 py-2" />
                        </Link>
                      )}
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td style={{ width: "25px" }}>
                  <div className="avatar-sm">
                    <span className="avatar-title rounded-circle bg-soft bg-primary text-primary font-size-24">
                      <i className="bx bxs-file-doc " />
                    </span>
                  </div>
                </td>
                <td
                  style={{
                    verticalAlign: "middle",
                  }}
                >
                  <div
                    className={`d-flex  justify-content-between ${
                      width >= 768
                        ? "align-items-center"
                        : " flex-column align-items-start"
                    }`}
                  >
                    <h6 className="font-size-13 mb-1">
                      Modified Cover letter
                      <br />
                    </h6>
                    <div
                      className="text-start "
                      style={{
                        display: "flex",
                        justifyContent: "space-evenly",
                      }}
                    >
                      {Role === "admin" && (
                        <label className="bg-none rounded bg-soft label-edit">
                          <i
                            style={{ fontSize: "25px" }}
                            className="bx bx-image-add text-muted"
                          >
                            <Input
                              hidden
                              content
                              multiple
                              onChange={onChangeCover2}
                              type="file"
                              name="cover_letter"
                            />
                          </i>
                          {uploadCoverLetter2?.name && (
                            <p
                              className="p-0 m-0 font-size-10"
                              style={{
                                maxWidth: 180,
                                overflow: "hidden",
                              }}
                            >
                              {uploadCoverLetter2?.name}
                            </p>
                          )}
                        </label>
                      )}

                      {CardData?.cover_letter_two && (
                        <Link
                          to={"#"}
                          onClick={() => Download(CardData?.cover_letter_two)}
                        >
                          <i className="bx bx-download text-muted h3 py-2" />
                        </Link>
                      )}
                    </div>
                  </div>
                </td>
              </tr>

              <tr>
                <td style={{ width: "45px" }}>
                  <div className="avatar-sm">
                    <span className="avatar-title rounded-circle bg-soft bg-primary text-primary font-size-24">
                      <i className="bx bxs-file-doc" />
                    </span>
                  </div>
                </td>
                <td
                  style={{
                    verticalAlign: "middle",
                  }}
                >
                  <div
                    className={`d-flex  justify-content-between ${
                      width >= 768
                        ? "align-items-center"
                        : " flex-column align-items-start"
                    }`}
                  >
                    <h6 className="font-size-13 mb-1">
                      Modified Company Cv <br />
                    </h6>
                    <div
                      className="text-start "
                      style={{
                        display: "flex",
                        justifyContent: "space-evenly",
                      }}
                    >
                      {Role === "admin" && (
                        <label className="bg-none rounded bg-soft label-edit">
                          <i
                            style={{ fontSize: "25px" }}
                            className="bx bx-image-add text-muted"
                          >
                            <Input
                              hidden
                              content
                              onChange={onChangeHandlerCompanyVc}
                              type="file"
                              name="cv"
                            />
                          </i>
                          {uploadCompanyCv?.name && (
                            <p
                              className="p-0 m-0 font-size-10"
                              style={{
                                maxWidth: 180,
                                overflow: "hidden",
                              }}
                            >
                              {uploadCompanyCv?.name}
                            </p>
                          )}
                        </label>
                      )}

                      {CardData?.company_cv && (
                        <Link
                          to={"#"}
                          className=" "
                          onClick={() => Download(CardData?.company_cv)}
                        >
                          <i className="bx bx-download h3 py-2 text-muted"></i>
                        </Link>
                      )}
                    </div>
                  </div>
                </td>
              </tr>

              <tr>
                <td style={{ width: "45px" }}>
                  <div className="avatar-sm">
                    <span className="avatar-title rounded-circle bg-soft bg-primary text-primary font-size-24">
                      <i className="bx bxs-file-doc" />
                    </span>
                  </div>
                </td>
                <td
                  style={{
                    verticalAlign: "middle",
                  }}
                >
                  <div
                    className={`d-flex  justify-content-between ${
                      width >= 768
                        ? "align-items-center"
                        : " flex-column align-items-start"
                    }`}
                  >
                    <h6 className="font-size-13 mb-1">
                      Modified Company Cv Two <br />
                    </h6>
                    <div
                      className="text-start "
                      style={{
                        display: "flex",
                        justifyContent: "space-evenly",
                      }}
                    >
                      {Role === "admin" && (
                        <label className="bg-none rounded bg-soft label-edit">
                          <i
                            style={{ fontSize: "25px" }}
                            className="bx bx-image-add text-muted"
                          >
                            <Input
                              hidden
                              content
                              onChange={onChangeHandlerCompanyVc2}
                              type="file"
                              name="cv"
                            />
                          </i>
                          {uploadCompanyCv2?.name && (
                            <p
                              className="p-0 m-0 font-size-10"
                              style={{
                                maxWidth: 180,
                                overflow: "hidden",
                              }}
                            >
                              {uploadCompanyCv2?.name}
                            </p>
                          )}
                        </label>
                      )}

                      {CardData?.company_cv_two && (
                        <Link
                          to={"#"}
                          className=" "
                          onClick={() => Download(CardData?.company_cv_two)}
                        >
                          <i className="bx bx-download h3 py-2 text-muted"></i>
                        </Link>
                      )}
                    </div>
                  </div>
                </td>
              </tr> */}
            </tbody>
          </Table>
          <Button
            color="success"
            className="float-end px-5 btn-block mt-3 mb-0"
            onClick={() => DocumentSubmit()}
            type="button"
          >
            {loading && (
              <>
                <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
              </>
            )}
            Upload Documents
          </Button>
        </div>
      </CardBody>
    </Card>
  )
}

export default AttachedFiles

AttachedFiles.propTypes = {
  CardData: PropTypes.any,
}
