import {
  GET_STUDENTS,
  GET_STUDENTS_SUCCESS,
  GET_STUDENTS_FAIL,
  GET_STUDENTS_LIST,
  CREATE_STUDENTS,
  CREATE_STUDENTS_SUCCESS,
  CREATE_STUDENTS_FAIL,
  STUDENT_DETAILS,
  STUDENT_DETAILS_SUCCESS,
  STUDENT_DETAILS_FAIL,
  UPDATE_STUDENT,
  UPDATE_STUDENT_SUCCESS,
  UPDATE_STUDENT_FAIL,
  DELETE_STUDENT,
  DELETE_STUDENT_SUCCESS,
  DELETE_STUDENT_FAIL,
  GET_JOB_APPLICATIONS,
  GET_JOB_APPLICATIONS_SUCCESS,
  GET_JOB_APPLICATIONS_FAIL,
  GET_SINGLE_APPLICATIONS_DETAILS,
  GET_SINGLE_APPLICATIONS_DETAILS_SUCCESS,
  GET_SINGLE_APPLICATIONS_DETAILS_FAIL,
  CREATE_JOB_APPLICATION,
  CREATE_JOB_APPLICATION_SUCCESS,
  CREATE_JOB_APPLICATION_FAIL,
  UPDATE_JOB_APPLICATION,
  UPDATE_JOB_APPLICATION_SUCCESS,
  UPDATE_JOB_APPLICATION_FAIL,
  DELETE_JOB_APPLICATION,
  DELETE_JOB_APPLICATION_SUCCESS,
  DELETE_JOB_APPLICATION_FAIL,
  GET_STUDENT_NOTE,
  GET_STUDENT_NOTE_SUCCESS,
  GET_STUDENT_NOTE_FAIL,
  CREATE_STUDENT_NOTES,
  CREATE_STUDENT_NOTES_SUCCESS,
  CREATE_STUDENT_NOTES_FAIL,
  DELETE_STUDENT_NOTE,
  DELETE_STUDENT_NOTE_SUCCESS,
  DELETE_STUDENT_NOTE_FAIL,
  GET_ADMIN_DASHBOARD_DETAILS,
  GET_ADMIN_DASHBOARD_DETAILS_SUCCESS,
  GET_ADMIN_DASHBOARD_DETAILS_FAIL,
  GET_STUDENTS_LIST_FAIL,
  GET_STUDENTS_LIST_SUCCESS,
  IMAGES_UPLOAD,
  IMAGES_UPLOAD_SUCCEESS,
  IMAGES_UPLOAD_FAIL,
  SELF_DELETE_STUDENT,
  SELF_DELETE_STUDENT_SUCCESS,
  SELF_DELETE_STUDENT_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  student: [],
  studentDetails: {},
  studentsList: {},
  error: {},
  loading: false,
  StudentsSingleview: {},
  updatedStudent: {},
  imageUpload: {},
  studentDelete: {},

  // Job Applications//
  applicationData: {
    count: "",
    results: [],
  },
  createApplication: [],
  applicationDetails: {},
  updateData: {},
  deleteData: {},

  //note
  studentNote: {
    count: "",
    results: [],
  },
  createdNote: {},
  deleteStudentNote: {},

  // admin dashboard
  adminDashboardDetails: {},
}

const StudentDetails = (state = INIT_STATE, action) => {
  switch (action.type) {
    // Get Students//

    case GET_STUDENTS:
      return {
        ...state,
        loading: true,
      }
    case GET_STUDENTS_SUCCESS:
      return {
        ...state,
        student: action.payload,
        error: {},
        loading: false,
      }

    case GET_STUDENTS_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case GET_STUDENTS_LIST:
      return {
        ...state,
        loading: true,
      }
    case GET_STUDENTS_LIST_SUCCESS:
      return {
        ...state,
        studentsList: action.payload.data,
        error: {},
        loading: false,
      }

    case GET_STUDENTS_LIST_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    //  Students Single view //

    case STUDENT_DETAILS:
      return {
        ...state,
        loading: true,
      }
    case STUDENT_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        StudentsSingleview: action.payload,
      }

    case STUDENT_DETAILS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }

    //  Update Students //

    case UPDATE_STUDENT:
      return {
        ...state,
        loading: true,
      }
    case UPDATE_STUDENT_SUCCESS:
      return {
        ...state,
        updatedStudent: action.payload,
        StudentsSingleview: action.payload,
        loading: false,
      }
    case UPDATE_STUDENT_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }

    // image upload

    case IMAGES_UPLOAD:
      return {
        ...state,
        loading: true,
      }
    case IMAGES_UPLOAD_SUCCEESS:
      return {
        ...state,
        loading: false,
        imageUpload: action.payload,
        StudentsSingleview: {
          ...state.StudentsSingleview,
          ...action.payload,
        },
      }

    case IMAGES_UPLOAD_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }

    // Delete Students //

    case DELETE_STUDENT:
      return {
        ...state,
        loading: true,
      }
    case DELETE_STUDENT_SUCCESS:
      return {
        ...state,
        loading: false,
        studentDelete: action.payload,
      }
    case DELETE_STUDENT_FAIL:
      return {
        ...state,
        loading: false,
        error: action.oayload,
      }

    case SELF_DELETE_STUDENT:
      return {
        ...state,
        loading: true,
      }
    case SELF_DELETE_STUDENT_SUCCESS:
      return {
        ...state,
        loading: false,
        studentDelete: action.payload,
      }
    case SELF_DELETE_STUDENT_FAIL:
      return {
        ...state,
        loading: false,
        error: action.oayload,
      }
    // Student Notes //

    //get //

    case GET_STUDENT_NOTE:
      return {
        ...state,
        loading: true,
      }

    case GET_STUDENT_NOTE_SUCCESS:
      return {
        ...state,
        loading: false,
        studentNote: action.payload,
      }
    case GET_STUDENT_NOTE_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }
    // create //

    case CREATE_STUDENT_NOTES:
      return {
        ...state,
        loading: true,
      }

    case CREATE_STUDENT_NOTES_SUCCESS:
      return {
        ...state,
        studentNote: {
          ...state.studentNote,
          results: [...state.studentNote.results, action.payload],
        },
        loading: false,
        createdNote: action.payload,
      }

    case CREATE_STUDENT_NOTES_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }

    // delete //
    case DELETE_STUDENT_NOTE:
      return {
        ...state,
        loading: true,
      }

    case DELETE_STUDENT_NOTE_SUCCESS:
      return {
        ...state,
        loading: false,
        studentNote: {
          ...state.studentNote,
          results: state.studentNote?.results?.filter(
            studentNote => studentNote?.id !== action.payload
          ),
        },
        // deleteStudentNote: action.payload,
      }
    case DELETE_STUDENT_NOTE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }

    //     Job Applications //

    case GET_JOB_APPLICATIONS:
      return {
        ...state,
        loading: true,
      }
    case GET_JOB_APPLICATIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        applicationData: action.payload,
      }

    case GET_JOB_APPLICATIONS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }

    //Single view //

    case GET_SINGLE_APPLICATIONS_DETAILS:
      return {
        ...state,
        loading: true,
      }
    case GET_SINGLE_APPLICATIONS_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        applicationSingleData: action.payload,
      }

    case GET_SINGLE_APPLICATIONS_DETAILS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }

    // create//

    case CREATE_JOB_APPLICATION:
      return {
        ...state,
        loading: true,
      }
    case CREATE_JOB_APPLICATION_SUCCESS:
      return {
        ...state,
        loading: false,
        createApplications: action.payload,
      }

    case CREATE_JOB_APPLICATION_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }

    // Update //

    case UPDATE_JOB_APPLICATION:
      return {
        ...state,
        loading: true,
      }
    case UPDATE_JOB_APPLICATION_SUCCESS:
      return {
        ...state,
        loading: false,
        updateData: action.payload,
      }

    case UPDATE_JOB_APPLICATION_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }

    // Delete //

    case DELETE_JOB_APPLICATION:
      return {
        ...state,
        loading: true,
      }

    case DELETE_JOB_APPLICATION_SUCCESS:
      return {
        ...state,
        loading: false,
        applicationData: {
          ...state.applicationData,
          results: state.applicationData?.results?.filter(
            appilcationData => appilcationData.id !== action.payload
          ),
        },
      }

    case DELETE_JOB_APPLICATION_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }

    // Admin Dashboard Details //

    case GET_ADMIN_DASHBOARD_DETAILS:
      return {
        ...state,
        loading: true,
      }
    case GET_ADMIN_DASHBOARD_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        adminDashboardDetails: action.payload,
      }

    case GET_ADMIN_DASHBOARD_DETAILS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }

    default:
      return state
  }
}

export default StudentDetails
