import React, { useEffect } from "react"
import { Button, Card, CardBody, Col, Row, Spinner } from "reactstrap"
import ToolkitProvider from "react-bootstrap-table2-toolkit"
import BootstrapTable from "react-bootstrap-table-next"
import { Link } from "react-router-dom"
import { map } from "lodash"
import { useDispatch, useSelector } from "react-redux"
import "assets/scss/datatables.scss"
import { getJobApplications } from "store/actions"
import { Markup } from "interweave"

function AllJobApplications() {
  const dispatch = useDispatch()

  const { applicationData } = useSelector(state => ({
    applicationData: state.StudentDetails.applicationData,
  }))
  const loading = false
  const columns = [
    {
      dataField: "title",
      text: "Title",
      sort: true,
    },
    {
      dataField: "description",
      text: " Description",
    },
    {
      dataField: "image",
      text: "Image",
    },
  ]

  const studentsData = map(applicationData?.results, (item, key) => ({
    ...item,
    title: (
      <span>
        <Markup content={item?.title} />
      </span>
    ),
    image: <img src={item?.image} style={{ height: 50, width: 50 }} />,
    description: (
      <span>
        <Markup
          content={
            item?.description?.length > 40
              ? item?.description.substr(0, 40 - 1) + "..."
              : item?.description
          }
        />
      </span>
    ),
  }))

  useEffect(() => {
    dispatch(getJobApplications())
  }, [dispatch])

  const defaultSorted = [
    {
      dataField: "id",
      order: "asc",
    },
  ]

  const selectRow = {
    mode: "checkbox",
  }

  console.log(loading)
  return (
    <Col className="col-12">
      <Card>
        <CardBody>
          <p className="text-muted font-size-14 mb-1">Job Application</p>
          <ToolkitProvider
            keyField="id"
            columns={columns}
            data={studentsData}
            search
          >
            {toolkitProps => (
              <React.Fragment>
                <Row className="mb-2">
                  <Col md="4">
                    <div className="">
                      <div className="position-relative"></div>
                    </div>
                  </Col>
                </Row>
                {loading ? (
                  <Spinner color="secondary" className="d-block m-auto" />
                ) : (
                  <>
                    <Row>
                      <Col xl="12">
                        <div className="table-responsive ">
                          <BootstrapTable
                            keyField={"id"}
                            responsive
                            bordered={false}
                            striped={false}
                            defaultSorted={defaultSorted}
                            selectRow={selectRow}
                            classes={"table align-middle table-nowrap h6"}
                            headerWrapperClasses={"thead-light"}
                            {...toolkitProps.baseProps}
                          />
                        </div>
                      </Col>
                    </Row>
                    <div className="float-end mt-3">
                      <Link to="/job-application">
                        <Button
                          color="success"
                          className="h6 text-white px-5"
                          size="sm"
                        >
                          view all
                        </Button>
                      </Link>
                    </div>
                  </>
                )}
              </React.Fragment>
            )}
          </ToolkitProvider>
        </CardBody>
      </Card>
    </Col>
  )
}

export default AllJobApplications
