import PropTypes from "prop-types"
import React from "react"
import { Col, Card, CardBody } from "reactstrap"
import YesOrNoQuestions from "pages/Students/Crud/elements/YesOrNoQuestions"

const Carditem = ({ CardData, hideLast, showLast }) => {
  return (
    <React.Fragment>
      {!showLast && (
        <>
          {/* <Col sm="12" className="pb24">
            <Card className="h100 ">
              <CardBody>
                <div className="d-flex align-items-center mb-3">
                  <div className="avatar-xs me-3">
                    <span className="avatar-title rounded-circle bg-primary bg-soft text-primary font-size-18">
                      <MdLibraryBooks />
                    </span>
                  </div>
                  <h5 className="font-size-12 mb-0 mt-0 text-muted"> Course</h5>
                </div>
                <div className="text-muted mt-4 font-size-12">
                  <p className="mb-1">
                    <Row>
                      <Col sm="6">Course Name</Col>
                      <Col sm="6">
                        {" "}
                        : <span>{CardData?.course_title}</span>
                      </Col>
                    </Row>
                  </p>
                  <p className="mb-0">
                    <Row>
                      <Col sm="6">Started on</Col>
                      <Col sm="6">
                        {" "}
                        : <span>{CardData?.course_start_date}</span>
                      </Col>
                    </Row>
                  </p>
                  <p className="mb-1">
                    <Row>
                      <Col sm="6">End in</Col>
                      <Col sm="6">
                        {" "}
                        : <span>{CardData?.course_end_date}</span>
                      </Col>
                    </Row>
                  </p>
                  <p className="mb-1">
                    <Row>
                      <Col sm="6">Course Duration</Col>
                      <Col sm="6">
                        {" "}
                        : <span>{CardData?.course_duration}</span>
                      </Col>
                    </Row>
                  </p>
                </div>
              </CardBody>
            </Card>
          </Col>{" "} */}

          {/* <Col sm="12" className="pb24">
            <Card className="h100">
              <CardBody>
                <div className="d-flex align-items-center mb-3">
                  <div className="avatar-xs me-3">
                    <span className="avatar-title rounded-circle bg-primary bg-soft text-primary font-size-18">
                      <i className="fas fa-university" />
                    </span>
                  </div>
                  <h5 className="font-size-12 mb-0 mt-0 text-muted">
                    {" "}
                    University / VISA
                  </h5>
                </div>
                <div className="text-muted mt-4 font-size-12">
                  <p className="mb-1">
                    <Row>
                      <Col sm="6">University Name</Col>
                      <Col sm="6">
                        {" "}
                        : <span>{CardData?.university}</span>
                      </Col>
                    </Row>
                  </p>
                  <p className="mb-1">
                    <Row>
                      <Col sm="6">VISA Starts in</Col>
                      <Col sm="6">
                        {" "}
                        : <span>{CardData?.visa_start_date}</span>
                      </Col>
                    </Row>
                  </p>{" "}
                  <p className="mb-0">
                    <Row>
                      <Col sm="6">VISA Exp</Col>
                      <Col sm="6">
                        {" "}
                        : <span>{CardData?.visa_end_date}</span>
                      </Col>
                    </Row>
                  </p>
                  <p className="mb-1">
                    <Row>
                      <Col sm="6">Country</Col>
                      <Col sm="6">
                        {" "}
                        : <span>{CardData?.course_campus}</span>
                      </Col>
                    </Row>
                  </p>
                </div>
              </CardBody>
            </Card>
          </Col> */}
          {/*           
          <Col sm="12" className="pb24">
            <Card className="h100">
              <CardBody>
                <div className="d-flex align-items-center mb-3">
                  <div className="avatar-xs me-3">
                    <span className="avatar-title rounded-circle bg-primary bg-soft text-primary font-size-18">
                      <i className="bx bxl-linkedin" />
                    </span>
                  </div>
                  <h5 className="font-size-12 text-muted mb-0 mt-0">
                    LinkedIn
                  </h5>
                </div>
                <div className="text-muted mt-4 font-size-12">
                  <p className="mb-1">
                    <Row>
                      <Col sm="6">Username</Col>
                      <Col sm="6">
                        {" "}
                        : <span>{CardData?.linkedin_username}</span>
                      </Col>
                    </Row>
                  </p>
                  <p className="mb-1">
                    <Row>
                      <Col sm="6">Password</Col>
                      <Col sm="6">
                        {" "}
                        : <span>{CardData?.linkedin_password}</span>
                      </Col>
                    </Row>
                  </p>
                </div>
              </CardBody>
            </Card>
          </Col> */}
          <Col sm="12" className="pb24">
            <Card className="h100">
              <CardBody>
                <div className="text-muted font-size-12 w-100">
                  <YesOrNoQuestions
                    content="Do you have any work experience ?"
                    fullWidth
                    sate={CardData?.is_previous_experience}
                  />
                  <YesOrNoQuestions
                    content="Do you have any previous work experience that is relevant to your qualifications ?"
                    fullWidth
                    sate={CardData?.is_work_experience}
                  />
                  <YesOrNoQuestions
                    content="Are you confident in your workplace skills for the job roles ?"
                    fullWidth
                    sate={CardData?.is_confident_workplace_skills}
                  />
                  <YesOrNoQuestions
                    content="Have you ever worked on any academic projects ?"
                    fullWidth
                    sate={CardData?.has_academic_projects}
                  />
                  <YesOrNoQuestions
                    content="Have you ever been a part of any work-related projects ?"
                    fullWidth
                    sate={CardData?.is_include_work_project}
                  />
                  <YesOrNoQuestions
                    content="Do you have any prior internship experience ?"
                    fullWidth
                    sate={CardData?.has_internship_experience}
                  />
                  {/* <p className="mb-1">
                    <Row>
                      <Col sm="9">
                        previous course did not offer any internship or
                        placement opportunities.
                      </Col>
                      <Col sm="3">
                        {" "}
                        :{" "}
                        <span>
                          {" "}
                          <Badge className="rounded bg-success font-size-10 px-2">
                            {CardData?.is_no_internship ? "Yes" : "No"}
                          </Badge>
                        </span>
                      </Col>
                    </Row>
                  </p>
                  <p className="mb-1 mt-3">
                    <Row>
                      <Col sm="9">
                        interested in doing an internship or placement
                      </Col>
                      <Col sm="3">
                        {" "}
                        :{" "}
                        <span>
                          <Badge className="rounded bg-success font-size-10 px-2">
                            {CardData?.is_internship_interested ? "Yes" : "No"}
                          </Badge>
                        </span>
                      </Col>
                    </Row>
                  </p> */}
                </div>
              </CardBody>
            </Card>
          </Col>
        </>
      )}{" "}
      {/* <Badge className="rounded bg-success font-size-10">
                  {" "}
                  course does not include an internship or placement
                </Badge> */}
      {/* {!hideLast && (
        <Col sm="12" className="pb24">
          <Card className="h100">
            <CardBody>
              <div className="d-flex align-items-center mb-3">
                <div className="avatar-xs me-3">
                  <span className="avatar-title rounded-circle bg-primary bg-soft text-primary font-size-18">
                    <AiOutlineCodepen />
                  </span>
                </div>
                <h5 className="font-size-12 mb-0 mt-0 text-muted">
                  Industrial Experience
                </h5>
           
              </div>
              <div className="text-muted mt-4 font-size-12">
                <p className="mb-1">
                  <Row>
                    <Col sm="6">Working Type</Col>
                    <Col sm="6">
                      {" "}
                      : <span>{CardData?.working_type}</span>
                    </Col>
                  </Row>
                </p>
                <p className="mb-1">
                  <Row>
                    <Col sm="6">Start Date</Col>
                    <Col sm="6">
                      {" "}
                      : <span>{CardData?.work_start_date}</span>
                    </Col>
                  </Row>
                </p>
                <p className="mb-1">
                  <Row>
                    <Col sm="6">End Date</Col>
                    <Col sm="6">
                      {" "}
                      : <span>{CardData?.work_end_date}</span>
                    </Col>
                  </Row>
                </p>
                <p className="mb-0">
                  <Row>
                    <Col sm="6">Duration</Col>
                    <Col sm="6">
                      {" "}
                      : <span>{CardData?.work_duration}</span>
                    </Col>
                  </Row>
                </p>
                <p className="mb-1">
                  <Row>
                    <Col sm="6">Submission Deadline</Col>
                    <Col sm="6">
                      {" "}
                      : <span>{CardData?.university_dead_line}</span>
                    </Col>
                  </Row>
                </p>
              </div>
            </CardBody>
          </Card>
        </Col>
      )}{" "} */}
    </React.Fragment>
  )
}
Carditem.propTypes = {
  CardData: PropTypes.array,
  hideLast: PropTypes.bool,
  showLast: PropTypes.bool,
}

export default Carditem
