import React, { useState } from "react"
import { Button, Modal } from "reactstrap"
import PropTypes from "prop-types"
import { IconName, RiErrorWarningLine } from "react-icons/ri"
import { useDispatch } from "react-redux"
import { deleteStudent } from "store/actions"
import { useHistory, useParams } from "react-router-dom"

function DeleteModal({ delete_modal, setDelete_modal }) {
  const dispatch = useDispatch()
  const params = useParams()
  const history = useHistory()

  function tog_center() {
    setDelete_modal(false)
    removeBodyCss()
  }
  function removeBodyCss() {
    document.body.classList.add("no_padding")
  }
  const student_id = params.id

  const handleDelete = student_id => {
    dispatch(deleteStudent(student_id, history))
  }

  return (
    <div>
      <Modal
        isOpen={delete_modal}
        toggle={() => {
          tog_center()
        }}
        centered={true}
      >
        <div className="p-1 px-2">
          <Button
            type="button"
            onClick={() => {
              setDelete_modal(false)
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </Button>
        </div>
        <div className="d-flex justify-content-center">
          <RiErrorWarningLine
            style={{
              color: "yellow",
              fontSize: "80px",
            }}
          />
        </div>
        <div className="modal-body ">
          <h2 className="text-center">Are You Sure...? </h2>
        </div>
        <div className="mt-3">
          <p className="h4 text-muted d-flex justify-content-center">
            Do you really want to delete these student ?{" "}
          </p>
          <p className=" h4 text-muted d-flex justify-content-center">
            This process cannot be undone.
          </p>
        </div>
        <div className="d-flex align-item-center justify-content-center my-3">
          <div className="mx-3">
            <Button className="px-5" outline>
              Cancel
            </Button>
          </div>
          <div className="mx-3">
            <Button
              className="px-5"
              color="danger"
              onClick={() => handleDelete(student_id)}
            >
              Remove
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  )
}

export default DeleteModal
DeleteModal.propTypes = {
  delete_modal: PropTypes.bool,
  setDelete_modal: PropTypes.func,
}
