import React, { useEffect, useState } from "react"
import { Link, useParams } from "react-router-dom"
import PropTypes from "prop-types"
import { Button, Card, CardBody } from "reactstrap"
import SimpleBar from "simplebar-react"
import { useDispatch, useSelector } from "react-redux"
import {
  createStudentNotes,
  deleteStudentNote,
  getStudentNote,
} from "store/actions"
import { AvField, AvForm } from "availity-reactstrap-validation"
import { map } from "lodash"
import moment from "moment"

const NoteScreen = props => {
  const dispatch = useDispatch()
  const params = useParams()

  const { options, series } = props
  useEffect(() => {
    dispatch(getStudentNote(params?.id))
  }, [])

  const [notes, setNotes] = useState()

  const { studentNote, studentId } = useSelector(state => ({
    studentNote: state?.StudentDetails?.studentNote,
    studentId: state.StudentDetails.StudentsSingleview?.id,
  }))

  const handleValidCreateSubmit = (e, createDetails) => {
    const createStudentNotesData = {
      note: notes,
      student: studentId,
      title: "Notes",
    }
    dispatch(createStudentNotes(createStudentNotesData, studentId, setNotes))
  }

  const handleDeleteNote = noteId => {
    dispatch(deleteStudentNote(noteId))
  }

  console.log(notes)

  return (
    <React.Fragment>
      <Card style={{ borderRadius: "15px" }}>
        <CardBody style={{ background: "#fff", borderRadius: "15px" }}>
          <div className="text-center ">
            <h5 className="font-size-15 mb-4">Notes</h5>
          </div>

          <div className="mt-4">
            <SimpleBar
              style={{ maxHeight: 580, height: "50vh" }}
              className="px-3"
            >
              {map(studentNote?.results, (item, key) => (
                <div key={key} style={{ borderBottom: "1px solid #e3e3e3" }}>
                  <ul className="list-group list-group-flush">
                    <li
                      className="list-group-item py-3 px-0"
                      style={{ background: "#fff" }}
                    >
                      <div className="media">
                        <div className="avatar-xs me-3">
                          <div className="avatar-title rounded-circle bg-light text-primary">
                            <i className="bx bxs-chat"></i>
                          </div>
                        </div>
                        <div className="media-body">
                          <div className="float-end">
                            <i
                              className="bx bx-trash-alt note-delete"
                              onClick={() => handleDeleteNote(item?.id)}
                              type="button"
                            />
                          </div>

                          <p className="text-muted">{item?.note}</p>
                          <h5 className="font-size-14 mb-1">
                            <small className="float-end text-muted">
                              {moment(item?.date).format("DD/MM/YYYY")}
                            </small>
                          </h5>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              ))}
            </SimpleBar>
            <div className="mt-3 w-100">
              <AvForm
                className="note-form"
                onValidSubmit={(e, createDetails) => {
                  handleValidCreateSubmit(e, createDetails)
                }}
              >
                <div className="px-2 note-div w-100">
                  <Button className="note-button" type="submit">
                    <i className="bx bxs-send note-icon"></i>
                  </Button>

                  <AvField
                    className="note-input"
                    name="note"
                    label=""
                    type="textarea"
                    placeholder=" Note Here..."
                    style={{
                      height: "auto",
                      minHeight: 45,
                    }}
                    validate={{
                      required: {
                        value: true,
                        errorMessage: " ",
                      },
                    }}
                    value={notes}
                    onChange={e => setNotes(e.target.value)}
                  />
                </div>
              </AvForm>
            </div>
          </div>
        </CardBody>
      </Card>
    </React.Fragment>
  )
}

NoteScreen.propTypes = {
  options: PropTypes.any,
  series: PropTypes.any,
}

export default NoteScreen
