import React from 'react'
import { HiChatAlt2, HiOutlineHome, } from 'react-icons/hi'
import { MdOutlineManageSearch, } from 'react-icons/md'
import { Link, useLocation } from 'react-router-dom'

function MobileNavBar() {
    const location = useLocation()

    return (
        <>
            {location.pathname === '/chat' ? <></> :
                <div
                    className={"MobileNav "}
                >
                    <Link to={"/dashboard"} className={`text-darkGreen mobile_nav_item ${location.pathname === "/dashboard" ? "active" : ""}`}>
                        <div className='icon-wrap'>
                            <HiOutlineHome />
                            <span>Home</span>
                        </div>
                    </Link>
                    <Link to={"/vacancies"} className={`text-darkGreen mobile_nav_item ${location.pathname === "/vacancies" ? "active" : ""}`}>
                        <div className='icon-wrap'>
                            <MdOutlineManageSearch size={"26px"} />
                            <span>Vacancies</span>
                        </div>
                    </Link>

                    <Link to={"/chat"} className={`text-darkGreen mobile_nav_item ${location.pathname === "/chat" ? "active" : ""}`}>
                        <div className='icon-wrap'>
                            <HiChatAlt2 />
                            <span>Message</span>
                        </div>
                    </Link>

                </div>}
        </>
    )
}

export default MobileNavBar