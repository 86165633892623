import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { connect, useDispatch } from "react-redux"
import {
  Switch,
  BrowserRouter as Router,
  Route,
  Redirect,
  useHistory,
} from "react-router-dom"

// Import Routes all
import {
  publicRoutes,
  commonRoute,
  adminProtectedRoutes,
  studentRoutes,
} from "./routes"

// Import all middleware
import Authmiddleware from "./routes/route"

// layouts Format
import VerticalLayout from "./components/VerticalLayout/"
import HorizontalLayout from "./components/HorizontalLayout/"
import NonAuthLayout from "./components/NonAuthLayout"

// Import scss
import "./assets/scss/theme.scss"
import "./assets/scss/custom/components/own_custom.css"
import "swiper/swiper-bundle.min.css"
import "swiper/swiper.min.css"
import useWindow from "Hooks/UseWindow"

const App = props => {
  const dispatch = useDispatch()
  const history = useHistory()

  function getLayout() {
    let layoutCls = VerticalLayout
    switch (props.layout.layoutType) {
      case "horizontal":
        layoutCls = HorizontalLayout
        break
      default:
        layoutCls = VerticalLayout
        break
    }
    return layoutCls
  }

  const token = localStorage.getItem("token")
  const Role = localStorage.getItem("role")
  const { isMobile } = useWindow()

  function MyRoute() {
    let Routes = commonRoute
    switch (Role) {
      case "admin":
        Routes = adminProtectedRoutes
        break
      case "student":
        Routes = studentRoutes
        break
      default:
        Routes = commonRoute
        break
    }
    return Routes
  }

  const Layout = getLayout()

  useEffect(() => {
    if (token) {
      history.push('/dashboard')
    }
  }, [token])

  return (
    <React.Fragment>
      <Router>
        <Switch>
          {publicRoutes.map((route, idx) => (
            <Authmiddleware
              path={route.path}
              layout={NonAuthLayout}
              component={route.component}
              key={idx}
              isAuthProtected={false}
              exact
            />
          ))}

          {MyRoute()?.map((route, idx) => (
            <Authmiddleware
              path={route.path}
              layout={route.path === "/logout" ? NonAuthLayout : Layout}
              component={route.component}
              key={idx}
              isAuthProtected={true}
              exact
            />
          ))}
          {token ? (
            <Route render={() => <Redirect to={{ pathname: "/dashboard" }} />} />
          ) :
            <Route render={() => <Redirect to={{ pathname: "/login" }} />} />
          }
          <Route render={() => <Redirect to={{ pathname: "/404" }} />} />,
        </Switch>
      </Router>
    </React.Fragment>
  )
}

App.propTypes = {
  layout: PropTypes.any,
}

const mapStateToProps = state => {
  return {
    layout: state.Layout,
  }
}

export default connect(mapStateToProps, null)(App)
