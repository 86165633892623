import React from "react"
import { Container } from "reactstrap"

import Breadcrumbs from "components/Common/Breadcrumb"
import DatatableTables from "./DatatableTables"
import MetaTag from "components/Common/Meta-tag"

function JobApplication() {
  return (
    <>
      <MetaTag title="All Job Applications" />
      <div className="page-content">
        <Breadcrumbs title="Dashboard" breadcrumbItem="All Job Applications" />
        <Container fluid>
          <div className="container-fluid">
            <DatatableTables />
          </div>
        </Container>
      </div>
    </>
  )
}

export default JobApplication
