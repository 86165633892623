import useWindow from "Hooks/UseWindow"
import React from "react"
import { Container, Row, Col } from "reactstrap"

const Footer = () => {
  const { isMobile } = useWindow()
  return (
    <React.Fragment>
      {!isMobile && <footer className="footer">
        <Container fluid={true}>
          <Row>
            <Col md={6}>{new Date().getFullYear()} © CareerPro</Col>
            <Col md={6}>
              <div className="text-sm-end d-none d-sm-block">
                Designed & Developed by{" "}
                <a target="blank" href="https://osperb.com">
                  osperb
                </a>
              </div>
            </Col>
          </Row>
        </Container>
      </footer>}
    </React.Fragment>
  )
}

export default Footer
