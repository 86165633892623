import React from "react"
import { useSelector } from "react-redux"
import { Card, Col, Row } from "reactstrap"
import { FaUniversity } from "react-icons/fa"

function CardData() {
  const { course } = useSelector(state => ({
    course: state?.StudentDashboard?.studentProfile,
  }))
  console.log(course)

  return (
    <>
      <Row>
        <Col md="4">
          <Card className="p-3">
            <Row className="">
              <h6 className=" m-2  ">Program Details</h6>
              <Col xs="4">
                <div className="social-source text-center mt-3">
                  <div className="avatar-xs mx-auto mb-3">
                    <span
                      className={
                        "avatar-title rounded-circle " + +" font-size-16"
                      }
                    >
                      <FaUniversity className="font-size-16" />
                    </span>
                  </div>
                  <p className="text-muted mb-0 pb-0 font-size-12">
                    University
                  </p>
                  <p className="mb-0 font-size-12"> {course?.university}</p>
                </div>
              </Col>
              <Col xs={4}>
                <div className="social-source text-center mt-3">
                  <div className="avatar-xs mx-auto mb-3 ">
                    <span
                      className={
                        "avatar-title rounded-circle " +
                        "fas fa-building" +
                        " font-size-16"
                      }
                    ></span>
                  </div>
                  <p className="text-muted mb-0 pb-0 font-size-12">Country</p>
                  <p className="mb-0 font-size-12"> {course?.course_campus}</p>
                </div>
              </Col>
              <Col xs={4}>
                <div className="social-source text-center mt-3">
                  <div className="avatar-xs mx-auto mb-3 ">
                    <span
                      className={
                        "avatar-title rounded-circle " +
                        "bx bx-book" +
                        " font-size-16"
                      }
                    ></span>
                  </div>
                  <p className="text-muted  mb-0 pb-0 font-size-12">Course </p>
                  <p className="mb-0 font-size-12"> {course?.course_title}</p>
                </div>
              </Col>
            </Row>
          </Card>
        </Col>
        <Col md="4">
          <Card className="p-3 ">
            <Row className="">
              <h6 className=" m-2 ">Course Details</h6>
              <Col xs="4">
                <div className="social-source text-center mt-3">
                  <div className="avatar-xs mx-auto mb-3">
                    <span
                      className={
                        "avatar-title rounded-circle " +
                        "bx bx-time-five" +
                        " font-size-16"
                      }
                    ></span>
                  </div>
                  <p className="text-muted mb-0 pb-0 font-size-12">
                    Course Duration
                  </p>
                  <p className="mb-0 font-size-12">
                    {" "}
                    {course?.course_duration}
                  </p>
                </div>
              </Col>
              <Col xs={4}>
                <div className="social-source text-center mt-3">
                  <div className="avatar-xs mx-auto mb-3 ">
                    <span
                      className={
                        "avatar-title rounded-circle " +
                        "bx bx-calendar-event" +
                        " font-size-16"
                      }
                    ></span>
                  </div>
                  <p className="text-muted mb-0 pb-0 font-size-12">
                    Course Start Date
                  </p>
                  <p className="mb-0 font-size-12">
                    {" "}
                    {course?.course_start_date}
                  </p>
                </div>
              </Col>
              <Col xs={4}>
                <div className="social-source text-center mt-3">
                  <div className="avatar-xs mx-auto mb-3 ">
                    <span
                      className={
                        "avatar-title rounded-circle " +
                        "bx bx-calendar-event" +
                        " font-size-16"
                      }
                    ></span>
                  </div>
                  <p className="text-muted mb-0 pb-0 font-size-12">
                    Course End Date{" "}
                  </p>
                  <p className="mb-0 font-size-12">
                    {" "}
                    {course?.course_end_date}
                  </p>
                </div>
              </Col>
            </Row>
          </Card>
        </Col>
        <Col md="4">
          <Card className="p-3">
            <Row className="">
              <h6 className=" m-2 ">Other Details</h6>
              <Col xs="4">
                <div className="social-source text-center mt-3">
                  <div className="avatar-xs mx-auto mb-3">
                    <span
                      className={
                        "avatar-title rounded-circle " +
                        "bx bx-time-five" +
                        " font-size-16"
                      }
                    ></span>
                  </div>
                  <p className="text-muted mb-0 pb-0 font-size-12">
                    Training Duration
                  </p>
                  <p className="mb-0 font-size-12"> {course?.work_duration}</p>
                </div>
              </Col>
              <Col xs={4}>
                <div className="social-source text-center mt-3">
                  <div className="avatar-xs mx-auto mb-3 ">
                    <span
                      className={
                        "avatar-title rounded-circle " +
                        "bx bx-calendar-event" +
                        " font-size-16"
                      }
                    ></span>
                  </div>
                  <p className="text-muted mb-0 pb-0 font-size-12">
                    Training StartsIN
                  </p>
                  <p className="mb-0 font-size-12">
                    {" "}
                    {course?.work_start_date}
                  </p>
                </div>
              </Col>
              <Col xs={4}>
                <div className="social-source text-center mt-3">
                  <div className="avatar-xs mx-auto mb-3 ">
                    <span
                      className={
                        "avatar-title rounded-circle " +
                        "bx bx-calendar-event" +
                        " font-size-16"
                      }
                    ></span>
                  </div>
                  <p className="text-muted  mb-0 pb-0 font-size-12">
                    Training EndIn{" "}
                  </p>
                  <p className="mb-0 font-size-12"> {course?.work_end_date}</p>
                </div>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </>
  )
}

export default CardData
