import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { map } from "lodash"
import moment from "moment"
import chat_bg from "./../../../../assets/images/chat-bg.png"
import google_tune from "./../../../../assets/audio/google_chat.mp3"
import facebook_chat from "./../../../../assets/audio/facebookchat.mp3"

import {
  Button,
  Card,
  Col,
  Container,
  Media,
  Row,
  TabContent,
  TabPane,
  UncontrolledTooltip,
} from "reactstrap"

//Import Scrollbar
import PerfectScrollbar from "react-perfect-scrollbar"
import "react-perfect-scrollbar/dist/css/styles.css"

import { useSelector, useDispatch } from "react-redux"
import { user } from "assets/images/logo"
import MetaTag from "components/Common/Meta-tag"
import { getChats, getMessages } from "store/actions"

import useWebSocket, { ReadyState } from "react-use-websocket"
import { axiosApi, WEBSOCKET_URL } from "helpers/api_methods"
import { FaUserAlt } from "react-icons/fa"

const Chat = props => {
  const dispatch = useDispatch()

  const { chats } = useSelector(state => ({
    chats: state.ChatDetails.chats,
  }))

  const [messageBox, setMessageBox] = useState(null)
  // const Chat_Box_Username2 = "Henry Wells"
  const [currentRoomId, setCurrentRoomId] = useState("")
  const [currentChat, setCurrentChat] = useState(false)
  // eslint-disable-next-line no-unused-vars
  const [currentUser, setCurrentUser] = useState({
    image: "user",
    name: localStorage.getItem("username"),
    online: "true",
    isActive: true,
  })

  const token = localStorage.getItem("token")

  const recieve_audio = new Audio(google_tune)
  const send_audio = new Audio(facebook_chat)

  const { readyState } = useWebSocket(`${WEBSOCKET_URL}/?token=${token}`, {
    onOpen: () => {
      console.log("Connected!")
    },
    onClose: () => {
      console.log("Disconnected!")
      // if (alert("Chat Disconnected!.. please Reload the page")) {
      // } else window.location.reload()
    },
    // New onMessage handler
    onMessage: e => {
      let msg = JSON.parse(e.data)
      if (msg["sender"] != currentUser["name"]) {
        var playPromise = recieve_audio.play()
        if (playPromise !== undefined) {
          playPromise
            .then(_ => {
              console.log("recieved tune")
            })
            .catch(error => {
              console.log("tune : ", error)
            })
        }
      } else {
        var playPromise = send_audio.play()
        if (playPromise !== undefined) {
          playPromise
            .then(_ => {
              console.log("send tune")
            })
            .catch(error => {
              console.log("tune : ", error)
            })
        }
      }

      if (currentChat.messages) {
        chats.filter(item => item.id === msg["chat"])[0].messages.push(msg)
      } else {
        chats.filter(item => item.id === msg["chat"])[0].messages([msg])
      }

      scrollToBottom()
    },
  })
  const { sendJsonMessage } = useWebSocket(`${WEBSOCKET_URL}/?token=` + token)

  const connectionStatus = {
    [ReadyState.CONNECTING]: "Connecting",
    [ReadyState.OPEN]: "Open",
    [ReadyState.CLOSING]: "Closing",
    [ReadyState.CLOSED]: "Closed",
    [ReadyState.UNINSTANTIATED]: "Uninstantiated",
  }[readyState]

  const [activeTab, setactiveTab] = useState("1")
  const [Chat_Box_Username, setChat_Box_Username] = useState("")
  const [Chat_Box_User_Status, setChat_Box_User_Status] = useState("online")
  const [curMessage, setcurMessage] = useState("")

  useEffect(() => {
    dispatch(getChats())
    // dispatch(getMessages())
    // setCurrentRoomId(chats[0].id)
  }, [dispatch])
  useEffect(() => {
    setTimeout(1000)
    scrollToBottom()
  }, [currentChat.messages, currentChat, chats, dispatch])

  //Use For Chat Box
  const userChatOpen = (id, name, status, roomId, chat) => {
    setChat_Box_Username(name)
    setCurrentRoomId(roomId)
    setCurrentChat(chat)
    dispatch(getMessages(id))
    // setMessages(
    //   chat.messages
    // )
    scrollToBottom()
  }

  const addMessage = (chat, sender) => {
    if (curMessage) {
      const message = {
        chat,
        sender,
        sender_name: sender,
        content: curMessage,
        createdAt: new Date(),
        msg_type: "text",

        chat_members: currentChat.member,
      }
      setcurMessage("")
      sendJsonMessage(message)
    } else {
      setcurMessage("")
    }
  }

  const scrollToBottom = () => {
    if (messageBox) {
      messageBox.scrollTop = messageBox.scrollHeight + 1200
    }
  }

  const onKeyPress = e => {
    const { key, value } = e
    if (key === "Enter") {
      setcurMessage(value)
      addMessage(currentRoomId, currentUser.name)
    }
  }

  const handleImageUpload = async e => {
    const formData = new FormData()
    formData.append("msg_file", e.target.files[0])
    try {
      const response = await axiosApi({
        method: "post",
        url: "/chat/message-file/",
        data: formData,
        headers: { "Content-Type": "multipart/form-data" },
      })

      let msg_type = ""
      if (e.target.files[0]["type"].split("/")[0] == "image") {
        msg_type = "image"
      } else {
        msg_type = "file"
      }
      sendJsonMessage({
        chat: currentChat["id"],
        sender: currentUser.name,
        sender_name: currentUser.name,
        content: response["data"]["msg_file"],
        msg_type: msg_type,
        createdAt: new Date(),
        chat_members: currentChat.member,
      })
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <React.Fragment>
      <style>
        {`
        .chat-leftsidebar .chat-leftsidebar-nav .tab-content{
          min-height:unset;
        }
        `}
      </style>
      <div className="page-content">
        <MetaTag title="Chat" />

        <Container fluid>
          {/* <Breadcrumbs title="Skote" breadcrumbItem="Chat" /> */}

          <Row>
            <Col lg="12" className="p-0 px-md-3">
              <div className="d-lg-flex ">
                <div className="chat-leftsidebar me-lg-3 bg-white rounded">
                  <div>
                    <div className="chat-leftsidebar-nav p-2">
                      <TabContent activeTab={activeTab} className="py-1">
                        <TabPane tabId="1">
                          <div>
                            {/* <h5 className="font-size-14 mb-3">Recent</h5> */}
                            <ul className="list-unstyled chat-list">
                              <div className="scrollBar">
                                {map(chats, (chat, key) => (
                                  <li
                                    key={chat.id}
                                    className={`${key === 0 && "firstchild"} ${
                                      currentRoomId === chat.id ? "active" : ""
                                    }`}
                                  >
                                    <Link
                                      to="#"
                                      onClick={() => {
                                        userChatOpen(
                                          chat.id,
                                          chat.name,
                                          "online",
                                          chat.id,
                                          chat
                                        )
                                      }}
                                    >
                                      <Media>
                                        {/* <div className="align-self-center me-3">
                                          <i
                                            className={
                                              "mdi mdi-circle text-success font-size-10"
                                            }
                                          />
                                        </div> */}
                                        <div className="align-self-center me-3">
                                          {/* <img
                                            src={user}
                                            className="rounded-circle avatar-xs"
                                            alt=""
                                          /> */}

                                          <div className="fa-user-alt-icon">
                                            <FaUserAlt
                                              size={"1rem"}
                                              color={"#fff"}
                                            />
                                          </div>
                                        </div>

                                        <Media className="overflow-hidden" body>
                                          <h5 className="text-truncate font-size-14 mb-1">
                                            {chat.name}
                                          </h5>
                                          <p className="text-truncate mb-0">
                                            {chat?.email}
                                          </p>
                                        </Media>
                                        <div className="font-size-11">
                                          {chat.unread > 0 ? (
                                            <span className="btn btn-warning btn-sm btn-rounded">
                                              {chat.unread}
                                            </span>
                                          ) : (
                                            ""
                                          )}
                                        </div>
                                      </Media>
                                    </Link>
                                  </li>
                                ))}
                              </div>
                            </ul>
                          </div>
                        </TabPane>
                      </TabContent>
                    </div>
                  </div>
                </div>
                <div className="w-100 user-chat">
                  <div className="bg-white px-2">
                    <div className="p-4 border-bottom ">
                      <Row>
                        <Col md="4" xs="9">
                          {Chat_Box_Username && (
                            <h5 className="font-size-15 mb-1">
                              <FaUserAlt
                                size={"1.1rem"}
                                className="text-muted me-2"
                              />
                              {Chat_Box_Username}
                            </h5>
                          )}

                          {/* <p className="text-muted mb-0">
                            <i
                              className={
                                Chat_Box_User_Status === "online"
                                  ? "mdi mdi-circle text-success align-middle me-1"
                                  : Chat_Box_User_Status === "intermediate"
                                  ? "mdi mdi-circle text-warning align-middle me-1"
                                  : "mdi mdi-circle align-middle me-1"
                              }
                            />
                            {Chat_Box_User_Status}
                          </p> */}
                        </Col>
                      </Row>
                    </div>

                    <div>
                      <div className="chat-conversation p-md-3">
                        <ul className="list-unstyled h-100">
                          <PerfectScrollbar
                            // style={{ height: "470px" }}
                            containerRef={ref => setMessageBox(ref)}
                          >
                            <li></li>
                            {currentChat.messages ? (
                              map(currentChat.messages, message => (
                                <li
                                  key={message.id}
                                  className={
                                    message.sender_name === currentUser.name
                                      ? "right"
                                      : ""
                                  }
                                >
                                  <div className="conversation-list">
                                    <div className="ctext-wrap">
                                      <div className="conversation-name">
                                        {message.sender_name}
                                      </div>
                                      {message.msg_type === "file" ? (
                                        <p style={{ margin: "0" }}>
                                          <a
                                            href={message.content}
                                            rel="noreferrer"
                                            target="_blank"
                                          >
                                            <i
                                              style={{ fontSize: "80px" }}
                                              className="mdi mdi-file-document-outline"
                                            />
                                            <br />
                                            {message.content.split("/").at(-1)}
                                          </a>
                                        </p>
                                      ) : message.msg_type === "image" ? (
                                        <p style={{ margin: "0" }}>
                                          <a
                                            href={message.content}
                                            rel="noreferrer"
                                            target="_blank"
                                          >
                                            <img src={message.content} />
                                            <br />
                                            {message.content.split("/").at(-1)}
                                          </a>
                                        </p>
                                      ) : (
                                        <p>{message.content}</p>
                                      )}
                                      <p className="chat-time mb-0">
                                        <i className="bx bx bx-check align-middle me-1" />
                                        {moment(message.createdAt).format(
                                          "DD-MM-YY hh:mm"
                                        )}
                                      </p>
                                    </div>
                                  </div>
                                </li>
                              ))
                            ) : (
                              <>
                                <div className="d-flex align-items-center flex-column w-50 m-auto justify-content-center">
                                  <img
                                    src={chat_bg}
                                    alt="background"
                                    width={"250px"}
                                  />
                                  <h6 className="mt-3">Chat Web</h6>
                                  <p className="text-center">
                                    Keep in touch with members by sending and
                                    receiving messages
                                  </p>
                                </div>
                              </>
                            )}
                          </PerfectScrollbar>
                        </ul>
                      </div>
                      {currentChat ? (
                        <div className="p-3 chat-input-section">
                          <Row>
                            <Col>
                              <div className="position-relative">
                                <input
                                  type="text"
                                  value={curMessage}
                                  onKeyPress={onKeyPress}
                                  onChange={e => setcurMessage(e.target.value)}
                                  className="form-control chat-input"
                                  placeholder="Enter Message..."
                                />
                                <div className="chat-input-links">
                                  <ul className="list-inline mb-0">
                                    <li className="list-inline-item">
                                      <input
                                        className="d-none"
                                        type={"file"}
                                        id="image-upload"
                                        onChange={(e, input) => {
                                          handleImageUpload(e, input)
                                        }}
                                      ></input>

                                      <label
                                        htmlFor="image-upload"
                                        style={{ fontSize: "20px", margin: 0 }}
                                      >
                                        <i
                                          className="mdi mdi-file-document-outline"
                                          id="Filetooltip"
                                        />
                                        <UncontrolledTooltip
                                          placement="top"
                                          target="Filetooltip"
                                        >
                                          Send Document
                                        </UncontrolledTooltip>
                                      </label>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </Col>
                            <Col className="col-auto">
                              <Button
                                type="button"
                                color="primary"
                                onClick={() =>
                                  addMessage(currentRoomId, currentUser.name)
                                }
                                className="btn btn-primary btn-rounded chat-send w-md "
                              >
                                <span className="d-none d-sm-inline-block me-2">
                                  Send
                                </span>{" "}
                                <i className="mdi mdi-send" />
                              </Button>
                            </Col>
                          </Row>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Chat
