import React, { useEffect } from "react"
import { Row, Col, Card } from "reactstrap"
import profileimg from "assets/images/profile-img.png"

//Import Images
import { useSelector } from "react-redux"

function CardWelcome(props) {
  const { profile } = useSelector(state => ({
    profile: state?.StudentDashboard?.studentProfile,
  }))
  console.log(profile)
  return (
    <React.Fragment>
      <Card className="bg-success bg-soft ">
        <div>
          <Row>
            <Col xs="7">
              <div className="text-muted p-3">
                <h5 className="text-muted mb-3 ">Welcome Back !</h5>

                <ul className="ps-3 mb-0 ">
                  <li className="py-1">{profile?.full_name}</li>
                  <li className="">{profile?.email}</li>
                </ul>
              </div>
            </Col>
            <Col xs="5" className="align-self-end">
              <img src={profileimg} alt="" className="img-fluid" />
            </Col>
          </Row>
        </div>
      </Card>
    </React.Fragment>
  )
}

export default CardWelcome
