import React, { useEffect, useState } from "react"
import { Card, CardBody, Col, Label, Row, Spinner } from "reactstrap"
import ToolkitProvider from "react-bootstrap-table2-toolkit"
import BootstrapTable from "react-bootstrap-table-next"
import MyPagination from "components/Common/MyPagination"
import { Link } from "react-router-dom"
import { map, range } from "lodash"
import { useDispatch, useSelector } from "react-redux"
import "assets/scss/datatables.scss"
import { deleteJobApplication, getJobApplications } from "store/actions"
import { AvField, AvForm } from "availity-reactstrap-validation"
import { categories, countries } from "./Crud/CategoryList"
import { Markup } from "interweave"

function AllJobApplications() {
  const [searchText, setSearchText] = useState("")
  const [page, setPage] = useState(1)
  const [country, setCountry] = useState("all")
  const [category, setCategory] = useState("all")
  const dispatch = useDispatch()

  const handleSubmit = id => {
    console.log(id)
    dispatch(deleteJobApplication(id))
  }

  const { applicationData, loading } = useSelector(state => ({
    applicationData: state.StudentDetails.applicationData,
    loading: state.StudentDetails.loading,
  }))

  const columns = [
    {
      dataField: "title",
      text: "Title",
    },
    {
      dataField: "description",
      text: "Description",
    },
    {
      dataField: "image",
      text: "Image",
    },
    {
      dataField: "icon",
      text: "Action",
    },
  ]

  const studentsData = map(applicationData?.results, (item, key) => ({
    ...item,
    image: (
      <img src={item?.image} alt="image" style={{ height: 50, width: 50 }} />
    ),
    title: item?.title ? (
      <Markup content={item?.title} />
    ) : (
      <span>{item?.job_title}</span>
    ),
    description: (
      <>
        <Row></Row>
        {item?.description?.length >= 40 ? (
          <>
            <p>Text is too long</p>
            <Link to={`/update-job-application/${item?.id}`}>View more...</Link>
          </>
        ) : (
          <Markup
            content={
              item?.description?.length > 40
                ? item?.description?.substr(0, 40 - 1) + "..."
                : item?.description
            }
          />
        )}
      </>
    ),
    icon: (
      <>
        <div className="d-flex  align-items-center">
          <div>
            <Link
              to={`/update-job-application/${item?.id}`}
              className="btn-dark btn-sm bg-success p-1 px-2 font-size-12"
              style={{ borderRadius: "14px" }}
            >
              Update
            </Link>
          </div>
          <i
            className="bx bx-trash color font mx-2"
            onClick={() => handleSubmit(item.id)}
            type="button"
          />
        </div>
      </>
    ),
  }))

  useEffect(() => {
    dispatch(getJobApplications(searchText, page))
  }, [dispatch, searchText, page])

  const totalPages = Math.ceil(applicationData?.count / 10)
  const pages = range(1, totalPages + 1)

  const handleSearch = e => {
    e.preventDefault()
    setSearchText(e.target.value)
  }

  const defaultSorted = [
    {
      dataField: "id",
      order: "asc",
    },
  ]

  const selectRow = {
    mode: "checkbox",
  }
  return (
    <div className="container-fluid">
      <h1>
        <Row>
          <Col className="col-12">
            <Card>
              <CardBody>
                <ToolkitProvider
                  keyField="id"
                  columns={columns}
                  data={studentsData}
                  search
                >
                  {toolkitProps => (
                    <React.Fragment>
                      <Row className="mb-2">
                        <Col md="4">
                          <div className="search-box me-2 mb-2 d-inline-block">
                            <div className="position-relative">
                              <form
                                className="app-search d-lg-block pt-4"
                                onChange={e => handleSearch(e)}
                              >
                                <div className="position-relative">
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Search..."
                                    defaultValue={searchText}
                                  />
                                  <span className="bx bx-search-alt" />
                                </div>
                              </form>
                            </div>
                          </div>
                        </Col>
                      </Row>
                      {loading ? (
                        <Spinner color="secondary" className="d-block m-auto" />
                      ) : (
                        <>
                          <Row>
                            <Col xl="12">
                              <div className="table-responsive ">
                                <BootstrapTable
                                  keyField={"id"}
                                  responsive
                                  bordered={false}
                                  striped={false}
                                  defaultSorted={defaultSorted}
                                  selectRow={selectRow}
                                  classes={"table align-middle table-nowrap h6"}
                                  headerWrapperClasses={"thead-light"}
                                  {...toolkitProps.baseProps}
                                />
                              </div>
                            </Col>
                          </Row>
                          <MyPagination
                            totalPages={pages}
                            page={page}
                            setPage={setPage}
                          />
                        </>
                      )}
                    </React.Fragment>
                  )}
                </ToolkitProvider>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </h1>
    </div>
  )
}

export default AllJobApplications
