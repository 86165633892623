import React, { useEffect, useState } from "react"
import { Container } from "reactstrap"
import { TbNotification } from "react-icons/tb"
import Breadcrumbs from "components/Common/Breadcrumb"
import ProfileTabs from "./elements/ProfileTabs"
import MetaTag from "components/Common/Meta-tag"
import { useWindowSize } from "react-use"
import ProfileEditLoader from "./elements/ProfileEditLoader"

const UpdateStudentProfile = () => {
  const Role = localStorage.getItem("role")
  const { width } = useWindowSize()

  const [customLoad, setCustomLoad] = useState(false)

  useEffect(() => {
    setCustomLoad(true)
    setTimeout(() => {
      setCustomLoad(false)
    }, 2000);
  }, [])

  return (
    <>
      <MetaTag title="Student Profile" />

      <div className="page-content">
        {width >= 768 && <>
          {Role === "student" ? (
            <p className="mx-4 pb-2 sm:mx-0" style={{ fontWeight: "500" }}>
              <TbNotification className="font-size-15 text-danger" /> Complete
              your profile by filling out the following form
            </p>
          ) : (
            <Breadcrumbs
              title="Student Profile"
              breadcrumbItem="Update Student Profile"
            />
          )}
        </>}
        <Container fluid className="sm:px-0">
          <div className="container-fluid sm:px-0">
            {customLoad && <ProfileEditLoader />}
            <ProfileTabs />
          </div>
        </Container>
      </div>
    </>
  )
}

export default UpdateStudentProfile
