import {
  GET_STUDENTS,
  GET_STUDENTS_SUCCESS,
  GET_STUDENTS_FAIL,
  GET_STUDENTS_LIST,
  GET_STUDENTS_LIST_SUCCESS,
  GET_STUDENTS_LIST_FAIL,
  STUDENT_DETAILS,
  STUDENT_DETAILS_SUCCESS,
  STUDENT_DETAILS_FAIL,
  UPDATE_STUDENT,
  UPDATE_STUDENT_SUCCESS,
  UPDATE_STUDENT_FAIL,
  DELETE_STUDENT,
  DELETE_STUDENT_SUCCESS,
  DELETE_STUDENT_FAIL,
  GET_JOB_APPLICATIONS,
  GET_JOB_APPLICATIONS_SUCCESS,
  GET_JOB_APPLICATIONS_FAIL,
  GET_SINGLE_APPLICATIONS_DETAILS,
  GET_SINGLE_APPLICATIONS_DETAILS_SUCCESS,
  GET_SINGLE_APPLICATIONS_DETAILS_FAIL,
  CREATE_JOB_APPLICATION,
  CREATE_JOB_APPLICATION_SUCCESS,
  CREATE_JOB_APPLICATION_FAIL,
  UPDATE_JOB_APPLICATION,
  UPDATE_JOB_APPLICATION_SUCCESS,
  UPDATE_JOB_APPLICATION_FAIL,
  DELETE_JOB_APPLICATION,
  DELETE_JOB_APPLICATION_SUCCESS,
  DELETE_JOB_APPLICATION_FAIL,
  GET_STUDENT_NOTE,
  GET_STUDENT_NOTE_SUCCESS,
  GET_STUDENT_NOTE_FAIL,
  CREATE_STUDENT_NOTES,
  CREATE_STUDENT_NOTES_SUCCESS,
  CREATE_STUDENT_NOTES_FAIL,
  DELETE_STUDENT_NOTE,
  DELETE_STUDENT_NOTE_SUCCESS,
  DELETE_STUDENT_NOTE_FAIL,
  GET_ADMIN_DASHBOARD_DETAILS,
  GET_ADMIN_DASHBOARD_DETAILS_SUCCESS,
  GET_ADMIN_DASHBOARD_DETAILS_FAIL,
  IMAGES_UPLOAD,
  IMAGE_UPLOAD_SUCCEESS,
  IMAGES_UPLOAD_FAIL,
  IMAGES_UPLOAD_SUCCEESS,
  SELF_DELETE_STUDENT,
  SELF_DELETE_STUDENT_SUCCESS,
  SELF_DELETE_STUDENT_FAIL,
} from "./actionTypes"

//Get Students //

export const getStudents = (tabChange, searchText, page) => ({
  type: GET_STUDENTS,
  payload: { tabChange, searchText, page },
})

export const getStudentsSuccess = students => ({
  type: GET_STUDENTS_SUCCESS,
  payload: students,
})
export const getStudentsFail = error => ({
  type: GET_STUDENTS_FAIL,
  payload: error,
})

export const getStudentsList = () => ({
  type: GET_STUDENTS_LIST,
  payload: {},
})

export const getStudentsListSuccess = data => ({
  type: GET_STUDENTS_LIST_SUCCESS,
  payload: { data },
})

export const getStudentsListFail = () => ({
  type: GET_STUDENTS_LIST_FAIL,
  payload: {},
})

// Students Singleview //

export const getStudentDetails = studentId => ({
  type: STUDENT_DETAILS,
  studentId,
})
export const getStudentDetailsSuccess = StudentsSingleview => ({
  type: STUDENT_DETAILS_SUCCESS,
  payload: StudentsSingleview,
})
export const getStudentDetailsFail = error => ({
  type: STUDENT_DETAILS_FAIL,
  payload: error,
})

// Update Students //

export const updateStudent = (
  updatedStudent,
  studentId,
  setIsOpenFile,
  history
) => ({
  type: UPDATE_STUDENT,
  payload: { updatedStudent, studentId, setIsOpenFile, history },
})

export const updateStudentSuccess = updatedStudent => ({
  type: UPDATE_STUDENT_SUCCESS,
  payload: updatedStudent,
})

export const updateStudentFail = error => ({
  type: UPDATE_STUDENT_FAIL,
  payload: error,
})

// image upload

export const imageUpload = (finalData, studentId, history) => ({
  type: IMAGES_UPLOAD,
  payload: { finalData, studentId, history },
})
export const imageUpload_success = imageUpload => ({
  type: IMAGES_UPLOAD_SUCCEESS,
  payload: imageUpload,
})
export const imageUploadFail = error => ({
  type: IMAGES_UPLOAD_FAIL,
  payload: error,
})

// Delete Students //

export const deleteStudent = (student_id, history) => ({
  type: DELETE_STUDENT,
  payload: { student_id, history },
})
export const deleteStudentSuccess = studentDelete => ({
  type: DELETE_STUDENT_SUCCESS,
  payload: studentDelete,
})
export const deleteStudentFail = error => ({
  type: DELETE_STUDENT_FAIL,
  payload: error,
})

export const selfDeleteStudent = (student_id, history) => ({
  type: SELF_DELETE_STUDENT,
  payload: { student_id, history },
})
export const selfDeleteStudentSuccess = studentDelete => ({
  type: SELF_DELETE_STUDENT_SUCCESS,
  payload: studentDelete,
})
export const selfDeleteStudentFail = error => ({
  type: SELF_DELETE_STUDENT_FAIL,
  payload: error,
})

// Student Notes//

// get //

export const getStudentNote = studentId => ({
  type: GET_STUDENT_NOTE,
  payload: studentId,
})
export const getStudentNoteSuccess = studentNote => ({
  type: GET_STUDENT_NOTE_SUCCESS,
  payload: studentNote,
})

export const getStudentNoteFail = error => ({
  type: GET_STUDENT_NOTE_FAIL,
  payload: error,
})

// create //

export const createStudentNotes = (createDetails, studentId, setNotes) => ({
  type: CREATE_STUDENT_NOTES,
  payload: { createDetails, studentId, setNotes },
})

export const createStudentNotesSuccess = createdNote => ({
  type: CREATE_STUDENT_NOTES_SUCCESS,
  payload: createdNote,
})

export const createStudentNoteFail = error => ({
  type: CREATE_STUDENT_NOTES_FAIL,
  payload: error,
})

// delete //

export const deleteStudentNote = noteId => ({
  type: DELETE_STUDENT_NOTE,
  payload: { noteId },
})

export const deleteStudentNoteSuccess = deleteStudentNote => ({
  type: DELETE_STUDENT_NOTE_SUCCESS,
  payload: deleteStudentNote,
})

export const deleteStudentNoteFail = error => ({
  type: DELETE_STUDENT_NOTE_FAIL,
  payload: error,
})

// job Applications//

export const getJobApplications = (searchText, page) => ({
  type: GET_JOB_APPLICATIONS,
  payload: { searchText, page },
})

export const getJobApplicationsSuccess = applicationData => ({
  type: GET_JOB_APPLICATIONS_SUCCESS,
  payload: applicationData,
})
export const getJobApplicationsFail = error => ({
  type: GET_JOB_APPLICATIONS_FAIL,
  payload: error,
})
// single data //

export const getApplicationDetails = applicationId => ({
  type: GET_SINGLE_APPLICATIONS_DETAILS,
  applicationId,
})

export const getApplicationDetailsSuccess = applicationSingleData => ({
  type: GET_SINGLE_APPLICATIONS_DETAILS_SUCCESS,
  payload: applicationSingleData,
})

export const getApplicationDetailsFail = error => ({
  type: GET_SINGLE_APPLICATIONS_DETAILS_FAIL,
  payload: error,
})

// Create//

export const createJobApplication = (input, history) => ({
  type: CREATE_JOB_APPLICATION,
  payload: { input, history },
})

export const createJobApplicationSucess = input => ({
  type: CREATE_JOB_APPLICATION_SUCCESS,
  payload: input,
})

export const createJobApplicationFail = error => ({
  type: CREATE_JOB_APPLICATION_FAIL,
  payload: error,
})

// update //

export const updateJobApplication = (upatedData, applicationId, history) => ({
  type: UPDATE_JOB_APPLICATION,
  payload: { upatedData, applicationId, history },
})

export const updateJobApplicationSuccess = updateData => ({
  type: UPDATE_JOB_APPLICATION_SUCCESS,
  payload: updateData,
})

export const updateJobApplicationFail = error => ({
  type: UPDATE_JOB_APPLICATION_FAIL,
  payload: error,
})

// delete //
export const deleteJobApplication = applicationId => (
  console.log(applicationId),
  {
    type: DELETE_JOB_APPLICATION,
    payload: { applicationId },
  }
)

export const deleteJobApplicationSuccess = deleteData => (
  console.log(deleteData),
  {
    type: DELETE_JOB_APPLICATION_SUCCESS,
    payload: deleteData,
  }
)

export const deleteJobApplicationFails = error => ({
  type: DELETE_JOB_APPLICATION_FAIL,
  payload: error,
})

//  Admin Dashboard Details //

export const getAdminDashboardDetails = () => ({
  type: GET_ADMIN_DASHBOARD_DETAILS,
})

export const getAdminDashboardDetailsSuccess = adminDashboardDetails => ({
  type: GET_ADMIN_DASHBOARD_DETAILS_SUCCESS,
  payload: adminDashboardDetails,
})

export const getAdminDashboardDetailsFail = error => ({
  type: GET_ADMIN_DASHBOARD_DETAILS_FAIL,
  payload: error,
})
