import { AvField } from "availity-reactstrap-validation"
import { map } from "lodash"
import React, { useState } from "react"
import PropTypes from "prop-types"
import { IoCloseCircleSharp } from "react-icons/io5"

import { Card, CardBody } from "reactstrap"

function ModulesMobile({ module, setModule }) {
  const [statusChange, setStatusChange] = useState({ id: "", change: false })
  return (
    <>
      {map(module, (mod, key) => (
        <Card className="glass-card" key={key}>
          <CardBody>
            {/* {mod?.uuid && (
              <IoCloseCircleSharp
                onClick={() =>
                  setModule(module?.filter(i => i?.uuid !== mod?.uuid))
                }
                size={"1.4rem"}
                className="module_close"
              />
            )} */}
            <div className="label-mt-0">
              <AvField
                style={{
                  fontWeight: "500",
                  fontSize: "14px",
                }}
                id="horizontal-username-Input"
                className="border-0 placeholder-muted "
                name={`specializations[${key}].title`}
                placeholder="Enter Subject"
                type="text"
                value={mod?.title}
                label="Qualification Name"
              />
            </div>
            <div>
              <AvField
                style={{
                  fontWeight: "500",
                  fontSize: "14px",
                }}
                id="horizontal-description-Input"
                className="border-0 placeholder-muted"
                name={`specializations[${key}].description`}
                placeholder="Enter Description"
                type="text"
                value={mod?.description}
                label="Specification / Major"
              />
            </div>
            {/* <div className="select-option-tag">
              <AvField
                style={{
                  fontWeight: "500",
                  fontSize: "14px",
                  color:
                    statusChange.id === mod?.id && statusChange?.change
                      ? "#495057"
                      : "#adadad",
                }}
                id="horizontal-username-Input"
                className="border-0 "
                name={`specializations[${key}].status`}
                type="select"
                label="Status"
                value={mod?.status}
                onChange={e => setStatusChange({ id: mod?.id, change: true })}
              >
                <option>Select the status</option>
                {statusRole?.map((role, key) => (
                  <option key={key} value={role.role}>
                    {role.name}
                  </option>
                ))}
              </AvField>
            </div> */}
          </CardBody>
        </Card>
      ))}
    </>
  )
}

export default ModulesMobile

ModulesMobile.propTypes = {
  module: PropTypes.any,
  setModule: PropTypes.func,
}

export const statusRole = [
  {
    name: "Pending",
    role: "pending",
  },
  {
    name: "Passed",
    role: "passed",
  },
  {
    name: "Not Assigned Yet",
    role: "not_assigned_yet",
  },
  {
    name: "On Going",
    role: "ongoing",
  },
  {
    name: "Failed",
    role: "failed",
  },
  {
    name: "Submitted",
    role: "submitted",
  },
  {
    name: "Not Selected",
    role: "not_selected",
  },
]
