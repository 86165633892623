import React from 'react'
import bgImage from "assets/images/other/logout-img.png"
import { Button } from 'reactstrap'
import { useHistory } from 'react-router-dom'

function MobileLogoutPage() {
    const history = useHistory()
    const handleLogout = async () => {
        localStorage.clear()
        window.location.reload()
    }

    return (
        <div className='logout-screen'>
            <p>Confirm Logout</p>
            {/* apply animation */}
            <div className='img-wrap'>
                <div></div>
                <img src={bgImage} alt="logout" />
            </div>
            <div className='logout-text my-4 text-center'>
                <h5>Logout</h5>
                <p>You want to log out,</p>
                <p>are you sure?</p>
            </div>
            <div>
                <Button onClick={() => history.goBack()} color='light' className='mx-2 '>
                    {`Don't logout`}
                </Button>
                <Button onClick={handleLogout} color='success' className='mx-2'>
                    Yes, Logout
                </Button>
            </div>
        </div>
    )
}

export default MobileLogoutPage