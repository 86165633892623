import React, { useEffect, useState } from "react"
import { Card, CardBody, Col, Container, Row, Spinner } from "reactstrap"
import DatatableTables from "./DatatableTables"
import Breadcrumbs from "components/Common/Breadcrumb"
import MetaTag from "components/Common/Meta-tag"
import useWindow from "Hooks/UseWindow"

import MobileVacencyList from "components/Mobile/MobileVacencyList"
import { Markup } from "interweave"
import { useDispatch, useSelector } from "react-redux"
import { getJobApplications } from "store/actions"
import MyPagination from "components/Common/MyPagination"
import { range } from "lodash"

function Vacancies() {
  const { isMobile } = useWindow()
  const dispatch = useDispatch()

  const [searchText, setSearchText] = useState("")
  const [page, setPage] = useState(1)

  const { job_applications, studentProfile, loading } = useSelector(state => ({
    job_applications: state?.StudentDetails?.applicationData,
    loading: state?.StudentDetails?.loading,
    studentProfile: state?.StudentDashboard?.studentProfile,
  }))

  const totalPages = Math.ceil(job_applications?.count / 10)
  const pages = range(1, totalPages + 1)

  useEffect(() => {
    dispatch(getJobApplications(searchText, page))
  }, [dispatch, searchText, page])
  const handleSearch = e => {
    setSearchText(e.target.value)
  }

  console.log(loading)

  return (
    <>
      {isMobile ? (
        <>
          <MobileVacencyList />
        </>
      ) : (
        <>
          <MetaTag title={"All Vacancies"} />

          <div className="page-content">
            <Breadcrumbs title="Dashboard" breadcrumbItem="All Vacancies" />
            <Container fluid>
              <div className="container-fluid">
                <Col md="4">
                  <div className="search-box me-2 mb-2 d-inline-block">
                    <div className="position-relative">
                      <form
                        className="app-search d-lg-block pt-4"
                        onChange={e => handleSearch(e)}
                      >
                        <div className="position-relative">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Search..."
                            defaultValue={searchText}
                          />
                          <span className="bx bx-search-alt" />
                        </div>
                      </form>
                    </div>
                  </div>
                </Col>
                {loading ? (
                  <Spinner color="secondary" className="d-block m-auto" />
                ) : (
                  <Row>
                    {job_applications?.results?.map((item, key) => (
                      <Col key={key} xs={12} md={6}>
                        <Card className="mb-3">
                          <CardBody>
                            <>
                              <div
                                className="m-0 p-0"
                                style={{ paddingBottom: -25 }}
                              >
                                <Markup
                                  content={item?.title}
                                  className="fs-3 p-0 m-0"
                                />
                              </div>
                              <span style={{ paddingBottom: -23 }}>
                                {item?.date}
                              </span>
                              <div>
                                <img
                                  className="p-1"
                                  src={item?.image}
                                  style={{
                                    maxHeight: "50%",
                                    width: "100%",
                                    objectFit: "",
                                  }}
                                  alt="Job Image"
                                />
                              </div>
                              <Markup
                                content={item?.description}
                                className="p-2"
                              />
                            </>
                          </CardBody>
                        </Card>
                      </Col>
                    ))}
                  </Row>
                )}
                <MyPagination
                  setPage={setPage}
                  page={page}
                  totalPages={pages}
                />
              </div>
            </Container>
          </div>
        </>
      )}
    </>
  )
}

export default Vacancies
