// Get Students //

export const GET_STUDENTS = "GET_STUDENTS"
export const GET_STUDENTS_SUCCESS = "GET_STUDENTS_SUCCESS"
export const GET_STUDENTS_FAIL = "GET_STUDENTS_FAIL"

export const GET_STUDENTS_LIST = "GET_STUDENTS_LIST"
export const GET_STUDENTS_LIST_SUCCESS = "GET_STUDENTS_LIST_SUCCESS"
export const GET_STUDENTS_LIST_FAIL = "GET_STUDENTS_LIST_FAIL"

// Single View //

export const STUDENT_DETAILS = "STUDENT_DETAILS"
export const STUDENT_DETAILS_SUCCESS = "STUDENT_DETAILS_SUCCESS"
export const STUDENT_DETAILS_FAIL = "STUDENT_DETAILS_FAIL"

// Update Students //

export const UPDATE_STUDENT = "UPDATE_STUDENT"
export const UPDATE_STUDENT_SUCCESS = "UPDATE_STUDENT_SUCCESS"
export const UPDATE_STUDENT_FAIL = "UPDATE_STUDENT_FAIL"

export const IMAGES_UPLOAD = "IMAGES_UPLOAD"
export const IMAGES_UPLOAD_SUCCEESS = "IMAGES_UPLOAD_SUCCEESS"
export const IMAGES_UPLOAD_FAIL = "IMAGES_UPLOAD_FAIL"

// Delete Students //

export const DELETE_STUDENT = "DELETE_STUDENT"
export const DELETE_STUDENT_SUCCESS = "DELETE_STUDENT_SUCCESS"
export const DELETE_STUDENT_FAIL = "DELETE_STUDENT_FAIL"

export const SELF_DELETE_STUDENT = "SELF_DELETE_STUDENT"
export const SELF_DELETE_STUDENT_SUCCESS = "SELF_DELETE_STUDENT_SUCCESS"
export const SELF_DELETE_STUDENT_FAIL = "SELF_DELETE_STUDENT_FAIL"

// Job Applications //
//GET JOB APPLICATIONS//

export const GET_JOB_APPLICATIONS = "GET_JOB_APPLICATIONS"
export const GET_JOB_APPLICATIONS_SUCCESS = "GET_JOB_APPLICATIONS_SUCCESS"
export const GET_JOB_APPLICATIONS_FAIL = "GET_JOB_APPLICATIONS_FAIL"

export const GET_SINGLE_APPLICATIONS_DETAILS = "GET_SINGLE_APPLICATIONS_DETAILS"
export const GET_SINGLE_APPLICATIONS_DETAILS_SUCCESS =
  "GET_SINGLE_APPLICATIONS_DETAILS_SUCCESS"
export const GET_SINGLE_APPLICATIONS_DETAILS_FAIL =
  "GET_SINGLE_APPLICATIONS_DETAILS_FAIL"

export const CREATE_JOB_APPLICATION = "CREATE_JOB_APPLICATION"
export const CREATE_JOB_APPLICATION_SUCCESS = "CREATE_JOB_APPLICATION_SUCCESS"
export const CREATE_JOB_APPLICATION_FAIL = "CREATE_JOB_APPLICATION_FAIL"

export const UPDATE_JOB_APPLICATION = "UPDATE_JOB_APPLICATION"
export const UPDATE_JOB_APPLICATION_SUCCESS = "UPDATE_JOB_APPLICATION_SUCCESS"
export const UPDATE_JOB_APPLICATION_FAIL = "UPDATE_JOB_APPLICATION_FAIL"

export const DELETE_JOB_APPLICATION = "DELETE_JOB_APPLICATION"
export const DELETE_JOB_APPLICATION_SUCCESS = "DELETE_JOB_APPLICATION_SUCCESS"
export const DELETE_JOB_APPLICATION_FAIL = "DELETE_JOB_APPLICATION_FAIL"

// notes //
//get//

export const GET_STUDENT_NOTE = "GET_STUDENT_NOTE"
export const GET_STUDENT_NOTE_SUCCESS = "GET_STUDENT_NOTE_SUCCESS"
export const GET_STUDENT_NOTE_FAIL = "GET_STUDENT_NOTE_FAIL"

//create//

export const CREATE_STUDENT_NOTES = "CREATE_STUDENT_NOTES"
export const CREATE_STUDENT_NOTES_SUCCESS = "CREATE_STUDENT_NOTES_SUCCESS"
export const CREATE_STUDENT_NOTES_FAIL = "CREATE_STUDENT_NOTES_FAIL"

//delete //

export const DELETE_STUDENT_NOTE = "DELETE_STUDENT_NOTE"
export const DELETE_STUDENT_NOTE_SUCCESS = "DELETE_STUDENT_NOTE_SUCCESS"
export const DELETE_STUDENT_NOTE_FAIL = "DELETE_STUDENT_NOTE_FAIL"

//admin dashboard //

export const GET_ADMIN_DASHBOARD_DETAILS = "GET_ADMIN_DASHBOARD_DETAILS"
export const GET_ADMIN_DASHBOARD_DETAILS_SUCCESS =
  "GET_ADMIN_DASHBOARD_DETAILS_SUCCESS"
export const GET_ADMIN_DASHBOARD_DETAILS_FAIL =
  "GET_ADMIN_DASHBOARD_DETAILS_FAIL"
