import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Button, Card, CardBody, Col, Label, Row } from "reactstrap"
import { map } from "lodash"
import noVacency from "assets/images/other/no-vacancy.png"
import { getJobApplications } from "store/actions"
import { AvField, AvForm } from "availity-reactstrap-validation"
import { categories, countries } from "pages/JobApplication/Crud/CategoryList"
import { Markup } from "interweave"

function MobileVacencyList() {
  const dispatch = useDispatch()

  const { studentProfile, job_applications } = useSelector(state => ({
    studentProfile: state?.StudentDashboard?.studentProfile,
    job_applications: state?.StudentDetails?.applicationData,
  }))

  const [country, setCountry] = useState("all")
  const [category, setCategory] = useState("all")
  const [searchText, setSearchText] = useState("")

  console.log(studentProfile)

  useEffect(() => {
    dispatch(getJobApplications(searchText, ""))
  }, [dispatch, searchText])

  const handleSearch = e => {
    setSearchText(e.target.value)
  }

  return (
    <div className="mt-3 p-3 h-100">
      <p className="text-white mb-0">All Vacancies </p>
      {/*       
      <div>
   
        <AvForm className="mb-3 search-box-1">
          <Label className="text-white font-size-12" style={{ width: 160 }}>
            Country:
          </Label>
          <AvField
            type="select"
            label=""
            name="country"
            validate={{
              required: {
                value: false,
              },
            }}
            onChange={e => setCountry(e.target.value)}
          >
            <option value="">choose country.....</option>
            {map(countries, (item, key) => (
              <option key={key} value={item}>
                {item}
              </option>
            ))}
          </AvField>
        </AvForm>
        <AvForm className="mb-4 search-box-1">
          <Label className="text-white font-size-12" style={{ width: 160 }}>
            Category:
          </Label>
          <AvField
            type="select"
            label=""
            name="category"
            validate={{
              required: {
                value: false,
              },
            }}
            onChange={e => setCategory(e.target.value)}
          >
            {" "}
            <option value="">choose category.....</option>
            {map(categories, (item, key) => (
              <option key={key} value={item}>
                {item}
              </option>
            ))}
          </AvField>
        </AvForm>
      </div> */}

      <div className="search-box w-100 me-2 mb-0 d-inline-block">
        <div className="position-relative">
          <form
            className="app-search d-lg-block pt-4 "
            onChange={e => handleSearch(e)}
          >
            <div className="position-relative">
              <input
                type="text"
                className="form-control"
                placeholder="Search..."
                defaultValue={searchText}
              />
              <span className="bx bx-search-alt" />
            </div>
          </form>
        </div>
      </div>

      {/* {job_applications?.results?.length >= 1 ? (
        <>
          {map(job_applications?.results, (item, key) => (
            <Col md={4} key={key}>
              <Card className="job-card">
                <CardBody>
                  <p
                    className="text-info mb-0 pb-1"
                    style={{ textTransform: "uppercase" }}
                  >
                    {item?.company}
                  </p>
                  <p
                    className="font-size-12 text-muted m-0 p-0 "
                    style={{
                      letterSpacing: "1px",
                      textTransform: "capitalize",
                      fontWeight: 400,
                    }}
                  >
                    {item?.stage}
                  </p>

                  <div className="mt-3 job">
                    <div>
                      <p className="mb-0">{item?.position}</p>
                      <p className="mb-0 pb-1" style={{ maxWidth: 200 }}>
                        {item?.job_description}
                      </p>
                      <span className="text-muted ">{item?.last_date}</span>
                    </div>
                    <a href={item?.job_link} target="_blank" rel="noreferrer">
                      <Button
                        className="btn-sm border-none"
                        style={{
                          backgroundColor: "#034e60",
                          whiteSpace: "nowrap",
                        }}
                      >
                        Apply Now
                      </Button>
                    </a>
                  </div>
                </CardBody>
              </Card>
            </Col>
          ))}
        </>
      ) : (
        <div className="d-flex flex-column align-items-center justify-content-center h-100">
          <img
            className="mt-5"
            src={noVacency}
            alt=""
            style={{
              marginLeft: 20,
              height: 280,
            }}
          />
          <p className="text-darkGreen pt-3">No Vacancies Available</p>
        </div>
      )}

      {studentProfile?.fees_paid === false && (
        <div className="not-paid-modal">
          <Card className="bg-white">
            <CardBody>
              <p className="text-danger">
                Uh Oh! Seems Like You are not paid the fee
              </p>

              <div className="text-black">
                <p className="mb-1">
                  <i className="mdi mdi-circle-medium align-middle text-primary me-1" />{" "}
                  Make the payment to access vacancies
                </p>

                <p className="mb-0">
                  <i className="mdi mdi-circle-medium align-middle text-primary me-1" />{" "}
                  For payments Contact us
                  <br />{" "}
                  <span className="mx-3 px-1">
                    {" "}
                    Call +447741639374, Email : info@careerpro.uk
                  </span>
                </p>
              </div>
            </CardBody>
          </Card>
        </div>
      )} */}

      <Row>
        {/* <div className="text-muted font-size-14 py-2">Job Application</div> */}
        {job_applications?.results?.map((item, key) => (
          <Col key={key} xs={12} md={6}>
            <Card className="mb-3">
              <CardBody>
                <>
                  <div className="m-0 p-0" style={{ paddingBottom: -25 }}>
                    <Markup content={item?.title} className="fs-3 p-0 m-0" />
                  </div>
                  <span style={{ paddingBottom: -23 }}>{item?.date}</span>
                  <div>
                    <img
                      className="p-1"
                      src={item?.image}
                      style={{ height: 150, width: "100%", objectFit: "cover" }}
                      alt="Job Image"
                    />
                  </div>
                  <Markup content={item?.description} className="p-2" />
                </>
              </CardBody>
            </Card>
          </Col>
        ))}
      </Row>
    </div>
  )
}

export default MobileVacencyList
