import { map } from "lodash"
import React from "react"
import {
  IoIosArrowDropleftCircle,
  IoIosArrowDroprightCircle,
} from "react-icons/io"
import { useSelector } from "react-redux"
import { Card, CardBody } from "reactstrap"
import SwiperCore, { Autoplay, Navigation } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"

SwiperCore.use([Navigation, Autoplay])
// SwiperCore.use([Navigation, Autoplay])

function MobileCareerIndrest() {
  const { studentProfile } = useSelector(state => ({
    studentProfile: state?.StudentDashboard?.studentProfile,
  }))

  const modules = studentProfile?.specializations

  console.log(studentProfile)

  const careerInterestData = [
    {
      //   type: "",
      items: [
        {
          title: "1st",
          value: studentProfile?.first_job_role,
        },
        {
          title: "2nd",
          value: studentProfile?.second_job_role,
        },
        {
          title: "3rd",
          value: studentProfile?.third_job_role,
        },
        {
          title: "4th",
          value: studentProfile?.forth_job_role,
        },
      ],
    },
    // {
    //   type: "1st",
    //   items: [
    //     {
    //       title: "Interested Job Sector",
    //       value: studentProfile?.first_job_sector,
    //     },
    //     {
    //       title: "Interested Job Role",
    //       value: studentProfile?.first_job_role,
    //     },
    //     {
    //       title: "Preferred Location",
    //       value: studentProfile?.first_preferred_location,
    //     },
    //   ],
    // },

    // {
    //     type: '2nd',
    //     items: [
    //         {
    //             title: "Interested Job Sector",
    //             value: studentProfile?.second_job_sector
    //         },
    //         {
    //             title: "Interested Job Role",
    //             value: studentProfile?.second_job_role
    //         },
    //         {
    //             title: "Preferred Location",
    //             value: studentProfile?.second_preferred_location
    //         },
    //     ]

    // },
    // {
    //     type: '3rd',
    //     items: [
    //         {
    //             title: "Interested Job Sector",
    //             value: studentProfile?.third_job_sector
    //         },
    //         {
    //             title: "Interested Job Role",
    //             value: studentProfile?.third_job_role
    //         },
    //         {
    //             title: "Preferred Location",
    //             value: studentProfile?.third_preferred_location
    //         },
    //     ]

    // },
    // {
    //     type: '4th',
    //     items: [
    //         {
    //             title: "Interested Job Sector",
    //             value: studentProfile?.forth_job_sector
    //         },
    //         {
    //             title: "Interested Job Role",
    //             value: studentProfile?.forth_job_role
    //         },
    //         {
    //             title: "Preferred Location",
    //             value: studentProfile?.forth_preferred_location
    //         },
    //     ]

    // },
  ]

  return (
    <>
      <p className="text-black mt-3">
        Interested Job Role
        {/* Career interest */}
      </p>

      <div className="carausel-10-columns new_cat_slider">
        <Swiper
          autoplay={false}
          navigation={{
            prevEl: ".custom_prev_ct2",
            nextEl: ".custom_next_ct2",
          }}
          //   navigation={{
          //     prevEl: ".custom_prev_ct2",
          //     nextEl: ".custom_next_ct2",
          //   }}
          className="custom-class"
          slidesPerView={1.1}
        >
          {map(careerInterestData, (item, key) => (
            <SwiperSlide key={key}>
              <Card
                className="glass-card"
                style={{
                  marginRight: "1rem",
                }}
              >
                <CardBody>
                  {item?.type && (
                    <div className="numbers-order mb-3">
                      <p>{item?.type}</p>
                    </div>
                  )}
                  {map(item?.items, (subItem, key1) => (
                    <div className="mb-3" key={key1}>
                      <p style={{ opacity: 0.8 }} className="sp-heading mb-0  ">
                        {subItem?.title}
                      </p>
                      <p className="mb-0 sp-des text-start flex-1">
                        {subItem?.value}
                      </p>
                    </div>
                  ))}
                </CardBody>
              </Card>
            </SwiperSlide>
          ))}
        </Swiper>
        <div
          className="slider-arrow slider-arrow-2 mb-4 carausel-10-columns-arrow"
          style={{
            marginLeft: "auto",
            width: "fit-content",
          }}
        >
          <span className="slider-btn slider-prev slick-arrow custom_prev_ct2">
            <IoIosArrowDropleftCircle
              className="text-darkGreen"
              size={"25px"}
            />
          </span>
          <span className="slider-btn slider-next slick-arrow custom_next_ct2">
            <IoIosArrowDroprightCircle
              className="text-darkGreen"
              size={"25px"}
            />
          </span>
        </div>
      </div>
    </>
  )
}

export default MobileCareerIndrest
