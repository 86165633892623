import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Col, Row } from "reactstrap"
import { getAdminDashboardDetails, getProfileDetails } from "store/actions"
import CardWelcome from "./elements/card-welcome"
import MiniWidget from "./elements/mini-widget"
import AllJobApplications from "./elements/AllJobApplications"
import StudentPayment from "./elements/studentPayment"
import chatImg from "../../../assets/images/chat.png"
import { Link, useLocation } from "react-router-dom"

function AdminDashboard() {
  const dispatch = useDispatch()
  // const reports = [
  //   {
  //     icon: "bx bx-copy-alt",
  //     title: "Totel Students",
  //     value: "1,452",
  //     badgeValue: "",
  //     color: "success",
  //     desc: "",
  //   },
  //   {
  //     icon: "bx bx-archive-in",
  //     title: "Fee Paid",
  //     value: "$ 28,452",
  //     badgeValue: "",
  //     color: "success",
  //     desc: "",
  //   },
  //   {
  //     icon: "bx bx-purchase-tag-alt",
  //     title: "Fee Not Paid",
  //     value: "$ 16.2",
  //     badgeValue: "",
  //     color: "warning",
  //     desc: "",
  //   },
  // ]

  useEffect(() => {
    dispatch(getAdminDashboardDetails())
  }, [dispatch])

  return (
    <div className="page-content">
      <Row>
        <Col xl="4">
          {/* <CardWelcome /> */}
          <StudentPayment />
        </Col>
        <Col xl="8">
          <Row>
            <MiniWidget />
          </Row>
          <AllJobApplications />
        </Col>
      </Row>
      <Link to={"/chat"} className="chat " title="chat">
        <img src={chatImg} className="heart" alt="" />
      </Link>
    </div>
  )
}

export default AdminDashboard
