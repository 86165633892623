export const studentSidebar = [
  {
    heading: "Home",
    badgeClass: "",
    badgeValue: "",
    routeLink: "/dashboard",
    iconClass: "bx bxs-home-circle",
  },
  {
    heading: "Vacancies",
    badgeClass: "",
    badgeValue: "",
    routeLink: "/vacancies",
    iconClass: "bx bx-cylinder",
  },
  {
    heading: "Message",
    badgeClass: "",
    badgeValue: "",
    routeLink: "/chat",
    iconClass: "bx bx-chat",
  },
  // {
  //   heading: "Career",
  //   badgeClass: "",
  //   badgeValue: "",
  //   iconClass: "bx bx-world",
  //   routeLink: "/career",
  // },

  // {
  //   heading: "My Profile/Edit",
  //   badgeClass: "",
  //   badgeValue: "",
  //   routeLink: "/my-profile",

  //   iconClass: "bx bxs-user",
  // },
]

export const adminSidebar = [
  {
    heading: "Dashboard",
    badgeClass: "",
    badgeValue: "",
    routeLink: "/dashboard",
    iconClass: "bx bxs-dashboard",
  },
  {
    routeLink: "/students",
    heading: "Students",
    badgeClass: "danger",
    badgeValue: "",
    iconClass: "bx bx-user",
    // subTitles: [
    //   { title: "Active", routeLink: "/students-pending" },
    //   { title: "Pending", routeLink: "/students-pending" },
    //   { title: "All Student", routeLink: "/students" },
    // ],
  },
  {
    heading: "Job Applications",
    badgeClass: "",
    badgeValue: "",
    iconClass: "shield",
    subTitles: [
      {
        title: "All Job Applications",
        routeLink: "/job-application",
      },
      {
        title: "Create Job Applications",
        routeLink: "/create-job-application",
      },
    ],
  },
  {
    heading: "Messages",
    badgeClass: "",
    badgeValue: "",
    routeLink: "/chat",
    iconClass: "chat",
  },

  //if you need a saparetor use this
  // {
  //   heading: "",
  //   headingSaparetor: "Staff",
  //   badgeClass: "",
  //   badgeValue: "",
  //   iconClass: "",
  //   subTitles: [{ title: "", routeLink: "" }],
  // },
]
