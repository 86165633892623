import React from "react"
import { Redirect } from "react-router-dom"

import Login from "../pages/Authentication/Login"

// Dashboard
import Dashboard from "pages/Dashboard/index"
import Pages500 from "pages/404/pages-500"
import Pages404 from "pages/404/pages-404"
import LandingPage from "pages/LandingPage"

import Register from "pages/Authentication/Register"
import ForgetPassword from "pages/Authentication/ForgetPassword"

import Students from "pages/Students"
import StudentSingleView from "pages/Students/SingleView"
import UpdateStudentProfile from "pages/Students/Crud/Update"
import Chat from "pages/Dashboard/admin/Chats/Chat"

import JobApplication from "pages/JobApplication"
import CreateJobApplication from "pages/JobApplication/Crud/CreateJobApplication"
import UpdateJobApplication from "pages/JobApplication/Crud/UpdateJobApplication"

import Vacancies from "pages/Vacancies/index"
import CourseDetails from "pages/CourseDetails"
import VerifyOTP from "pages/Authentication/VerifyOTP"
import ChatBox from "pages/Dashboard/student/elements/chat-box"
import MobileLogoutPage from "components/Mobile/MobileLogoutPage"
import PrivacyPolicy from "pages/PrivacyPolicy"
import ResetPassword from "pages/Authentication/NewPassword"
import UpdateJobApplications from "pages/JobApplication/Crud/UpdateJobApplicationNew"
import CreateJobApplications from "pages/JobApplication/Crud/CreateJobApplicationNew"

const commonRoute = [
  { path: "/dashboard", component: Dashboard },

  {
    path: "/",
    exact: true,
    component: function dashboard() {
      return <Redirect to="/dashboard" />
    },
  },
]

//for admin only
const adminProtectedRoutes = [
  { path: "/dashboard", component: Dashboard },

  //students
  { path: "/students", component: Students },
  { path: "/student/:id", component: StudentSingleView },
  { path: "/student/update/:id", component: UpdateStudentProfile },

  //job-application
  { path: "/job-application", component: JobApplication },
  // { path: "/create-job-application", component: CreateJobApplication },
  { path: "/create-job-application", component: CreateJobApplications },
  // { path: "/update-job-application/:id", component: UpdateJobApplication },
  { path: "/update-job-application/:id", component: UpdateJobApplications },

  //chat
  { path: "/chat", component: Chat },

  //this route should be at the end of all other routes eslint-disable-next-line react/display-name
  { path: "/", exact: true, component: () => <Redirect to="/dashboard" /> },
]

//for students only
const studentRoutes = [
  { path: "/dashboard", component: Dashboard },
  { path: "/vacancies", component: Vacancies },
  { path: "/course-details", component: CourseDetails },
  { path: "/my-profile", component: StudentSingleView },
  { path: "/student/update/:id", component: UpdateStudentProfile },
  { path: "/chat", component: ChatBox },
  { path: "/logout", component: MobileLogoutPage },

  //this route should be at the end of all other routes eslint-disable-next-line react/display-name
  { path: "/", exact: true, component: () => <Redirect to="/dashboard" /> },
]

const publicRoutes = [
  { path: "/", component: LandingPage },
  { path: "/login", component: Login },
  { path: "/register", component: Register },
  { path: "/forgetpassword", component: ForgetPassword },
  { path: "/verify", component: VerifyOTP },
  { path: "/password/reset/:id", component: ResetPassword },
  { path: "/privacy-policy", component: PrivacyPolicy },
  // { path: "/newpassword", component: NewPassword },
  { path: "/404", component: Pages404 },
  { path: "/500", component: Pages500 },
]

export { publicRoutes, commonRoute, adminProtectedRoutes, studentRoutes }
