import React from "react"
import { Card } from "reactstrap"
import { Link } from "react-router-dom"
import { CardBody } from "reactstrap"
import { map } from "lodash"
import PropTypes from "prop-types"

function ModulesSpecifications({ CardData }) {
  return (
    <Card>
      <CardBody>
        <div className="d-flex">
          <div className="me-2">
            <h6 className="mb-4">
              {/* Modules & Specifications */}
              Qualifications
            </h6>
          </div>
        </div>

        <div className="">
          <table className="table align-middle table-nowrap mb-0">
            <thead>
              <tr>
                <th scope="col">Qualification Name</th>
                <th scope="col">Specification / Major</th>
                {/* <th scope="col">Status</th> */}
              </tr>
            </thead>

            <tbody>
              {map(CardData?.specializations, (StudentSingleDatas, key) => (
                <tr key={key}>
                  <td>
                    <h6 className="font-size-13 text-truncate mb-1">
                      <Link to="#" className="text-dark">
                        {/* <p>{key + 1}</p> */}
                        {StudentSingleDatas?.title}
                      </Link>
                    </h6>
                  </td>
                  <td>
                    <h6 className="font-size-13 text-dark mb-1">
                      <Link to="#" className="text-dark">
                        {StudentSingleDatas?.description}
                      </Link>
                    </h6>
                  </td>
                  {/* <td>
                    <p className="text-muted mb-0">
                      {StudentSingleDatas?.status}
                    </p>
                  </td> */}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </CardBody>
    </Card>
  )
}

export default ModulesSpecifications

ModulesSpecifications.propTypes = {
  CardData: PropTypes.array,
}
