export const LOGIN_USER_REQUEST = "LOGIN_USER_REQUEST"
export const LOGIN_USER_SUCCESS = "LOGIN_USER_SUCCESS"
export const LOGIN_USER_FAIL = "LOGIN_USER_FAIL"

export const USER_REGISTER_REQUEST = "USER_REGISTER_REQUEST"
export const USER_REGISTER_SUCCESS = "USER_REGISTER_SUCCESS"
export const USER_REGISTER_FAIL = "USER_REGISTER_FAIL"

export const VERIFICATION_REQUEST = "VERIFICATION_REQUEST"
export const VERIFICATION_SUCCESS = "VERIFICATION_SUCCESS"
export const VERIFICATION_FAIL = "VERIFICATION_FAIL"

export const FORGET_PASSWORD = "FORGET_PASSWORD"
export const FORGET_PASSWORD_SUCCESS = "FORGET_PASSWORD_SUCCESS"
export const FORGET_PASSWORD_ERROR = "FORGET_PASSWORD_ERROR"

export const FORGET_NEW_PASSWORD = "FORGET_NEW_PASSWORD"
export const FORGET_NEW_PASSWORD_SUCCESS = "FORGET_NEW_PASSWORD_SUCCESS"
export const FORGET_NEW_PASSWORD_ERROR = "FORGET_NEW_PASSWORD_ERROR"

export const RESET_PASSWORD = "RESET_PASSWORD"
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS"
export const RESET_PASSWORD_FAIL = "RESET_PASSWORD_FAIL"

export const RESEND_OTP = "RESEND_OTP"
export const RESEND_OTP_SUCCESS = "RESEND_OTP_SUCCESS"
export const RESEND_OTP_FAIL = "RESEND_OTP_FAIL"